import { getToApi, postJsonToApi } from "./requestManager";
import { getGAClientId } from "../features/tracking/trackingSlice";
import { store } from "../app/store";
import { getPaymentDialogSelectedSubscriptionDuration } from "../features/ui/uiSlice";
import { amplitudeFeatureFlagsVariables } from "../features/amplitude/amplitudeFeatureFlagsVariables";

const STRIPE_BASE_PATH = 'stripe';
const CREATE_CHECKOUT_SESSION_PATH = STRIPE_BASE_PATH + '/create_checkout_session';
const CHECKOUT_SESSION_STATUS_PATH = STRIPE_BASE_PATH + '/checkout_session_status';
const CREATE_CUSTOMER_PORTAL_SESSION_PATH = STRIPE_BASE_PATH + '/create_customer_portal_session';
const REMOVE_BUSINESS_PATH = STRIPE_BASE_PATH + '/remove_business'
const ACTIVE_SUBSCRIPTION_INFO_PATH = STRIPE_BASE_PATH + '/active_subscription_info';
export const PORTAL_TYPE_MANAGE_SUBSCRIPTION = 'manage_subscription';
export const PORTAL_TYPE_ADD_BUSINESS = 'add_business';
export const PORTAL_TYPE_MANAGE_SUBSCRIPTION_WITH_YEARLY_UPGRADE = 'manage_subscription_with_yearly_upgrade'
export const PORTAL_TYPE_UPGRADE_TO_YEARLY = 'upgrade_to_yearly'
export type  CustomerPortalType = typeof PORTAL_TYPE_MANAGE_SUBSCRIPTION
  | typeof PORTAL_TYPE_ADD_BUSINESS
  | typeof PORTAL_TYPE_MANAGE_SUBSCRIPTION_WITH_YEARLY_UPGRADE
  | typeof PORTAL_TYPE_UPGRADE_TO_YEARLY;
export const PRICING_TEST_MAY_2024 = "pricingTestMay2024";
export const THREE_MONTHS_50_OFF_PARAM = "threeMonths50Off";
export const SUBSCRIPTION_DURATION_PARAM = "subscription_duration";
export const UNABLE_TO_OPEN_MANAGE_SUBSCRIPTION_PORTAL = "Unable to manage subscription. Please try again or contact support if the problem persists.";

export const stripeServices = {
  checkout,
  checkoutSessionStatus,
  customerPortalAddBusiness,
  customerPortalManageSubscription,
  removeBusiness,
  activeSubscriptionInfo,
  customerPortalUpgradeToYearly,
}

interface StripeCheckoutAPI
{
  client_secret: string,
  session_id: string
}

interface StripeCustomerPortalAPI
{
  session_id: string,
  url: string,
}

interface StripeCheckoutSessionStatusAPI
{
  status: string,
  stripe_email?: string,
  stripe_customer_name?: string,
}

export interface ScheduledDowngradeAttributes
{
  next_phase_start_date: string,
  new_quantity: number
}

export interface ScheduledDowngradeAPI
{
  scheduled_downgrade: ScheduledDowngradeAttributes;
}

interface ActiveSubscriptionInfoAPI
{
  active_subscription: {
    quantity: number,
    current_period_start_date: string,
    current_period_end_date: string,
    scheduled_downgrade?: ScheduledDowngradeAttributes,
  },
}

function getPricingTestParams()
{
  return {
    [PRICING_TEST_MAY_2024]: THREE_MONTHS_50_OFF_PARAM,
  };
}

function getSubscriptionParams( duration )
{
  return {
    [SUBSCRIPTION_DURATION_PARAM]: duration,
  };
}

async function checkout()
{
  let jsonBody;
  const subscriptionDuration = getPaymentDialogSelectedSubscriptionDuration( store.getState() );
  if ( !!subscriptionDuration )
  {
    jsonBody = getSubscriptionParams( subscriptionDuration );
  }
  else
  {
    jsonBody = getPricingTestParams();
  }

  return postJsonToApi<StripeCheckoutAPI>( CREATE_CHECKOUT_SESSION_PATH, {}, jsonBody );
}

async function checkoutSessionStatus( sessionId: string )
{
  const googleClientId = getGAClientId( store.getState() );

  const jsonBody = {
    session_id: sessionId,
    ga_client_id: googleClientId,
  };

  return postJsonToApi<StripeCheckoutSessionStatusAPI>( CHECKOUT_SESSION_STATUS_PATH, {}, jsonBody );
}

function customerPortalAddBusiness()
{
  return customerPortal( PORTAL_TYPE_ADD_BUSINESS );
}

async function customerPortalManageSubscription()
{
  let shouldShowSettingUpgradeYearlyOption = await amplitudeFeatureFlagsVariables.shouldShowSettingUpgradeYearlyOption();
  const type = shouldShowSettingUpgradeYearlyOption ? PORTAL_TYPE_MANAGE_SUBSCRIPTION_WITH_YEARLY_UPGRADE : PORTAL_TYPE_MANAGE_SUBSCRIPTION;
  return customerPortal( type );
}

function customerPortalUpgradeToYearly()
{
  return customerPortal( PORTAL_TYPE_UPGRADE_TO_YEARLY );
}

function customerPortal( portalType: CustomerPortalType )
{
  const jsonBody = {
    portal_type: portalType,
  };

  return postJsonToApi<StripeCustomerPortalAPI>( CREATE_CUSTOMER_PORTAL_SESSION_PATH, {}, jsonBody ).then( ( response ) =>
  {
    window.location.replace( response.url );
  } );
}

async function activeSubscriptionInfo()
{
  return await getToApi<ActiveSubscriptionInfoAPI>( ACTIVE_SUBSCRIPTION_INFO_PATH, {} );
}

function removeBusiness()
{
  return postJsonToApi<ScheduledDowngradeAPI>( REMOVE_BUSINESS_PATH, {}, {} );
}
