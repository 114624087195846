import { Box, Typography } from "@mui/material";
import React from "react";
import { GlobalErrorDialogCallbackProps } from "./globalErrorDialogFactory";
import { GlobalErrorConfirmDialog } from "../ui/globalErrorConfirmDialog";

export default function PostIdeaNotificationNotFoundDialog( props: GlobalErrorDialogCallbackProps )
{

  function getDialogContent()
  {
    return <Box sx={{ px: 20, textAlign: "left" }}>
      <Typography>If you believe this is in error, try the following:</Typography>
      <ol type={"1"}>
        <li>Logout of Alkai</li>
        <li>Click the link again</li>
        <li>When prompted to login, login with the phone or email the link was sent to.</li>
      </ol>
    </Box>
  }

  return <GlobalErrorConfirmDialog {...props}
                                   title={"This post belongs to a different account."}
                                   dialogContent={getDialogContent()}/>
}