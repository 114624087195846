import * as React from "react";
import { ConfirmDialog, ConfirmDialogProps } from "./confirmDialog";

export interface GlobalErrorConfirmDialogProps extends ConfirmDialogProps
{
  title: string;
  dialogContent?: React.ReactNode;
}

export function GlobalErrorConfirmDialog( props: GlobalErrorConfirmDialogProps )
{

  return <ConfirmDialog {...props}
                        title={props.title}
                        titleSxProps={{ color: "primary.main", fontSize: "22px", lineHeight: "26px", fontWeight: 700, fontFamily: "montserrat", pt: 15 }}
                        dialogContent={props.dialogContent}
                        showNeedHelpLink={true}
                        onClose={props.onConfirm}
                        confirmText={"OK"}
                        confirmButtonSxProps={{ fontSize: "14px", fontFamily: "Montserrat", fontWeight: 700, height: "42px", width: "120px"}}/>
}