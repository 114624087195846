import { SxProps, Typography } from "@mui/material";
import * as React from "react";
import { merge } from "lodash";

export interface PurchaseFlowHeaderProps
{
  children?: React.ReactNode;
  sx?: SxProps;
}

export function YearlyPurchaseFlowHeader( props: PurchaseFlowHeaderProps )
{
  return (
    <Typography sx={merge( {
      width: "252px",
      color: "primary.contrastText",
      fontWeight: "800",
      fontSize: "32px",
      lineHeight: "32px",
      wordWrap: "break-word",
      textAlign: "center",
      my: 8,
      fontFamily: "Montserrat"
    }, props.sx )}>
      {props.children}</Typography>
  );
}


