import { SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";
import { merge } from "lodash";

interface PurchaseFlowBenefitTextProps
{
  children: ReactNode;
  small?: boolean;
  sx?: SxProps;
}

export function PurchaseFlowBenefitText( props: PurchaseFlowBenefitTextProps )
{
  const fontSize = props.small ? "14px" : "15px";
  const lineHeight = props.small ? "16px" : "18px";
  const width = props.small ? 180 : 230;
  return (
    <Typography variant={"body1"}
                sx={merge( {
                  color: "primary.contrastText",
                  width: width,
                  wordWrap: "break-word",
                  fontFamily: "Montserrat",
                  fontSize,
                  lineHeight
                }, props.sx )}>{props.children}</Typography>
  )
}