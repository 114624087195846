import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { UpsellBenefitRow } from "./upsellBenefitRow";
import { PurchaseFlowHeader } from "../payment/purchaseFlowHeader";
import { UPSELL_GRADIENT } from "../constants";
import { AlkaiWordmarkLogoWhite } from "../registration/alkaiWordmarkLogoWhite";
import { PurchaseFlowCTA } from "../payment/purchaseFlowCTA";
import { PORTAL_TYPE_ADD_BUSINESS, stripeServices } from "../../services/stripe.services";
import ProgressOverlay from "../loadingIndicator/progressOverlay";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { eventTracker } from "../../helpers/eventTracker";
import { useDispatch, useSelector } from "react-redux";
import { setApplicationLoadingState } from "../loadingIndicator/loadingSlice";
import { isMonthlySubscriber } from "../user/userSlice";
import { RootState } from "../../app/store";

function AddMultipleBusinessesUpsell( props: WithFullScreenDialogProps )
{
  const [loading, setLoading] = useState( false );
  const dispatch = useDispatch();
  const userHasMonthlySubscription = useSelector( ( state: RootState ) => isMonthlySubscriber( state ) );

  useEffect( () =>
  {
    if ( props.externalStateOpen )
    {
      eventTracker.logAddBusinessUpsellShown();
    }
  }, [props.externalStateOpen] );

  function handleCloseButtonClicked()
  {
    eventTracker.logAddBusinessUpsellCancelClicked();
    handleCloseUpsell();
  }

  function handleCloseUpsell()
  {
    if ( props.handleClose )
    {
      props.handleClose();
    }
  }

  async function handleAddBusiness()
  {
    setLoading( true );
    eventTracker.logAddBusinessContinueToPaymentClicked();
    stripeServices.customerPortalAddBusiness().then( () =>
    {
      dispatch( setApplicationLoadingState( true ) );
      handleCloseUpsell();
    } ).catch( ( error ) =>
    {
      setLoading( false );
      eventTracker.logStripeCustomerPortalFailed( error, PORTAL_TYPE_ADD_BUSINESS );
      dispatch( setAlertMessage( errorAlert( "Unable to add business. Please try again or contact support if the problem persists." ) ) );
    } );
  }

  function getPurchaseFlowCTAText(isMonthlySubscription: boolean)
  {
    if (isMonthlySubscription)
    {
      return "Add a business for $20/month"
    }
    else
    {
      return "Add a business for $120/year"
    }
  }


  return (
    <FullPageMobileContainer sx={{ background: UPSELL_GRADIENT }}>
      <IconButton
        aria-label="close"
        color="secondary"
        size="small"
        sx={{
          position: "absolute",
          left: 8,
          top: 8,
          zIndex: 1,
        }}
        onClick={handleCloseButtonClicked}>
        <CloseIcon fontSize="inherit"/>
      </IconButton>
      <Stack sx={{ display: "flex", alignItems: "center", my: 12, mx: "auto" }}>
        <AlkaiWordmarkLogoWhite sx={{ width: "110px" }}/>
        <PurchaseFlowHeader sx={{ width: "280px" }}>Hire Alkai for<br/>all of your businesses!</PurchaseFlowHeader>
        <UpsellBenefitRow>
          <Box component="span" fontWeight='bold'>Switch between businesses</Box> within one account
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          <Box component="span" fontWeight='bold'>Unique Brand Kit</Box> means each business will look its best
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          <Box component="span" fontWeight='bold'>Custom social plan</Box><br/>for each unique business
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          <Box component="span" fontWeight='bold'>Eye-catching content, created automatically</Box> when it is time to post
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          <Box component="span" fontWeight='bold'>Post Directly & Schedule</Box> <br/>to each business's social accounts
        </UpsellBenefitRow>


        <PurchaseFlowCTA onClick={handleAddBusiness}>
          {getPurchaseFlowCTAText(userHasMonthlySubscription)}
        </PurchaseFlowCTA>
        {
          userHasMonthlySubscription && <Typography variant="body1" sx={{
                                       width: 260,
                                       color: "white",
                                       fontFamily: "Montserrat",
                                       fontWeight: 400,
                                       fontSize: 10,
                                       textAlign: "center"
                                     }}>
                                       Active discounts will be applied for the duration of the discount period
                                     </Typography>
        }
      </Stack>
      {loading && <ProgressOverlay/>}
    </FullPageMobileContainer>
  );
}

export const AddMultipleBusinessesUpsellDialog = withFullScreenDialog( AddMultipleBusinessesUpsell )
