import React, { useEffect } from 'react'
import { Button, Drawer, Stack, Typography } from "@mui/material";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FacebookLoginResponse, facebookServices } from "../../services/facebook.services";
import { getFacebookAppId } from '../../constants';
import { ConfirmFacebookAccount } from '../confirmSocialNetworkAccount/confirmFacebookAccount';
import { ConfirmFacebookPageAccount } from "../confirmSocialNetworkAccount/confirmFacebookPageAccount";
import { businessServices, FACEBOOK_PAGE } from "../../services/business.services";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { useDispatch } from "react-redux";
import { eventTracker } from "../../helpers/eventTracker";
import FacebookIcon from "@mui/icons-material/Facebook";
import { ShareDestinationToggle } from "../editing/ShareDestinationToggle";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { logToConsole, logToConsoleError } from "../utils/devLoggingHelper";
import { SETTINGS_PAGE_SOURCE, SocialNetworkConnectionSourcePageType } from "../../helpers/trackingConstants";
import { iOSMessageSender } from "../payment/iOSMessageSender";
import { browserUtils } from "../utils/browserUtils";
import { useLocation, useSearchParams } from "react-router-dom";
import { merge } from "lodash";
import { showGlobalErrorDialog } from "../ui/uiSlice";
import { FACEBOOK_ERROR_NO_LINKED_PAGE_DIALOG } from "../constants";

interface ReactFacebookRenderProps
{
  isSdkLoaded?: boolean;
  isProcessing?: boolean;
  isDisabled?: boolean;
  onClick( event: React.MouseEvent ): void;
}

interface ConnectFacebookPagesButtonProps
{
  source: SocialNetworkConnectionSourcePageType;
  handleConnectionSucceeded?(): void;
  handleConnectionFailed?(): void;
  handleConnectionStarted?(): void;
}

export function ConnectFacebookPagesButton( props: ConnectFacebookPagesButtonProps )
{
  const dispatch = useDispatch();
  const facebookPermissionsScope = facebookServices.getFacebookPagesScope();
  const [drawerOpened, setDrawerOpened] = React.useState( false );
  const [profileUrl, setProfileUrl] = React.useState( "" );
  const [profileName, setProfileName] = React.useState( "" );
  const [profileAccessToken, setProfileAccessToken] = React.useState( "" );
  const [profileId, setProfileId] = React.useState( 0 );
  const [showLoader, setShowLoader] = React.useState( false );
  const [confirmingFacebookPage, setConfirmingFacebookPage] = React.useState( false );
  const socialNetworkAccountsContext = React.useContext( SocialNetworkAccountsContext );
  const fromSettings = props.source === SETTINGS_PAGE_SOURCE;
  const { hash } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect( () =>
  {
    const hashParams = new URLSearchParams( hash );
    const facebookCallback = hashParams.get( "#cb" );
    const accessToken = hashParams.get( "access_token" );
    const connectingFacebookPage = searchParams.get( "connecting" ) === FACEBOOK_PAGE;
    if ( !!facebookCallback && accessToken && connectingFacebookPage )
    {
      removeFacebookCallbackHash();
      facebookServices.verifyUserHasFacebookPagePermissions( accessToken ).then( ( facebookIsAccessible ) =>
      {
        if ( facebookIsAccessible )
        {
          facebookServices.getMeInfo( accessToken ).then( ( meInfo ) =>
          {
            onFacebookConnectSuccess( meInfo.id, meInfo.name, accessToken );
          } );
        }
      } );
    }

  }, [hash, searchParams] );

  function addConnectingTypeToSearchParams( connectingType: string )
  {
    const queryParamsAsObject = Object.fromEntries(
      searchParams
    );
    const newSearchParams = merge( queryParamsAsObject, { connecting: connectingType } );
    const newURLSearchParams = new URLSearchParams( newSearchParams );
    setSearchParams( newURLSearchParams );
  }

  function removeConnectingTypeFromSearchParams()
  {
    const queryParamsAsObject = Object.fromEntries(
      searchParams
    );
    delete queryParamsAsObject.connecting;
    const newURLSearchParams = new URLSearchParams( queryParamsAsObject );
    setSearchParams( newURLSearchParams );
  }

  function removeFacebookCallbackHash()
  {
    window.location.hash = "";
  }

  const handleClick = ( event: React.MouseEvent ) =>
  {
    addConnectingTypeToSearchParams( FACEBOOK_PAGE );

    setConfirmingFacebookPage( false );
    requestConnect();
  }

  function switchAccountsErrorText()
  {
    if ( browserUtils.isAlkaiIOSApp() )
    {
      return "To connect a different account please try to connect again.";
    }
    else
    {
      return "Please log out of Facebook in your browser before trying to connect again.";
    }
  }

  const onSwitchAccounts = ( event: React.MouseEvent ) =>
  {
    logToConsole( "switch accounts facebook pages" );
    window.FB.logout();
    iOSMessageSender.clearFacebookCacheData();
    handleCancelConnectionAttempt( { errorText: switchAccountsErrorText() } );
  }

  function facebookPageConnectionComplete()
  {
    const accountType = FACEBOOK_PAGE;
    const context = socialNetworkAccountsContext.socialNetworkAccounts;

    eventTracker.sendFirstSocialNetworkAccountConnectedIfNecessary( context, accountType, props.source );

    eventTracker.logFacebookPageConnected( props.source );
    if ( !!props.handleConnectionSucceeded )
    {
      props.handleConnectionSucceeded();
    }
  }

  function closeAsFailed()
  {
    setShowLoader( false );
    closeDrawer();
    if ( !!props.handleConnectionFailed )
    {
      props.handleConnectionFailed();
    }
  }

  const onConfirm = async ( event: React.MouseEvent ) =>
  {
    setShowLoader( true );
    try
    {
      const result = await businessServices.clientFacebookConnect( profileId, profileAccessToken );
      logToConsole( "result facebook connected", result );

      if ( result.has_facebook_page_accounts )
      {
        const socialNetworkAccountsResponseAPI = await businessServices.listSocialNetworkAccounts();
        socialNetworkAccountsContext.updateSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
        setShowLoader( false );
        setConfirmingFacebookPage( true );
      }
      else
      {
        eventTracker.logFacebookPageConnectFailed( props.source, "The user does not have facebook page account linked." );
        dispatch( showGlobalErrorDialog( FACEBOOK_ERROR_NO_LINKED_PAGE_DIALOG ) );
        closeAsFailed();
      }
    }
    catch (error)
    {
      eventTracker.logFacebookPageConnectFailed( props.source, error?.toString() );
      displayErrorAlert();
      logToConsoleError( "error facebook connected", error );
      closeAsFailed();
    }
  }

  const onFacebookConnectSuccess = ( facebookId: number,
                                     name: string,
                                     facebookAccessToken: string ) =>
  {
    removeConnectingTypeFromSearchParams();
    const profileImageUrl = facebookServices.getFacebookProfileImageUrl( facebookId.toString() );
    setProfileUrl( profileImageUrl );
    setProfileName( name );
    setProfileAccessToken( facebookAccessToken );
    setProfileId( facebookId );
    openDrawer();
  }

  const displayErrorAlert = ( errorText?: string ) =>
  {
    let errorMessageForUser = "We had a problem connecting Facebook. Please try again or contact support if the problem persists.";
    if ( errorText )
    {
      errorMessageForUser = errorText;
    }
    dispatch( setAlertMessage( errorAlert( errorMessageForUser ) ) );
  }

  const onFacebookConnectFailure = ( failureData?: { response?: FacebookLoginResponse, errorText?: string, permissionsDenied?: boolean } ) =>
  {
    logToConsoleError( "pages facebook connect failure", failureData );
    const errorText = failureData?.errorText;
    const responseStatus = failureData?.response?.status;
    eventTracker.logFacebookPageConnectFailed( props.source, errorText, responseStatus );
    if ( facebookServices.isFacebookUserNotLoggedInResponse( failureData?.response ) )
    {
      logToConsole( "not signed in to facebook" );
    }
    else
    {
      displayErrorAlert( errorText );
    }

    if ( !!props.handleConnectionFailed )
    {
      props.handleConnectionFailed();
    }
  }

  const handleFacebookResponse = async ( response ) =>
  {
    if ( response && response.id && response.accessToken )
    {
      const accessToken = response.accessToken;
      const facebookIsAccessible = await facebookServices.verifyUserHasFacebookPagePermissions( accessToken );
      if ( facebookIsAccessible )
      {
        onFacebookConnectSuccess( response.id, response.name, accessToken );
      }
      else
      {
        onFacebookConnectFailure(
          { permissionsDenied: true, errorText: "Unable to connect until grant required permissions." },
        );
      }
    }
    else
    {
      onFacebookConnectFailure( { response, errorText: "Facebook connect failed." } );
    }
  }

  function requestConnect()
  {
    logToConsole( "connect facebook page started" );
    eventTracker.logAcceptConnectFacebookPageClicked( props.source );
    if ( !!props.handleConnectionStarted )
    {
      props.handleConnectionStarted();
    }
  }

  function reloadSocialNetworkAccounts()
  {
    businessServices.listSocialNetworkAccounts().then( ( socialNetworkAccountsResponseAPI ) =>
      {
        socialNetworkAccountsContext.updateSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
      }
    );
  }

  return (
    <FacebookLogin
      appId={getFacebookAppId()}
      className={""}
      autoLoad={false}
      reAuthenticate={true}
      disableMobileRedirect={true}
      scope={facebookPermissionsScope}
      fields="name,email,picture"
      version={facebookServices.GRAPH_API_VERSION}
      render={( renderProps: ReactFacebookRenderProps ) =>
      {
        const { onClick } = renderProps;
        return (
          <>
            <Drawer
              anchor={"bottom"}
              open={drawerOpened}
              onClose={handleCancelConnectionAttempt}
              sx={{ textAlign: 'center', m: 1, zIndex: 1500 }}
            >
              {!confirmingFacebookPage && <ConfirmFacebookAccount profileUrl={profileUrl}
                                                                  profileName={profileName}
                                                                  onSwitchAccounts={onSwitchAccounts}
                                                                  onConfirm={onConfirm}
                                                                  showLoader={showLoader}/>}

              {confirmingFacebookPage && <ConfirmFacebookPageAccount handleCancel={handleCancelConfirmingFacebookPage}
                                                                     handleSelectionComplete={handleFinishedConfirmingFacebookPage}
                                                                     isInitialConnect={true}/>}
            </Drawer>
            {!fromSettings && <ShareDestinationToggle icon={<FacebookIcon sx={{ width: 30, height: 30, mt: 3, color: "black" }}/>}
                                                      label={"Connect a Facebook Page*"}
                                                      onClick={onClick}
                                                      checked={false}
            />}
            {fromSettings && <Stack
              sx={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between", width: "100%" }}>
              <Stack sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                <FacebookIcon sx={{ width: 30, height: 30, color: "black" }}/>
                <Typography>Facebook Page*</Typography>
              </Stack>
              <Button variant={"contained"} onClick={onClick} sx={{ minWidth: "100px" }}>Connect</Button>
            </Stack>}
          </>)
          ;
      }}
      onClick={handleClick}
      callback={handleFacebookResponse}/>
  )

  function handleCancelConfirmingFacebookPage()
  {
    eventTracker.logFacebookPageConnectFailed( props.source, "use cancelled confirming facebook page to use" );
    reloadSocialNetworkAccounts();
    closeDrawer();
    if ( !!props.handleConnectionFailed )
    {
      props.handleConnectionFailed();
    }
  }

  function handleFinishedConfirmingFacebookPage()
  {
    reloadSocialNetworkAccounts();
    facebookPageConnectionComplete();
    closeDrawer();
  }

  function handleCancelConnectionAttempt( failureData?: {} )
  {
    onFacebookConnectFailure( failureData || { errorText: "Connecting a Facebook Page was canceled." } );
    closeDrawer();
  }

  function closeDrawer()
  {
    setDrawerOpened( false );
  }

  function openDrawer()
  {
    setDrawerOpened( true );
  }

}
