import React from "react";
import { GlobalErrorDialogCallbackProps } from "./globalErrorDialogFactory";
import { ConfirmDialogBodyTypography } from "../ui/confirmDialogBodyTypography";
import { GlobalErrorConfirmDialog } from "../ui/globalErrorConfirmDialog";
import { ConfirmDialogContent } from "../ui/confirmDialogContent";
import { ALKAI_FAQ_UNABLE_TO_CONNECT_INSTAGRAM_URL } from "../constants";

export default function FacebookInstagramNoLinkedPageDialog( props: GlobalErrorDialogCallbackProps )
{

  function getDialogContent()
  {
    return <ConfirmDialogContent>
      <ConfirmDialogBodyTypography>
        Alkai connects to your Instagram Business Account through Facebook.
      </ConfirmDialogBodyTypography>
      <ConfirmDialogBodyTypography>
        To proceed, log in to Instagram and use the Settings for your Business Account to link with your Business' Facebook Page. Then, try connecting
        again.
      </ConfirmDialogBodyTypography>
    </ConfirmDialogContent>
  }

  return <GlobalErrorConfirmDialog {...props}
                                   title={"Instagram Account not linked to Facebook Page"}
                                   dialogContent={getDialogContent()}
                                   customNeedHelpUrl={ALKAI_FAQ_UNABLE_TO_CONNECT_INSTAGRAM_URL}/>
}