import { Box, Typography } from "@mui/material";

export interface BestValueBadgeProps
{
  selected?: boolean;
}

export function BestValueBadge( props: BestValueBadgeProps)
{
  return <Box
    sx={{ backgroundColor: "#BDE792",
      color: "#23163B",
      width: "75px",
      height: "20px",
      position: "absolute",
      top: "-13px",
      left: "10px",
      borderRadius: "2px"}}>
    {!props.selected && <Box sx={{ backgroundColor: "#00000060",
      borderRadius: "2px",
      width: "75px",
      height: "20px",
      position: "absolute", }}> </Box>}
    <Typography sx={{ fontWeight: "600", fontSize: "12px", lineHeight: "16px", textAlign: "center" }}>Best Value</Typography>
  </Box>;
}