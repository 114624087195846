import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import * as React from "react";
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import { UPSELL_GRADIENT } from "../constants";
import { PaymentConfirmation } from "./paymentConfirmation";
import DiamondsBackground from "../../assets/diamonds-bg.svg";

function PaymentConfirmationDialog( props: WithFullScreenDialogProps )
{
  const handleClose = () =>
  {
    if ( props.handleClose )
    {
      props.handleClose();
    }
  }

  const background = { backgroundImage: "url(" + DiamondsBackground + ")," + UPSELL_GRADIENT, backgroundSize: "contain" };
  return (
    <FullPageMobileContainer sx={{ ...background }}>
      <PaymentConfirmation handleCloseCheckout={handleClose}/>
    </FullPageMobileContainer>
  );
}

export const PaymentConfirmationDialogFullScreen = withFullScreenDialog( PaymentConfirmationDialog )

