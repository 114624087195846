import { Link, Stack, Typography } from "@mui/material";
import React from "react";
import { PORTAL_TYPE_MANAGE_SUBSCRIPTION, stripeServices, UNABLE_TO_OPEN_MANAGE_SUBSCRIPTION_PORTAL } from "../../services/stripe.services";
import { eventTracker } from "../../helpers/eventTracker";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { useDispatch } from "react-redux";

export function ManageSubscriptionBanner()
{
  const dispatch = useDispatch();

  function handleManageSubscriptionClicked()
  {
    stripeServices.customerPortalManageSubscription().catch( ( error ) =>
    {
      eventTracker.logStripeCustomerPortalFailed( error, PORTAL_TYPE_MANAGE_SUBSCRIPTION );
      dispatch( setAlertMessage( errorAlert( UNABLE_TO_OPEN_MANAGE_SUBSCRIPTION_PORTAL ) ) );
    } );
  }

  return (
    <Stack direction="column" sx={{
      color: 'primary.contrastText', m: 1, borderRadius: "5px",
      backgroundColor: "#FA585E",
    }}>
      <Typography variant={"body2"}>
        Your subscription is no longer active.<br/> Please
        renew to continue using Alkai.
      </Typography>
      <Link sx={{ m: 2, pb: "1px", fontWeight: "bold" }} color={"primary.contrastText"} component="button" alignItems={"center"}
            variant="body2"
            onClick={handleManageSubscriptionClicked}>Manage subscription</Link>
    </Stack>
  );
}
