import * as React from 'react';
import { useContext, useState } from 'react';
import { NO_MUSIC_TRACK_ID, TRACK_TYPE_NO_MUSIC, Z_INDEX_DRAWER } from "../constants";
import { Box, Drawer, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { postIdeaServices } from "../../services/postIdeaServices";
import { clearAndStopAudioPlayerTrack, clearEditorAudioStartTimeOverrideMs } from "./uiSlice";
import { eventTracker } from "../../helpers/eventTracker";
import { useDispatch } from "react-redux";
import { PostIdeaContext } from "../context/postIdeaContext";
import { getSelectedTrackIdFromPostJson, getSelectedTrackTypeFromPostJson } from "../postIdea/postIdeaHelper";
import { musicItemHelper } from "../music/musicItemHelper";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { CommonTrackAPI, MusicSelection } from "../music/musicSlice";

export interface AudioDrawerProps
{
  pendingSelection?: MusicSelection;
  setPendingSelection( pendingSelection: MusicSelection | undefined ): void;
  closeAfterApplying: () => void;
}

export function AudioDrawer( props: AudioDrawerProps )
{
  const dispatch = useDispatch();

  const postIdea = useContext( PostIdeaContext ).postIdea;
  const selectedTrackId = getSelectedTrackIdFromPostJson( postIdea );
  const selectedTrackType = getSelectedTrackTypeFromPostJson( postIdea );

  const [applyingMusic, setApplyingMusic] = useState( false );
  const pendingSelectionTrackId = props.pendingSelection ? props.pendingSelection.id : NO_MUSIC_TRACK_ID;
  const pendingSelectionTrackType = props.pendingSelection ? props.pendingSelection.type : TRACK_TYPE_NO_MUSIC;
  const pendingSelectionDifferentThanInitial = props.pendingSelection && (pendingSelectionTrackId !== selectedTrackId || pendingSelectionTrackType
                                                                          !== selectedTrackType);
  const pendingSelection = props.pendingSelection;

  async function handleApplyPendingSelection()
  {
    if ( pendingSelection )
    {
      setApplyingMusic( true );
      logMusicApplied( pendingSelection );
      const newMusicTrack = musicItemHelper.getMusicTrackFromPendingSelection( pendingSelection );
      try
      {
        await postIdeaServices.replaceMusicWhenMelodieFeatureEnabled( postIdea.id, newMusicTrack );
        setApplyingMusic( false );
        dispatch( clearEditorAudioStartTimeOverrideMs() );
        props.closeAfterApplying();
      }
      catch (e)
      {
        setApplyingMusic( false );
        dispatch( setAlertMessage( errorAlert( "Error switching music" ) ) );
      }
    }
  }

  function handleCancelPendingSelection()
  {
    if ( pendingSelection )
    {
      logMusicCanceled( pendingSelection );
    }
    dispatch( clearAndStopAudioPlayerTrack() );
    props.setPendingSelection( undefined );
  }

  function logMusicApplied( toTrack: CommonTrackAPI )
  {
    const fromTrackId = musicItemHelper.getTrackIdForReporting( selectedTrackType, selectedTrackId );
    const toTrackId = musicItemHelper.getTrackIdForReporting( toTrack.type, toTrack.id );
    eventTracker.logEditPostMusicApplied( postIdea, fromTrackId, toTrackId );
  }

  function logMusicCanceled( pendingTrack: CommonTrackAPI )
  {
    const backToTrack =  musicItemHelper.getTrackIdForReporting( selectedTrackType, selectedTrackId );
    const pendingTrackId = musicItemHelper.getTrackIdForReporting( pendingTrack.type, pendingTrack.id );
    eventTracker.logEditPostMusicCanceled( postIdea, backToTrack, pendingTrackId );
  }

  function currentMusicView()
  {
    if ( pendingSelection )
    {
      return <Typography variant={"body1"} sx={{textOverflow: "ellipsis", overflow: "hidden", textAlign: "center", width: "85%"}}>{musicItemHelper.displayNameForTrack( pendingSelection.type, pendingSelection.display_name )}</Typography>
    }
    else
    {
      return <Typography variant={"body1"}>Error retrieving music</Typography>
    }
  }

  return <Drawer
    anchor={"bottom"}
    open={pendingSelectionDifferentThanInitial}
    sx={{ zIndex: Z_INDEX_DRAWER }}
    variant="persistent">
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mt: 2, mb: 6 }}>
      <Typography variant={"h6"}>Apply new music selection?</Typography>
      {currentMusicView()}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2, gap: 6 }}>
        <LoadingButton loading={applyingMusic}
                       startIcon={<HighlightOffIcon/>}
                       variant="contained" color="negative"
                       onClick={handleCancelPendingSelection}>Cancel</LoadingButton>
        <LoadingButton loading={applyingMusic}
                       startIcon={<CheckCircleOutlineIcon/>}
                       variant="contained"
                       color="positive"
                       onClick={handleApplyPendingSelection}>Apply</LoadingButton>
      </Box>
    </Box>
  </Drawer>
}
