import { Link, Stack } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { ShowCarouselSignInContext } from "../context/showCarouselSignInContext";

export function TrustPilotWidget()
{
  const ref = React.useRef( null );
  useEffect( () =>
  {
// If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
// If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
// When it is, it will automatically load the TrustBox.
    // @ts-ignore
    if ( window.Trustpilot )
    {
      // @ts-ignore
      window.Trustpilot.loadFromElement( ref.current, true );
    }
  }, [] );
  const showCarouselSignIn = useContext( ShowCarouselSignInContext );

  function getStyle()
  {
    if ( !showCarouselSignIn )
    {
      return {
        pt: "35px"
      };
    }
    return {};
  }

  return (
    <Stack direction="column" alignItems="center" spacing={12} sx={getStyle()}>
      <div ref={ref}
           className="trustpilot-widget"
           data-locale="en-US"
           data-template-id="5419b732fbfb950b10de65e5"
           data-businessunit-id="65ce50cb0acd12f4ff462dd3"
           data-style-height="24px"
           data-style-width="100%">
        <Link href="https://www.trustpilot.com/review/alkai.ai" target="_blank" rel="noopener">Trustpilot</Link>
      </div>
    </Stack>
  );
}
