import React from "react";
import { GlobalErrorDialogCallbackProps } from "./globalErrorDialogFactory";
import { ConfirmDialogBodyTypography } from "../ui/confirmDialogBodyTypography";
import { GlobalErrorConfirmDialog } from "../ui/globalErrorConfirmDialog";
import { ConfirmDialogContent } from "../ui/confirmDialogContent";
import { ALKAI_FAQ_NO_FACEBOOK_PAGE_URL } from "../constants";

export default function FacebookNoLinkedPageDialog( props: GlobalErrorDialogCallbackProps )
{

  function getDialogContent()
  {

    return <ConfirmDialogContent>
      <ConfirmDialogBodyTypography>
        There is no Facebook Page associated with your Facebook account.
      </ConfirmDialogBodyTypography>
      <ConfirmDialogBodyTypography>
        To proceed, log in to Facebook and create a Business Page or add your personal account as an admin to an existing Business Page. Then, try
        connecting again.
      </ConfirmDialogBodyTypography>
    </ConfirmDialogContent>
  }

  return <GlobalErrorConfirmDialog {...props}
                                   title={"Facebook Page not found"}
                                   dialogContent={getDialogContent()}
                                   customNeedHelpUrl={ALKAI_FAQ_NO_FACEBOOK_PAGE_URL}/>
}