import { Stack } from "@mui/material";
import React, { useEffect } from "react";

import { Helmet } from "react-helmet";
import { ROUTE_SEO, ROUTES } from "../constants";
import { amplitudeFeatureFlagsVariables } from "../amplitude/amplitudeFeatureFlagsVariables";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { setUpsellShown } from "../ui/uiSlice";
import { eventTracker } from "../../helpers/eventTracker";
import { UPSELL_SOURCE_SUBSCRIPTION_PAGE } from "../../helpers/trackingConstants";
import { useDispatch, useSelector } from "react-redux";
import ProgressOverlay from "../loadingIndicator/progressOverlay";
import { RootState } from "../../app/store";
import { hasStripeSubscription, isMonthlySubscriber } from "../user/userSlice";

export function UpgradeToYearlySubscriptionPage()
{
  const dispatch = useDispatch();
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const monthlySubscriber = useSelector( ( state: RootState ) => isMonthlySubscriber( state ) );
  const userHasStripeSubscription = useSelector( ( state: RootState ) => hasStripeSubscription( state ) );
  const isMonthlyStripeSubscriber = monthlySubscriber && userHasStripeSubscription;

  useEffect( () =>
  {
    onMount()
  }, [] );

  function onMount()
  {
    amplitudeFeatureFlagsVariables.shouldShowSettingUpgradeYearlyOption().then( ( shouldShowSettingUpgradeYearlyOption ) =>
    {
      if ( shouldShowSettingUpgradeYearlyOption && isMonthlyStripeSubscriber )
      {
        dispatch( setUpsellShown( true ) );
        eventTracker.logUpgradeToYearlyUpsellShown( UPSELL_SOURCE_SUBSCRIPTION_PAGE);
      }
      navigateWithSearchParams( ROUTES.PLAN );
    } );
  }

  return <Stack spacing={10} sx={{ textAlign: "left", px: 5, pt: 5 }} alignItems="start">
    <Helmet>
      <title>{ROUTE_SEO.UPGRADE_TO_YEARLY_SUBSCRIPTION.title}</title>
      <meta name="description" content={ROUTE_SEO.UPGRADE_TO_YEARLY_SUBSCRIPTION.description}/>
    </Helmet>
    <ProgressOverlay/>
  </Stack>
}
