import { Box } from "@mui/material";
import React from "react";
import { GlobalErrorDialogCallbackProps } from "./globalErrorDialogFactory";
import { ConfirmDialogBodyTypography } from "../ui/confirmDialogBodyTypography";
import { GlobalErrorConfirmDialog } from "../ui/globalErrorConfirmDialog";

export default function YoutubeOauthErrorNoLinkedChannelDialog( props: GlobalErrorDialogCallbackProps )
{
  function getDialogContent()
  {
    return <Box sx={{ textAlign: "left", px: 20 }}>
      <ConfirmDialogBodyTypography>There are no YouTube Channels associated with your Google Account</ConfirmDialogBodyTypography>
      <br/>
      <ConfirmDialogBodyTypography>Before attempting to connect again, add a YouTube Channel, or connect with a different Google
                                   Account.</ConfirmDialogBodyTypography>
    </Box>
  }

  return <GlobalErrorConfirmDialog {...props}
                                   title={"YouTube Channel not found"}
                                   dialogContent={getDialogContent()}/>
}