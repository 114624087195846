import { amplitudeFeatureFlagsWrapper } from "./amplitudeFeatureFlagsWrapper";

let injectableAmplitudeFeatureFlagsWrapper: typeof amplitudeFeatureFlagsWrapper;
export const GRANDFATHERED_DEFAULT_TO_PLAN_TAB = "grandfathereddefaulttoplantab";
export const SHOULD_SHOW_PAYWALL_WITH_YEARLY_OPTION = "should_show_paywall_with_yearly_option";
export const SHOULD_SHOW_YEARLY_UPGRADE_IN_SETTINGS = "should_show_yearly_upgrade_in_settings";
export const SHOULD_RESCRAPE_WEBSITE_ON_EXTEND_PLAN = "should_rescrape_website_on_extend_plan";
export const SHOULD_USE_NEW_MEDIA_DESCRIPTION_AND_PLAN_RULES = "should_use_new_media_description_and_plan_rules";
export const SHOULD_SHOW_CAROUSEL_SIGN_IN_PAGE = "should_show_carousel_sign_in_page";

const BOOLEAN_FEATURE_FLAGS: string[] = [GRANDFATHERED_DEFAULT_TO_PLAN_TAB,
                                         SHOULD_RESCRAPE_WEBSITE_ON_EXTEND_PLAN,
                                         SHOULD_SHOW_PAYWALL_WITH_YEARLY_OPTION,
                                         SHOULD_SHOW_YEARLY_UPGRADE_IN_SETTINGS,
                                         SHOULD_SHOW_CAROUSEL_SIGN_IN_PAGE];

async function getAmplitudeVariablesForSignIn()
{
  const shouldRescrapeWebsiteOnExtendPlanValue = await shouldRescrapeWebsiteOnExtendPlan()
  const shouldUseNewMediaDescriptionAndPlanRulesValue = await shouldUseNewMediaDescriptionAndPlanRules()
  return {
    should_rescrape_website_on_extend_plan_feature: shouldRescrapeWebsiteOnExtendPlanValue,
    should_use_new_media_description_and_plan_rules_feature: shouldUseNewMediaDescriptionAndPlanRulesValue,
  }
}

function shouldDefaultToPlanTab()
{
  return isFeatureEnabled( GRANDFATHERED_DEFAULT_TO_PLAN_TAB )
}

function shouldRescrapeWebsiteOnExtendPlan()
{
  return isFeatureEnabled( SHOULD_RESCRAPE_WEBSITE_ON_EXTEND_PLAN );
}

function shouldAllowUserUploadedMusic()
{
  return true;
}

function shouldShowWaveformStartTimeSelection()
{
  return true;
}

function shouldAllowVideoAudioMusic()
{
  return true;
}

function shouldShowMusicFavorites()
{
  return true;
}

function shouldShowPaywallWithYearlyOption()
{
  return isFeatureEnabled( SHOULD_SHOW_PAYWALL_WITH_YEARLY_OPTION );
}

function shouldShowSettingUpgradeYearlyOption()
{
  return isFeatureEnabled( SHOULD_SHOW_YEARLY_UPGRADE_IN_SETTINGS );
}

function shouldUseNewMediaDescriptionAndPlanRules()
{
  return isFeatureEnabled( SHOULD_USE_NEW_MEDIA_DESCRIPTION_AND_PLAN_RULES )
}

function shouldShowCarouselSignInPage()
{
  return isFeatureEnabled( SHOULD_SHOW_CAROUSEL_SIGN_IN_PAGE );
}

function isFeatureEnabled( name: string ): Promise<boolean>
{
  return getWrapper().isFeatureFlagEnabled( name );
}

function getWrapper()
{
  return injectableAmplitudeFeatureFlagsWrapper ? injectableAmplitudeFeatureFlagsWrapper : amplitudeFeatureFlagsWrapper;
}

export const amplitudeFeatureFlagsVariables = {
  BOOLEAN_FEATURE_FLAGS,
  getAmplitudeVariablesForSignIn,
  shouldDefaultToPlanTab,
  shouldShowMusicFavorites,
  isFeatureEnabled,
  shouldAllowUserUploadedMusic,
  shouldShowWaveformStartTimeSelection,
  shouldAllowVideoAudioMusic,
  shouldRescrapeWebsiteOnExtendPlan,
  shouldShowPaywallWithYearlyOption,
  shouldShowSettingUpgradeYearlyOption,
  shouldUseNewMediaDescriptionAndPlanRules,
  shouldShowCarouselSignInPage,
};
