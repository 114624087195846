import { values } from "lodash";
import { AspectRatio } from "./postIdea/postsSlice";
import { OverridableStringUnion } from "@mui/types";
import { ButtonPropsColorOverrides } from "@mui/material/Button/Button";

export const ROUTES = {
  ROOT: "/",
  CHAT: "/chat",
  USER_SETTINGS: "/user_settings",
  PLAN: "/plan",
  MEDIA_LIBRARY: "/media_library",
  SIGN_IN: "/sign_in",
  BRANDING: "/branding",
  STYLE_QUIZ: "/style_quiz",
  SOCIAL_MEDIA_ACCOUNTS: "/social_media_accounts",
  PLAN_SETTINGS: "/plan_settings",
  ACCOUNT_SETTINGS: "/account_settings",
  UNSUBSCRIBE: "/unsubscribe",
  SELECT_BUSINESSES: "/select_businesses",
  UPGRADE_TO_YEARLY_SUBSCRIPTION: "/upgrade_to_yearly_subscription",
  ADD_BUSINESS_PURCHASE_COMPLETED: "/add_business_purchase_completed",
  NEW_SUPPORT_TICKET: "/new_support_ticket",
  DELETE_MY_ACCOUNT: "/delete_my_account",
}

export const ROUTE_PARAM = {
  CHANGE_BUSINESSES: "change_businesses",
  PLAN_TAB_POST_COMPLETE: "post_complete",
  PLAN_TAB_REFRESH_REQUEST: "refresh",
  PLAN_TAB_DATE: "date",
  SETTINGS_TAB_UPSELL: "upsell",
  RETURN_FROM_STRIPE: 'return_from_stripe',
  UPGRADE_TO_YEARLY_COMPLETED: 'upgrade_to_yearly_completed',
}

export const ROUTE_SEO = {
  ROOT: {
    title: "Alkai - Your AI-Powered Social Media Assistant",
    description: "Elevate your social media strategy with Alkai, the AI assistant that helps generate creative content ideas and notifications for draft readiness. Transform your social presence today!",
  },
  CHAT: {
    title: "Alkai Chat – Your AI Social Media Companion",
    description: "Need creative assistance? Chat with Alkai for real-time insights and support to boost your social media content. Start enhancing your strategy with AI-driven suggestions!",
  },
  USER_SETTINGS: {
    title: "Manage Your Alkai Account Settings",
    description: "Update your business profile and manage preferences. Connect with support or follow us on social media to maximize your use of our AI-powered tools.",
  },
  MEDIA_LIBRARY: {
    title: "Alkai Media Library",
    description: "Manage your media assets with Alkai. Upload, organize, and access your images and videos to streamline your social media content creation. Get started now!",
  },
  PLAN: {
    title: "Social Media Planning with Alkai",
    description: "Alkai helps outline effective social media plans tailored to your business. Receive notifications when your drafts are ready to review. Plan smarter with AI guidance!",
  },
  SIGN_IN: {
    title: "Sign In to Your Alkai Account",
    description: "Access your Alkai account to manage your AI-generated social media plans and review ready-to-publish drafts. Log in now to streamline your social media management!"
  },
  BRANDING: {
    title: "Personalize Your Branding on Social Media with Alkai",
    description: "Take full control of your brand's appearance on social media with Alkai. Add your logo, tagline, and handle to personalize your posts."
  },
  STYLE_QUIZ: {
    title: "Alkai Style Quiz",
    description: "Discover your brand's style with Alkai's style quiz. Answer a few questions to receive personalized recommendations for your social media content."
  },
  POSTING_STRATEGY_QUIZ: {
    title: "Alkai Posting Strategy Quiz",
    description: "Take the Alkai Posting Strategy Quiz to receive personalized recommendations for your social media content."
  },
  SELECT_BUSINESSES: {
    title: "Select which businesses to use with Alkai",
    description: "Change businesses to manage multiple brands with Alkai. Switch between businesses to access unique social media plans and content ideas."
  },
  UPGRADE_TO_YEARLY_SUBSCRIPTION: {
    title: "Upgrade to yearly subscription with Alkai",
    description: "Switch to a yearly subscription and save!"
  },
  SOCIAL_MEDIA_ACCOUNTS: {
    title: "Connect Social Media Accounts with Alkai",
    description: "Link your social media accounts to Alkai to streamline your content creation process. Access AI-generated ideas and drafts for easy publishing."
  },
  PLAN_SETTINGS: {
    title: "Alkai Plan Settings",
    description: "Customize your social media plans with Alkai. Set your posting frequency and content goals to receive personalized content ideas and drafts."
  },
  ACCOUNT_SETTINGS: {
    title: "Alkai Account Settings",
    description: "Manage your Alkai account settings."
  },
  NEW_SUPPORT_TICKET: {
    title: "Contact Alkai Support",
    description: "Submit a support ticket to the Alkai Support team for assistance with your account, social media plans, or content creation."
  },
  DELETE_ACCOUNT_REQUEST: {
    title: "Contact Alkai Support",
    description: "Submit a request to delete your Alkai account."
  }

}

export const PLAN_TAB_WEEK_HEADER_Z_INDEX = 1;
export const PLAN_TAB_BLURRED_BACKDROP_Z_INDEX = PLAN_TAB_WEEK_HEADER_Z_INDEX + 1;
export const PLAN_TAB_COMPLETED_POSTS_EDIT_BUTTON_TEXT = "Duplicate"
export const PLAN_TAB_COMPLETED_POSTS_PUBLISHING_TEXT = "Publishing"
export const PLAN_TAB_COMPLETED_POSTS_SHARE_AGAIN_TEXT = "Share again"

export const TERMS_OF_USE_URL = "https://www.alkai.ai/terms-of-use/";
export const PRIVACY_POLICY_URL = "https://www.alkai.ai/privacy-policy/";
export const ALKAI_BLOG_URL = "https://www.alkai.ai/blog?s=settings";
export const ALKAI_GET_THE_APP_WEBSITE_URL = "https://www.alkai.ai/get-the-app";
export const ALKAI_GET_THE_APP_APPSTORE_URL = "https://apps.apple.com/us/app/alkai-ai-social-media-pro/id6467685525";
export const FACEBOOK_ALKAI_URL = "https://www.facebook.com/heyalkai";
export const INSTAGRAM_ALKAI_URL = "https://www.instagram.com/alkai.ai";
export const ALKAI_FAQ_URL = "https://help.alkai.ai";
export const ALKAI_FAQ_ARTICLE_URL = ALKAI_FAQ_URL + "/en/article/";
export const ALKAI_FAQ_CONFIGURE_PLAN_URL = ALKAI_FAQ_ARTICLE_URL + "managing-your-posting-plan-1f9xbfz/";
export const ALKAI_HOW_TO_CONNECT_SOCIAL_FAQ_URL = ALKAI_FAQ_ARTICLE_URL + "connect-your-social-media-accounts-step-by-step-guide-w6oz7q/";
export const ALKAI_SUBSCRIPTION_TERMS_FAQ_URL = ALKAI_FAQ_ARTICLE_URL + "alkai-subscription-terms-16uxmng/";
export const ALKAI_FAQ_NO_FACEBOOK_PAGE_URL = ALKAI_FAQ_URL + "/en/article/social-accounts-no-facebook-page-nwpxdn/";
export const ALKAI_FAQ_UNABLE_TO_CONNECT_INSTAGRAM_URL = ALKAI_FAQ_URL + "/en/article/social-account-unable-to-connect-to-instagram-gf7gx7/";
export const ALKAI_FAQ_POP_UP_BLOCKERS_URL = ALKAI_FAQ_URL;

export const ALKAI_SUPPORT_EMAIL = "alkai-feedback@alkai.ai"

export const FEATURE_FLAG_DEV_MODE = "devMode";
export const FEATURE_FLAGS = [FEATURE_FLAG_DEV_MODE];
export const STOCK_MEDIA_SEARCH_TYPE_PHOTO = "photo";
export const STOCK_MEDIA_SEARCH_TYPE_VIDEO = "video";

export const MEDIA_ASSET_FILE_TYPE_PHOTO = "photo";
export const MEDIA_ASSET_FILE_TYPE_VIDEO = "video";
export const MEDIA_ASSET_FILE_TYPE_UNSUPPORTED = "unsupported";
export const STOCK_MEDIA_PEXELS_ATTRIBUTION = "POWERED BY PEXELS";
export const STOCK_MEDIA_PEXELS_ATTRIBUTION_LINK = "https://www.pexels.com/";

export type StockMediaSearchType = typeof STOCK_MEDIA_SEARCH_TYPE_PHOTO | typeof STOCK_MEDIA_SEARCH_TYPE_VIDEO;

export const STABLE_DIFFUSION_SOURCE_MEDIA_ASSET = "stable_diffusion";
export const STOCK_MEDIA_SOURCE_MEDIA_ASSET = "stock_media";
export const CURATED_MEDIA_SOURCE_MEDIA_ASSET = "curated_media";
export const USER_UPLOAD_SOURCE_MEDIA_ASSET = "user_upload";
export const MEDIA_ASSET_SOURCE_WEBSITE = "website";

export const STABILITY_STYLE_PRESET_CONSTANTS = {
  PRESET_3D_MODEL: "3d-model",
  PRESET_ANALOG_FILM: "analog-film",
  PRESET_ANIME: "anime",
  PRESET_CINEMATIC: "cinematic",
  PRESET_COMIC_BOOK: "comic-book",
  PRESET_DIGITAL_ART: "digital-art",
  PRESET_ENHANCE: "enhance",
  PRESET_FANTASY_ART: "fantasy-art",
  PRESET_ISOMETRIC: "isometric",
  PRESET_LINE_ART: "line-art",
  PRESET_LOW_POLY: "low-poly",
  PRESET_MODELING_COMPOUND: "modeling-compound",
  PRESET_NEON_PUNK: "neon-punk",
  PRESET_ORIGAMI: "origami",
  PRESET_PHOTOGRAPHIC: "photographic",
  PRESET_PIXEL_ART: "pixel-art",
  PRESET_TILE_TEXTURE: "tile-texture",
}

export const STABILITY_STYLE_PRESETS = values( STABILITY_STYLE_PRESET_CONSTANTS )

export type MediaAssetSource =
  typeof STOCK_MEDIA_SOURCE_MEDIA_ASSET
  | typeof USER_UPLOAD_SOURCE_MEDIA_ASSET
  | typeof STABLE_DIFFUSION_SOURCE_MEDIA_ASSET
  | typeof MEDIA_ASSET_SOURCE_WEBSITE
  | typeof CURATED_MEDIA_SOURCE_MEDIA_ASSET;

export const MAX_MESSAGES_FROM_OPEN_AI = 20;
export const MESSAGE_TYPE_TEXT_REQUEST = "text_request";
export const MESSAGE_TYPE_FREQUENCY_REQUEST = "frequency_request";
export const MESSAGE_TYPE_MULTI_TOPICS_APPROVAL = "multi_topics_approval";
export const MESSAGE_TYPE_REEL_APPROVAL = "reel_approval";
export const MESSAGE_TYPE_MULTIPLE_POST_IDEAS = "multiple_post_ideas";
export const MESSAGE_TYPE_DISPLAY = "display";
export const MESSAGE_TYPE_THREAD_MESSAGE = "thread_message"
export const MESSAGE_TYPE_THREAD_IMAGE = "thread_image"

export const MESSAGE_ROLE_USER = "user";
export const MESSAGE_ROLE_ASSISTANT = "assistant";

export const ENDPOINT_AUTH = "auth";
export const ENDPOINT_AUTH_SIGN_IN = "auth/sign_in";
export const ENDPOINT_AUTH_SIGN_OUT = "auth/sign_out";
export const ENDPOINT_AUTH_CONVERT_GUEST_TO_USER = "convert_guest_to_user";
export const ENDPOINT_AUTH_WRONG_SIGN_IN_INPUT = "users/wrong_sign_in_input";
export const ENDPOINT_AUTH_VERIFY_GUEST = "users/verify";
export const ENDPOINT_UPDATE_CLIENT_ACTIVITY = "users/update_client_activity";
export const ENDPOINT_REFRESH_USERS_ME = "users/me";
export const ENDPOINT_UPDATE_IOS_TRACKING_PERMISSIONS = "users/update_ios_tracking_permissions";

export const SEARCH_PARAM_KEY_SOURCE = "s";
export const SEARCH_PARAM_VALUE_UNKNOWN = "unknown";
export const SEARCH_PARAM_KEY_NOTIFY = "notify";
export const SEARCH_PARAM_KEY_WEBSITE = "website";
export const SEARCH_PARAM_KEY_POST_IDEA = "pi";
export const SEARCH_PARAM_KEY_BUSINESS_SLUG = "b";

export const CREATE_ACCOUNT_SHOWN_VIA_SIGN_IN_LANDING_PAGE = "sign_in_landing_page";
export const CREATE_ACCOUNT_SHOWN_VIA_SHARE_BUTTON = "share_button";
export const CREATE_ACCOUNT_SHOWN_VIA_USER_SETTINGS = "user_settings";
export const CREATE_ACCOUNT_SHOWN_VIA_PLAN_DIALOG = "plan_dialog";
export const CREATE_ACCOUNT_SHOWN_VIA_CHECKOUT = "checkout";
export const CREATE_ACCOUNT_SHOWN_VIA_POST_PREVIEW = "post_preview";

export type ShareDrawerMode =
  typeof SHARE_DRAWER_MODE_CHOOSE_NOW_OR_LATER
  | typeof SHARE_DRAWER_MODE_DIRECT_SHARE
  | typeof SHARE_DRAWER_MODE_SCHEDULE;

export const SHARE_DRAWER_MODE_CHOOSE_NOW_OR_LATER = "choose_now_or_later";
export const SHARE_DRAWER_MODE_DIRECT_SHARE = "direct_share";
export const SHARE_DRAWER_MODE_SCHEDULE = "schedule";

export const MUI_SHARE_DRAWER_CLOSE_REASON_BACKDROP_CLICK = "backdropClick";

export const SECONDARY_CONTRAST_TEXT_COLOR = '#9BA5B7'

export const APP_TAB_BAR_HEIGHT = 56;

export const EVENT_CODE_KEY_ENTER = 'Enter'

export const MAX_NUMBER_OF_MEDIA = 30;
export const MAX_NUMBER_OF_MEDIA_ERROR = `Sorry, you've reached the ${MAX_NUMBER_OF_MEDIA} media limit.`;

export const MAX_NUMBER_OF_LOGOS = 1;
export const MAX_NUMBER_OF_LOGOS_ERROR = `Sorry, you can not select more than ${MAX_NUMBER_OF_LOGOS} logo.`;

export const TAB_MINIMUM_WIDTH = "70px"

export const NAVIGATE_PREVIOUS = "previous";
export const NAVIGATE_NEXT = "next";
export type NavigateDirection = typeof NAVIGATE_PREVIOUS | typeof NAVIGATE_NEXT;
export const DESIGN_HTML_NEWLINE_SEQUENCE = "<br/>";
export const NOT_NOW = "Not now";

export const KEYBOARD_ENTER_KEY_ID = 13;

export const GIF = "gif";
export const WEBP = "webp";
export const PNG = "png";
export const MP4 = "mp4";
export const MOV = "mov";

export const INSTAGRAM_RECOMMENDED_HASHTAGS_COUNT = 6
export const INSTAGRAM_MAX_HASHTAGS_COUNT = 30;

export enum SOURCES_FROM_ALKAI_CRM
{
  DAY_ONE_NUDGE_EMAIL_CRM_SOURCE = "alkai_email1",
  DAY_ONE_NUDGE_SMS_CRM_SOURCE = "alkai_sms1",
  INTRO_EMAIL_CRM_SOURCE = "alkai_email",
  INTRO_SMS_CRM_SOURCE = "alkai_sms",
}

export enum MediaPickingMode
{
  ReplaceSingle = "REPLACE_SINGLE",
  Add = "ADD",
  Overwrite = "OVERWRITE",
}

export enum DesignOutputMode
{
  Static = "static",
  Animated = "animated",
}

export const NO_MUSIC_TRACK_DISPLAY_NAME = "No music"
export const VIDEO_AUDIO_MUSIC_TRACK = "video audio"
export const TRACK_TYPE_EPIDEMIC_SOUND = "epidemicSound";
export const TRACK_TYPE_MELODIE = "melodie";
export const NO_MUSIC_TRACK_ID = "";
export const TRACK_TYPE_NO_MUSIC = "noMusic";
export const TRACK_TYPE_VIDEO_AUDIO = "videoAudio";
export const TRACK_TYPE_UPLOADED = "uploaded"
export const DEFAULT_TRACK_DISPLAY_NAME = "Current track";
export const DEFAULT_VIDEO_AUDIO_TRACK_DISPLAY_NAME = "Audio from media in post";

export const MELODIE_ENTITY_TYPE_GENRE_GROUPS_API = "genre_groups";
export const MELODIE_ENTITY_TYPE_PURPOSES_API = "purposes";

export enum EditTab
{
  None = -1,
  Layout = 0,
  HolidayLayouts = 1,
  Colors = 2,
  Fonts = 3,
  Text = 4,
  Media = 5,
  Music = 6,
  Brand = 7,
}

export enum StripeCheckoutSessionStatus
{
  Complete = 'complete',
  Open = 'open'
}

export const BOTTOM_NAV_ID_PREVIEW_TAB = "previewTab"
export const BOTTOM_NAV_ID_TEXT_TAB = "textTab"
export const BOTTOM_NAV_ID_MEDIA_TAB = "mediaTab"
export const BOTTOM_NAV_ID_MUSIC_TAB = "musicTab"
export const BOTTOM_NAV_ID_COLORS_TAB = "colorsTab"
export const BOTTOM_NAV_ID_FONTS_TAB = "fontsTab"
export const BOTTOM_NAV_ID_BRAND_TAB = "brandTab"
export const PREVIEW_NAV_ID_PREVIOUS_VARIANT = "previousVariant"
export const PREVIEW_NAV_ID_NEXT_VARIANT = "nextVariant"

export const Z_INDEX_DRAWER = 1300;
export const BOTTOM_NAV_EDIT_DRAWER_HEIGHT_PX = 300;
export const BOTTOM_NAV_SCROLLABLE_ITEM = 50;
export const BOTTOM_NAV_EXTRA_SCROLLBAR_SPACING = 20;
export const BOTTOM_NAV_SCROLLABLE_CONTENT_HEIGHT_ONE_ROW = BOTTOM_NAV_SCROLLABLE_ITEM * 1 + BOTTOM_NAV_EXTRA_SCROLLBAR_SPACING;
export const BOTTOM_NAV_SCROLLABLE_CONTENT_HEIGHT_TWO_ROWS = BOTTOM_NAV_SCROLLABLE_ITEM * 2 + BOTTOM_NAV_EXTRA_SCROLLBAR_SPACING;
export const CURRENT_COLOR_PALETTE_SECTION_HEIGHT_PX = BOTTOM_NAV_SCROLLABLE_ITEM;
export const CURRENT_FONT_SET_SECTION_HEIGHT_PX = BOTTOM_NAV_SCROLLABLE_ITEM;
export const COLOR_PALETTE_BUTTON_HEIGHT_PX = BOTTOM_NAV_SCROLLABLE_ITEM;
export const COLOR_PALETTE_BUTTON_MIN_WIDTH_PX = 120;
export const LAYOUT_BUTTON_MIN_WIDTH_PX = 70;
export const LAYOUT_BUTTON_HEIGHT_PX = 90;
export const LAYOUT_BUTTON_SELECTED_HEIGHT_PX = 120;
export const BOTTOM_NAV_LAYOUTS_CONTENT_HEIGHT = LAYOUT_BUTTON_SELECTED_HEIGHT_PX + BOTTOM_NAV_EXTRA_SCROLLBAR_SPACING;

export const FONT_OPTION_HEIGHT_PX = 35;
export const FONT_SET_BUTTON_HEIGHT_PX = BOTTOM_NAV_SCROLLABLE_ITEM;
export const FONT_SET_BUTTON_MIN_WIDTH_PX = 120;

export const SIGN_IN_PROVIDER_PHONE_NUMBER = 'phone_number'
export const SIGN_IN_PROVIDER_EMAIL = 'email'

export type SignInProviderType = typeof SIGN_IN_PROVIDER_PHONE_NUMBER | typeof SIGN_IN_PROVIDER_EMAIL;

export const BRAND_CARD_PREVIEW_SOURCE_EDIT = "edit";
export const BRAND_CARD_PREVIEW_SOURCE_BRAND_SETTINGS = "brand_settings";

export type BrandCardPreviewSource = typeof BRAND_CARD_PREVIEW_SOURCE_EDIT | typeof BRAND_CARD_PREVIEW_SOURCE_BRAND_SETTINGS;

export const PHONE_NUMBER_SIGN_IN_VERIFY_SUCCESS = "Phone verified."

export const EMAIL_SIGN_IN_VERIFY_SUCCESS = "Email verified."

export const PHONE_NUMBER_SIGN_IN_VERIFY_FAILED = "Phone verification failed or expired. Please try again or request a new code."

export const EMAIL_SIGN_IN_VERIFY_FAILED = "Email verification failed or expired. Please try again or request a new code."

export const ASSISTANT_CHAT_CHECK_MESSAGES_ATTEMPTS = 60;

export const MESSAGE_FADE_IN_DURATION = 1000

export const QUICK_ACTIONS_WAIT_TIME_MS = 120000;

export const UPSELL_GRADIENT = "linear-gradient(238deg,#e0546b 0.63%,#af4c80 47.83%,#6e3f9a 93.1%)";
export const PAYWALL_GRADIENT = "linear-gradient(238deg,#6e3f9a 0.63%,#af4c80 47.83%,#e0546b 93.1%)";
export const YEARLY_PAYWALL_BUTTON_GRADIENT = "linear-gradient(90deg,#5F3DA1,#23163B 160%)";
export const STREAK_SUCCESS_GRADIENT = UPSELL_GRADIENT;
export const POST_SUCCESS_GRADIENT = "linear-gradient(202.24deg, #FA585E 0%, #AF4C80 100%)";

export const ALKAI_ONBOARDING_FIRST_CLIENT_SIDE_MESSAGE_DEFAULT_OLDEST_DATE_IMAGINABLE = "2000-01-01";

export const ALKAI_ONBOARDING_FIRST_CLIENT_SIDE_MESSAGE = "Hi, I'm Alkai. Your dedicated social media assistant!🔥\n\n"
                                                          + "I know it can be hard to be consistent in posting. I'm here to help you stay on track by providing ideas, video and a strategy for your social. ✨\n"
                                                          + "\nWhat would like to do first? 🤔";

export const POSTING_STREAK_NOTIFICATION_STATUS_UNREAD = 'unread'

export const USER_FEEDBACK_STATUS_SUBMITTED = "submitted";
export const USER_FEEDBACK_STATUS_SHOWN = "shown";
export const USER_FEEDBACK_STATUS_CANCELED = "canceled";

//Constants for website input from landing page
export const LANDING_WITHOUT_WEBSITE = "__LANDING_WITHOUT_WEBSITE__"
export const LANDING_WITHOUT_WEBSITE_USER_MESSAGE = "Continue without a website"

export const SURVEY_MONKEY_JUNE_2024_SURVEY_ID = "9HFZDDW";

export const UPLOAD_MEDIA_BUTTON_TEXT = "Upload media";
export const ADD_FROM_ANOTHER_SOURCE_BUTTON_TEXT = "Add from another source";
export const POST_MEDIA_PICKER_TITLE = "Add media to post";
export const MEDIA_TAB_ID_UPLOAD = 1;
export const MEDIA_TAB_ID_WEBSITE = 2;
export const MEDIA_TAB_ID_SEARCH = 3;
export const MEDIA_TAB_ID_GENERATE = 4;
export const MEDIA_TAB_ID_CURATED_SET = 5;

export const BUSINESS_MEDIA_PICKER_TITLE = "Add media to library";

export const POST_VIEW_DATE_FORMAT_DAY_DIFFERENT_THAN_PLAN = "MMM do, h:mm a";
export const POST_VIEW_DATE_FORMAT_DAY_SAME_AS_PLAN = "h:mm a";
export const START_VIDEO_TIME_TO_SHOW_FRAME_BEFORE_TAP = '#t=0.01';

export const ERROR_MESSAGE_REFRESH_AND_TRY_AGAIN = "Hmm, there was a problem. Please refresh and try again.";

export const HOLIDAY_PRESET_NAME = "holiday";
export const CONNECTS_WITH_RIPL_TEXT = "* Connects using Facebook Login with our parent company Ripl, Inc.";

export type OutputFormatSlug = typeof OUTPUT_FORMAT_SLUG_SQUARE_STATIC |
  typeof OUTPUT_FORMAT_SLUG_PORTRAIT_VIDEO |
  typeof OUTPUT_FORMAT_SLUG_SQUARE_VIDEO |
  typeof OUTPUT_FORMAT_SLUG_PORTRAIT_STATIC |
  typeof OUTPUT_FORMAT_SLUG_LANDSCAPE_STATIC |
  typeof OUTPUT_FORMAT_SLUG_LANDSCAPE_VIDEO |
  typeof OUTPUT_FORMAT_SLUG_CAROUSEL;

export const OUTPUT_FORMAT_SLUG_SQUARE_STATIC = "5bhpc0qmkhrqsq5j_wbwhg";
export const OUTPUT_FORMAT_SLUG_PORTRAIT_VIDEO = "k5whbwc1tte5x_ddoclu9g";
export const OUTPUT_FORMAT_SLUG_SQUARE_VIDEO = "f4itmybnbg9-cwujeb25yg";
export const OUTPUT_FORMAT_SLUG_PORTRAIT_STATIC = "eih-nroclozxqwxksskpzg";
export const OUTPUT_FORMAT_SLUG_LANDSCAPE_STATIC = "mtn_s7vi-dgbmiscjqwvda";
export const OUTPUT_FORMAT_SLUG_LANDSCAPE_VIDEO = "dpirhs635gmst8pj-zmoaa";
export const OUTPUT_FORMAT_SLUG_CAROUSEL = "2i9ps3k4s83fyuriwelmw";

export const OUTPUT_FORMAT_BEST_FOR_REELS_ICON_NAME = "reels.svg"
export const OUTPUT_FORMAT_BEST_FOR_FEED_ICON_NAME = "feed.svg"
export const OUTPUT_FORMAT_BEST_FOR_STORIES_ICON_NAME = "stories.svg"
export const OUTPUT_FORMAT_BEST_FOR_FACEBOOK_ICON_NAME = "facebook.svg"
export const OUTPUT_FORMAT_BEST_FOR_INSTAGRAM_ICON_NAME = "instagram.svg"
export const OUTPUT_FORMAT_BEST_FOR_TIKTOK_ICON_NAME = "tiktok.svg"
export const OUTPUT_FORMAT_BEST_FOR_PINTEREST_ICON_NAME = "pinterest.svg"
export const OUTPUT_FORMAT_BEST_FOR_YOUTUBE_ICON_NAME = "youtube.svg"
export const OUTPUT_FORMAT_BEST_FOR_YOUTUBE_SHORTS_ICON_NAME = "youtube_shorts.svg"
export const OUTPUT_FORMAT_BEST_FOR_MESSENGER_ICON_NAME = "facebook_messenger.svg"
export const OUTPUT_FORMAT_BEST_FOR_SHOPIFY_ICON_NAME = "shopify.svg"
export const OUTPUT_FORMAT_BEST_FOR_ETSY_ICON_NAME = "etsy.svg"
export const OUTPUT_FORMAT_BEST_FOR_LINKEDIN_ICON_NAME = "linkedin.svg"
export const OUTPUT_FORMAT_BEST_FOR_SNAP_ICON_NAME = "snap.svg"
export const OUTPUT_FORMAT_BEST_FOR_THREADS_ICON_NAME = "threads.svg"
export const OUTPUT_FORMAT_BEST_FOR_X_ICON_NAME = "x.svg"
export const OUTPUT_FORMAT_BEST_FOR_WHATSAPP_ICON_NAME = "whatsapp.svg"

export const OUTPUT_FORMAT_ASPECT_RATIO_SQUARE = "square";
export const OUTPUT_FORMAT_ASPECT_RATIO_VERTICAL = "vertical";
export const OUTPUT_FORMAT_ASPECT_RATIO_LANDSCAPE = "landscape";

export type OutputFormatAspectRatio = typeof OUTPUT_FORMAT_ASPECT_RATIO_SQUARE |
  typeof OUTPUT_FORMAT_ASPECT_RATIO_VERTICAL |
  typeof OUTPUT_FORMAT_ASPECT_RATIO_LANDSCAPE;

export interface OutputFormatDisplayProperties
{
  displayName: string,
  displayResolution: string,
  icon_file_name: string,
  bestForIconsLine1?: string[],
  bestForIconsLine2?: string[],
  smallSxProps: { width: string, height: string }
  largeSxProps: { width: string, height: string }
  previewPlaceholderAspect: OutputFormatAspectRatio;
  aspectRatio: AspectRatio;
}

export type OutputFormatDisplayPropertiesBySlug = {
  [slug in OutputFormatSlug]: OutputFormatDisplayProperties;
};

export const VIDEO_OUTPUT_FORMAT_SLUGS = [
  OUTPUT_FORMAT_SLUG_SQUARE_VIDEO,
  OUTPUT_FORMAT_SLUG_PORTRAIT_VIDEO,
  OUTPUT_FORMAT_SLUG_LANDSCAPE_VIDEO
]

export const OUTPUT_FORMAT_PROPERTIES_BY_SLUG: OutputFormatDisplayPropertiesBySlug = {
  [OUTPUT_FORMAT_SLUG_SQUARE_STATIC]: {
    displayName: "Square Image",
    displayResolution: "1080 x 1080 px",
    icon_file_name: "square_image.png",
    bestForIconsLine1: [OUTPUT_FORMAT_BEST_FOR_FACEBOOK_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_INSTAGRAM_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_FEED_ICON_NAME],
    bestForIconsLine2: [OUTPUT_FORMAT_BEST_FOR_THREADS_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_LINKEDIN_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_X_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_PINTEREST_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_ETSY_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_SHOPIFY_ICON_NAME],
    smallSxProps: { width: "20px", height: "20px" },
    largeSxProps: { width: "52px", height: "52px" },
    previewPlaceholderAspect: OUTPUT_FORMAT_ASPECT_RATIO_SQUARE,
    aspectRatio: AspectRatio.Square
  },
  [OUTPUT_FORMAT_SLUG_PORTRAIT_VIDEO]: {
    displayName: "Vertical Video",
    displayResolution: "1080 x 1920 px",
    icon_file_name: "portrait_video.png",
    bestForIconsLine1: [OUTPUT_FORMAT_BEST_FOR_FACEBOOK_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_INSTAGRAM_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_REELS_ICON_NAME],
    bestForIconsLine2: [OUTPUT_FORMAT_BEST_FOR_TIKTOK_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_YOUTUBE_SHORTS_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_MESSENGER_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_WHATSAPP_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_ETSY_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_SHOPIFY_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_SNAP_ICON_NAME],
    smallSxProps: { width: "20px", height: "35px" },
    largeSxProps: { width: "35px", height: "62px" },
    previewPlaceholderAspect: OUTPUT_FORMAT_ASPECT_RATIO_VERTICAL,
    aspectRatio: AspectRatio.Portrait
  },
  [OUTPUT_FORMAT_SLUG_SQUARE_VIDEO]: {
    displayName: "Square Video",
    displayResolution: "1080 x 1080 px",
    icon_file_name: "square_video.png",
    bestForIconsLine1: [OUTPUT_FORMAT_BEST_FOR_FACEBOOK_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_INSTAGRAM_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_FEED_ICON_NAME],
    bestForIconsLine2: [OUTPUT_FORMAT_BEST_FOR_LINKEDIN_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_YOUTUBE_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_PINTEREST_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_SHOPIFY_ICON_NAME],
    smallSxProps: { width: "20px", height: "20px" },
    largeSxProps: { width: "52px", height: "52px" },
    previewPlaceholderAspect: OUTPUT_FORMAT_ASPECT_RATIO_SQUARE,
    aspectRatio: AspectRatio.Square
  },
  [OUTPUT_FORMAT_SLUG_PORTRAIT_STATIC]: {
    displayName: "Vertical Image",
    displayResolution: "1080 x 1920 px",
    icon_file_name: "portrait_image.png",
    bestForIconsLine1: [OUTPUT_FORMAT_BEST_FOR_FACEBOOK_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_INSTAGRAM_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_STORIES_ICON_NAME],
    bestForIconsLine2: [OUTPUT_FORMAT_BEST_FOR_MESSENGER_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_ETSY_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_SNAP_ICON_NAME],
    smallSxProps: { width: "20px", height: "35px" },
    largeSxProps: { width: "35px", height: "62px" },
    previewPlaceholderAspect: OUTPUT_FORMAT_ASPECT_RATIO_VERTICAL,
    aspectRatio: AspectRatio.Portrait
  },
  [OUTPUT_FORMAT_SLUG_LANDSCAPE_STATIC]: {
    displayName: "Landscape Image",
    displayResolution: "???",
    icon_file_name: "landscape_image.png",
    bestForIconsLine1: [OUTPUT_FORMAT_BEST_FOR_FACEBOOK_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_FEED_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_INSTAGRAM_ICON_NAME,
                        OUTPUT_FORMAT_BEST_FOR_STORIES_ICON_NAME],
    bestForIconsLine2: [],
    smallSxProps: { width: "35px", height: "25px" },
    largeSxProps: { width: "62px", height: "45px" },
    previewPlaceholderAspect: OUTPUT_FORMAT_ASPECT_RATIO_LANDSCAPE,
    aspectRatio: AspectRatio.Landscape
  },
  [OUTPUT_FORMAT_SLUG_LANDSCAPE_VIDEO]: {
    displayName: "Landscape Video",
    displayResolution: "???",
    icon_file_name: "landscape_video.png",
    bestForIconsLine1: [OUTPUT_FORMAT_BEST_FOR_YOUTUBE_ICON_NAME],
    bestForIconsLine2: [],
    smallSxProps: { width: "35px", height: "25x" },
    largeSxProps: { width: "62px", height: "45px" },
    previewPlaceholderAspect: OUTPUT_FORMAT_ASPECT_RATIO_LANDSCAPE,
    aspectRatio: AspectRatio.Landscape
  },
  [OUTPUT_FORMAT_SLUG_CAROUSEL]: {
    displayName: "Carousel",
    displayResolution: "???",
    icon_file_name: "carousel.png",
    bestForIconsLine1: [],
    bestForIconsLine2: [],
    smallSxProps: { width: "20px", height: "20px" },
    largeSxProps: { width: "52px", height: "52px" },
    previewPlaceholderAspect: OUTPUT_FORMAT_ASPECT_RATIO_SQUARE,
    aspectRatio: AspectRatio.Square
  }
}

export type ContentGoalEnum = typeof CONTENT_GOAL_PROMOTE |
  typeof CONTENT_GOAL_ENGAGE;

export const CONTENT_GOAL_PROMOTE = "promote";
export const CONTENT_GOAL_ENGAGE = "engage";
export const CONTENT_GOALS_LIST = [CONTENT_GOAL_PROMOTE, CONTENT_GOAL_ENGAGE];

export interface ContentGoalDisplayProperties
{
  displayName: string,
  getDescriptionOfGoal: string,
  icon_file_name: string,
  smallSxProps: { width: string, height?: string }
}

export type ContentGoalDisplayPropertiesByEnum = {
  [slug in ContentGoalEnum]: ContentGoalDisplayProperties;
};

export const CONTENT_GOAL_PROPERTIES_BY_ENUM: ContentGoalDisplayPropertiesByEnum = {
  [CONTENT_GOAL_PROMOTE]: {
    displayName: "Promote",
    getDescriptionOfGoal: "Posts that acquire leads, highlight products, sale events, or services.",
    icon_file_name: "promote.png",
    smallSxProps: { width: "20px" },
  },
  [CONTENT_GOAL_ENGAGE]: {
    displayName: "Engage",
    getDescriptionOfGoal: "Posts that are insightful, educational, ask questions, provide updates, recognize holidays, and build my community.",
    icon_file_name: "engage.png",
    smallSxProps: { width: "20px" },
  },
}

export const BUSINESS_SWITCHER_DEFAULT_BUSINESS_NAME = "New Business";

export const TIKTOK_PRIVACY_PUBLIC = "PUBLIC_TO_EVERYONE";
export const TIKTOK_PRIVACY_SELF_ONLY = "SELF_ONLY";
export const TIKTOK_PRIVACY_MUTUAL_FOLLOWING = "MUTUAL_FOLLOW_FRIENDS";
export const TIKTOK_PRIVACY_FOLLOWING_CREATOR = "FOLLOWER_OF_CREATOR";

export type TiktokPrivacyOption = typeof TIKTOK_PRIVACY_PUBLIC |
  typeof TIKTOK_PRIVACY_SELF_ONLY |
  typeof TIKTOK_PRIVACY_MUTUAL_FOLLOWING |
  typeof TIKTOK_PRIVACY_FOLLOWING_CREATOR;

export const TIKTOK_PRIVACY_OPTIONS_DISPLAY_NAME_MAP: { [key in TiktokPrivacyOption]: string } = {
  [TIKTOK_PRIVACY_PUBLIC]: "Public",
  [TIKTOK_PRIVACY_SELF_ONLY]: "Only you",
  [TIKTOK_PRIVACY_MUTUAL_FOLLOWING]: "Following each other",
  [TIKTOK_PRIVACY_FOLLOWING_CREATOR]: "Followers only",
}

export const YOUTUBE_PRIVACY_PUBLIC = "PUBLIC";
export const YOUTUBE_PRIVACY_UNLISTED = "UNLISTED";
export const YOUTUBE_PRIVACY_PRIVATE = "PRIVATE";

export type YoutubePrivacyOption = typeof YOUTUBE_PRIVACY_PUBLIC |
  typeof YOUTUBE_PRIVACY_UNLISTED |
  typeof YOUTUBE_PRIVACY_PRIVATE;

export const YOUTUBE_PRIVACY_OPTIONS_DISPLAY_NAME_MAP: { [key in YoutubePrivacyOption]: string } = {
  [YOUTUBE_PRIVACY_PUBLIC]: "Public",
  [YOUTUBE_PRIVACY_UNLISTED]: "Unlisted",
  [YOUTUBE_PRIVACY_PRIVATE]: "Private",
}

export const ALLOWED_MUSIC_FILE_EXTENSIONS = [
  '.mp3',
  ".m4a",
  ".wav",
  ".aac"
]

export const MAX_MUSIC_FILE_UPLOAD_SIZE_IN_BYTE = 20 * 1024 * 1024;

export const POST_IDEA_NOTIFICATION_NOT_FOUND_DIALOG = 'post_idea_notification_not_found_dialog';
export const YOUTUBE_OAUTH_ERROR_DIALOG_NO_LINKED_CHANNEL = 'youtube_oauth_error_dialog_no_linked_channel';
export const POPUP_BLOCKER_DETECTED = 'popup_blocker_detected';
export const FACEBOOK_ERROR_NO_LINKED_PAGE_DIALOG = 'facebook_error_no_linked_page_dialog';
export const FACEBOOK_INSTAGRAM_ERROR_NO_LINKED_PAGE_DIALOG = 'facebook_instagram_error_no_linked_page_dialog';

export type GlobalErrorDialogType = typeof POST_IDEA_NOTIFICATION_NOT_FOUND_DIALOG |
  typeof YOUTUBE_OAUTH_ERROR_DIALOG_NO_LINKED_CHANNEL |
  typeof POPUP_BLOCKER_DETECTED |
  typeof FACEBOOK_ERROR_NO_LINKED_PAGE_DIALOG |
  typeof FACEBOOK_INSTAGRAM_ERROR_NO_LINKED_PAGE_DIALOG;

export const YOUTUBE_OAUTH_ERROR_NO_LINKED_CHANNEL = 'youtube_oauth_error_no_linked_channel'
export type OauthErrorCode = typeof YOUTUBE_OAUTH_ERROR_NO_LINKED_CHANNEL;

export const SUBSCRIPTION_PLATFORM_IOS = 'ios';
export const SUBSCRIPTION_PLATFORM_STRIPE = 'stripe';
export type SubscriptionPlatform = typeof SUBSCRIPTION_PLATFORM_IOS | typeof SUBSCRIPTION_PLATFORM_STRIPE;

export const SUBSCRIPTION_DURATION_MONTHLY = 'monthly';
export const SUBSCRIPTION_DURATION_YEARLY = 'yearly';
export type SubscriptionDuration = typeof SUBSCRIPTION_DURATION_MONTHLY | typeof SUBSCRIPTION_DURATION_YEARLY;

export type ButtonColorType = OverridableStringUnion<
  'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
  ButtonPropsColorOverrides
>;

