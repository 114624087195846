import {
  DEFAULT_TRACK_DISPLAY_NAME,
  DEFAULT_VIDEO_AUDIO_TRACK_DISPLAY_NAME,
  NO_MUSIC_TRACK_DISPLAY_NAME,
  NO_MUSIC_TRACK_ID,
  TRACK_TYPE_MELODIE,
  TRACK_TYPE_NO_MUSIC,
  TRACK_TYPE_VIDEO_AUDIO,
  VIDEO_AUDIO_MUSIC_TRACK
} from "../constants";
import { CommonTrackAPI, GlobalSettingsMusicDataAPI, MelodieTrackAPI, MusicSelection } from "./musicSlice";
import { isEmpty, join } from "lodash";
import { MelodieFeatureEnabledReplaceMusicTrackAPI } from "../../services/postIdeaServices";

export const musicItemHelper = {
  getBottomText,
  isTrackIdSelected,
  displayNameForTrack,
  getMusicTrackFromGlobalSettingsMusicData,
  getMusicTrackFromPendingSelection,
  getNoMusicTrack,
  getVideoAudioMusicTrack,
  convertMusicTrackToReplaceMusicAPIPayload,
  getTrackIdForReporting,
}

function getBottomText( track: CommonTrackAPI )
{
  if ( isMelodieTrack( track ) )
  {
    const moods = (track as MelodieTrackAPI)?.moods;
    return join( moods, ", " );
  }
  return null;
}

function isMelodieTrack( commonTrackAPI: CommonTrackAPI )
{
  return commonTrackAPI.type === TRACK_TYPE_MELODIE;
}

function isNoMusicSelected( postIdeaTrackId: string, musicItemTrackId: string, postIdeaTrackType: string, musicItemTrackType: string )
{
  const isSameTrackType = musicItemTrackType === postIdeaTrackType;
  const isPostIdeaTrackEmpty = isEmpty( postIdeaTrackId );
  const isMusicItemTrackIdEmpty = isEmpty( musicItemTrackId );
  const isPostIdeaTrackTypeEmpty = isEmpty( postIdeaTrackType );
  const isNoMusicTrackType = musicItemTrackType === TRACK_TYPE_NO_MUSIC;
  const isEmptyForNoMusic = isNoMusicTrackType
                            && isPostIdeaTrackEmpty
                            && isMusicItemTrackIdEmpty
                            && (isSameTrackType || isPostIdeaTrackTypeEmpty);
  return isEmptyForNoMusic;
}

function isTrackIdSelected( musicItemTrackId: string, musicItemTrackType: string, postIdeaTrackId: string, postIdeaTrackType: string,
                            pendingSelection?: MusicSelection )
{
  if ( pendingSelection )
  {
    return pendingSelection?.id === musicItemTrackId && pendingSelection?.type === musicItemTrackType;
  }
  else
  {
    const isSameTrackType = musicItemTrackType === postIdeaTrackType;
    const isExactMatch = postIdeaTrackId === musicItemTrackId && isSameTrackType;

    const isEmptyForNoMusic = isNoMusicSelected( postIdeaTrackId, musicItemTrackId, postIdeaTrackType, musicItemTrackType );

    return isExactMatch || isEmptyForNoMusic;
  }
}

function displayNameForTrack( type: string, displayName: string | undefined )
{
  if ( type === TRACK_TYPE_NO_MUSIC )
  {
    return NO_MUSIC_TRACK_DISPLAY_NAME;
  }
  else if ( type === TRACK_TYPE_VIDEO_AUDIO )
  {
    return DEFAULT_VIDEO_AUDIO_TRACK_DISPLAY_NAME;
  }
  else if ( displayName )
  {
    return displayName;
  }
  else
  {
    return DEFAULT_TRACK_DISPLAY_NAME;
  }
}

function getTrackIdForReporting( trackType: string, trackId: string )
{
  switch ( trackType )
  {
    case TRACK_TYPE_NO_MUSIC:
      return NO_MUSIC_TRACK_DISPLAY_NAME;
    case TRACK_TYPE_VIDEO_AUDIO:
      return VIDEO_AUDIO_MUSIC_TRACK;
    default:
      return trackId;
  }
}

function getMusicTrackFromPendingSelection( pendingSelection: MusicSelection )
{
  return {
    ...pendingSelection,
    music_url: pendingSelection.url,
  } as MelodieFeatureEnabledReplaceMusicTrackAPI;
}

function getMusicTrackFromGlobalSettingsMusicData( currentMusicData: GlobalSettingsMusicDataAPI )
{
  const displayName = displayNameForTrack( currentMusicData.type, currentMusicData.metadata?.display_name );
  return {
    ...currentMusicData.metadata,
    id: currentMusicData.id,
    type: currentMusicData.type,
    display_name: displayName,
    url: currentMusicData.audio_url,
  } as CommonTrackAPI;
}

function convertMusicTrackToReplaceMusicAPIPayload( musicTrack: CommonTrackAPI, trackStartTimeInSeconds: number )
{
  const displayName = displayNameForTrack( musicTrack.type, musicTrack.display_name );
  const replaceMusicTrack = {
    ...musicTrack,
    display_name: displayName,
    start_time_in_seconds: trackStartTimeInSeconds,
    music_url: musicTrack.url,
  }
  return replaceMusicTrack;
}

function getNoMusicTrack()
{
  return {
    url: "",
    id: NO_MUSIC_TRACK_ID,
    type: TRACK_TYPE_NO_MUSIC,
    startTimeInSeconds: 0,
  } as CommonTrackAPI;
}

function getVideoAudioMusicTrack()
{
  return {
    url: "",
    id: NO_MUSIC_TRACK_ID,
    type: TRACK_TYPE_VIDEO_AUDIO,
    startTimeInSeconds: 0,
  } as CommonTrackAPI;
}
