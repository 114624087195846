import * as React from "react";
import { customerSupportServices } from "../../services/customerSupport.services";
import { useDispatch, useSelector } from "react-redux";
import { getUserEmail } from "../user/userSlice";
import { RootState } from "../../app/store";
import { Stack, TextField, Typography } from "@mui/material";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { ALKAI_SUPPORT_EMAIL, ButtonColorType, ROUTES, UPSELL_GRADIENT } from "../constants";
import validator from "validator";
import { errorAlert, setAlertMessage, successAlert } from "../alert/alertSlice";
import AlertBanner from "../alert/alertBanner";
import LoadingButton from "@mui/lab/LoadingButton";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { FullPageMobileHeader } from "../layout/fullPageMobileHeader";
import { trim } from "lodash";

export const SUBMIT_SUPPORT_TICKET_DIALOG_STYLE = 'submit_support_ticket'
export const SUBMIT_DELETE_ACCOUNT_REQUEST_DIALOG_STYLE = 'submit_delete_account_request'
export type NewSupportTicketDialogStyle = typeof SUBMIT_SUPPORT_TICKET_DIALOG_STYLE | typeof SUBMIT_DELETE_ACCOUNT_REQUEST_DIALOG_STYLE
export interface NewSupportTicketContentProps extends WithFullScreenDialogProps
{
  style?: NewSupportTicketDialogStyle;
}

function getDialogConfigForStyle( dialogStyle: NewSupportTicketDialogStyle )
{
  if ( dialogStyle === SUBMIT_DELETE_ACCOUNT_REQUEST_DIALOG_STYLE )
  {
    return {
      defaultSubject: "Delete account request",
      defaultDescription: "Please delete my Alkai account",
      finePrintText: "By submitting, you are confirming you want your account to be deleted. This action cannot be undone.",
      submitButtonText: "Delete my account",
      submitButtonColor: "error",
    }
  }
  return {
    submitButtonText: "Submit Ticket",
    submitButtonColor: "primary",
  }
}

export function NewSupportTicketContent( props: NewSupportTicketContentProps )
{
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const dispatch = useDispatch();
  const dialogStyle = props.style || SUBMIT_SUPPORT_TICKET_DIALOG_STYLE;
  const dialogConfig = getDialogConfigForStyle( dialogStyle );
  const initialEmail = useSelector( ( state: RootState ) => getUserEmail( state ) );
  const [email, setEmail] = React.useState( initialEmail || "" );
  const [emailError, setEmailError] = React.useState( "" );
  const [description, setDescription] = React.useState( dialogConfig.defaultDescription || "" );
  const [descriptionError, setDescriptionError] = React.useState( "" );
  const [subject, setSubject] = React.useState( dialogConfig.defaultSubject || "" );
  const [subjectError, setSubjectError] = React.useState( "" );
  const [ticketSubmissionInProgress, setTicketSubmissionInProgress] = React.useState( false );

  async function handleSubmit()
  {
    try
    {
      setTicketSubmissionInProgress( true );
      await customerSupportServices.submitTicket( email, description, subject )
      returnToUserSettings();
      dispatch( setAlertMessage( successAlert( "Your help request has been submitted. Our support team will be in touch with you shortly." ) ) );
    }
    catch (error)
    {
      dispatch( setAlertMessage(
        errorAlert( `We were unable to submit your help request. Please reach out to ${ALKAI_SUPPORT_EMAIL} if this continues.` ) ) );
    }
    finally
    {
      setTicketSubmissionInProgress( false );
    }
  }

  function handleEmailAddressChange( event: React.ChangeEvent<HTMLInputElement> )
  {
    setEmail( trim(event.target.value) );
  }

  function handleSubjectChange( event: React.ChangeEvent<HTMLInputElement> )
  {
    setSubject( event.target.value );
    if ( event.target.value !== "" )
    {
      setSubjectError( "" );
    }
  }

  function handleDescriptionChange( event: React.ChangeEvent<HTMLInputElement> )
  {
    setDescription( event.target.value );
    if ( event.target.value !== "" )
    {
      setDescriptionError( "" );
    }
  }

  async function submitButtonClicked( event: React.MouseEvent<HTMLButtonElement> )
  {
    event.preventDefault();
    if ( email !== "" && subject !== "" && description !== "" )
    {
      if ( validator.isEmail( email ) )
      {
        await handleSubmit()
      }
      else
      {
        setEmailError( "Invalid Email Address" );
      }
    }
    else if ( subject === "" )
    {
      setSubjectError( "Subject is required" );
    }
    else if ( description === "" )
    {
      setDescriptionError( "Description is required" );
    }
    else if ( email === "" )
    {
      setEmailError( "Email is required" );
    }
    else
    {
      dispatch( setAlertMessage( errorAlert( "All fields are required" ) ) );
    }

  }

  function returnToUserSettings()
  {
    navigateWithSearchParams( ROUTES.USER_SETTINGS );
  }

  return (
    <>
      <FullPageMobileHeader>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <IconButton onClick={returnToUserSettings}>
            <ClearIcon/>
          </IconButton>
        </Stack>
      </FullPageMobileHeader>
      <FullPageMobileContainer sx={{ background: UPSELL_GRADIENT }}>
        <Stack spacing={5} sx={{ display: "flex", alignItems: "center", my: 12, mx: "auto", maxWidth: 360, bgcolor: 'background.paper', p: 10 }}>

          <Typography sx={{ fontFamily: "Montserrat", fontWeight: 700, fontSize: 20, mb: 10 }}>Submit a new support ticket</Typography>
          <form>
            <label>
              Email Address:
              <TextField
                className="emailTextField"
                defaultValue={email}
                placeholder="email address required"
                fullWidth={true}
                required={true}
                error={emailError ? true : undefined}
                helperText={emailError}
                onChange={handleEmailAddressChange}/>
            </label>
            <label>
              <Typography>Subject:</Typography>
              <TextField
                className="subjectTextField"
                defaultValue={subject}
                placeholder="What is the issue?"
                fullWidth={true}
                required={true}
                error={subjectError ? true : undefined}
                helperText={subjectError}
                onChange={handleSubjectChange}/>
            </label>
            <label>
              <Typography>Description:</Typography>
              <TextField
                className="descriptionTextField"
                multiline
                defaultValue={description}
                placeholder="Please provide a detailed description of the issue you are experiencing."
                fullWidth={true}
                required={true}
                error={descriptionError ? true : undefined}
                helperText={descriptionError}
                minRows={4}
                onChange={handleDescriptionChange}/>
            </label>
            {!!dialogConfig.finePrintText && <Typography variant="caption">
              {dialogConfig.finePrintText}
            </Typography>}
            <LoadingButton variant="contained"
                           color={dialogConfig.submitButtonColor as ButtonColorType}
                           onClick={!ticketSubmissionInProgress ? submitButtonClicked : undefined}
                           loading={ticketSubmissionInProgress}
                           sx={{ mt: 10 }}>
              {dialogConfig.submitButtonText}
            </LoadingButton>
          </form>
          <AlertBanner/>
        </Stack>
      </FullPageMobileContainer>
    </>

  );
}

export const NewSupportTicketDialog = withFullScreenDialog( NewSupportTicketContent )
