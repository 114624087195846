import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import React from "react";
import { getCurrentGlobalErrorDialogType, hideGlobalErrorDialog, } from "../ui/uiSlice";
import PostIdeaNotificationNotFoundDialog from "./postIdeaNotificationNotFoundDialog";
import {
  FACEBOOK_ERROR_NO_LINKED_PAGE_DIALOG,
  FACEBOOK_INSTAGRAM_ERROR_NO_LINKED_PAGE_DIALOG,
  POPUP_BLOCKER_DETECTED,
  GlobalErrorDialogType,
  POST_IDEA_NOTIFICATION_NOT_FOUND_DIALOG,
  YOUTUBE_OAUTH_ERROR_DIALOG_NO_LINKED_CHANNEL
} from "../constants";
import YoutubeOauthErrorNoLinkedChannelDialog from "./youtubeOauthErrorNoLinkedChannelDialog";
import PopupBlockerDetectedDialog from "./popupBlockerDetectedDialog";
import FacebookNoLinkedPageDialog from "./facebookNoLinkedPageDialog";
import FacebookInstagramNoLinkedPageDialog from "./facebookInstagramNoLinkedPageDialog";

export interface GlobalErrorDialogCallbackProps
{
  open: boolean;
  onConfirm: () => void;
}

export default function GlobalErrorDialogFactory()
{
  const dispatch = useDispatch();
  const dialogType = useSelector( ( state: RootState ) => getCurrentGlobalErrorDialogType( state ) );
  const shouldShow = !!dialogType;

  function handleClose()
  {
    dispatch( hideGlobalErrorDialog() );
  }

  function getDialog( dialogType: GlobalErrorDialogType )
  {
    if ( dialogType === POPUP_BLOCKER_DETECTED )
    {
      return <PopupBlockerDetectedDialog open={shouldShow} onConfirm={handleClose}/>
    }
    else if ( dialogType === POST_IDEA_NOTIFICATION_NOT_FOUND_DIALOG )
    {
      return <PostIdeaNotificationNotFoundDialog open={shouldShow} onConfirm={handleClose}/>
    }
    else if ( dialogType === YOUTUBE_OAUTH_ERROR_DIALOG_NO_LINKED_CHANNEL )
    {
      return <YoutubeOauthErrorNoLinkedChannelDialog open={shouldShow} onConfirm={handleClose}/>
    }
    if ( dialogType === FACEBOOK_ERROR_NO_LINKED_PAGE_DIALOG )
    {
      return <FacebookNoLinkedPageDialog open={shouldShow} onConfirm={handleClose}/>
    }
    if ( dialogType === FACEBOOK_INSTAGRAM_ERROR_NO_LINKED_PAGE_DIALOG )
    {
      return <FacebookInstagramNoLinkedPageDialog open={shouldShow} onConfirm={handleClose}/>
    }
  }

  return (getDialog( dialogType ) || null)
}