import { MelodieTrackAPI, MusicSelection } from "./musicSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentMusicTrack, getCurrentMusicTrackPlaying, MusicTrack, setAudioPlayerPlaying, setAudioPlayerTrack } from "../ui/uiSlice";
import { RootState } from "../../app/store";
import { useEffect, useState } from "react";
import "./beatSyncIcon.scss"
import { MusicItemView } from "./MusicItemView";
import { logToConsoleError } from "../utils/devLoggingHelper";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";

interface MusicItemProps
{
  track: MusicSelection;
  handleTrackSelected: ( track: MusicSelection ) => void;
  selected: boolean;
}

export function MusicItem( props: MusicItemProps )
{
  const dispatch = useDispatch();
  const selected = props.selected;
  const [trackUrl, setTrackUrl] = useState<string>();
  const currentAudioPlayerMusicTrack = useSelector( ( state: RootState ) => getCurrentMusicTrack( state ) );
  const currentAudioPlayerPlaying = useSelector( ( state: RootState ) => getCurrentMusicTrackPlaying( state ) );
  const doesTrackDownloadDataMatchAudioPlayer = currentAudioPlayerMusicTrack?.url === trackUrl;
  const hasTrackDownloadData = !!trackUrl;
  const [loading, setLoading] = useState( false );
  const showStopIcon = !!(selected && doesTrackDownloadDataMatchAudioPlayer && currentAudioPlayerPlaying);

  useEffect( () =>
  {
    if ( selected )
    {
      if ( hasTrackDownloadData )
      {
        setTrackUrl( getUrlToPlayInClient() );
      }
    }
  }, [selected] );

  function handleOnClick()
  {
    if ( !loading )
    {
      togglePlayingMusic();
    }
  }

  function getUrlToPlayInClient()
  {
    const streamUrl = (props.track as MelodieTrackAPI)?.stream_url;
    return streamUrl ? streamUrl : props.track.url;
  }

  const togglePlayingMusic = () =>
  {
    if ( currentAudioPlayerMusicTrack && doesTrackDownloadDataMatchAudioPlayer )
    {
      dispatch( setAudioPlayerPlaying( !currentAudioPlayerPlaying ) );
    }
    else
    {
      setLoading( true );
      if ( !!props.track.url )
      {
        props.handleTrackSelected( props.track );
        const urlToPlayInClient = getUrlToPlayInClient();
        setTrackUrl( urlToPlayInClient );
        playTrack( urlToPlayInClient, 0 );
        setLoading( false );
      }
      else
      {
        setLoading( false );
        logToConsoleError( "track has no url", props.track );
        dispatch( setAlertMessage( errorAlert( "Could not play music. Please try again." ) ) );
      }
    }
  };

  const playTrack = ( url: string, startTimeInSeconds: number ) =>
  {
    const musicTrack: MusicTrack = { url: url, startTimeInSeconds: startTimeInSeconds }
    dispatch( setAudioPlayerTrack( musicTrack ) );
    dispatch( setAudioPlayerPlaying( true ) );
  };

  return (
    <MusicItemView track={props.track}
                   handleClicked={handleOnClick}
                   selected={selected}
                   loading={loading}
                   showStopIcon={showStopIcon}
                   showBottomText={true}
                   showFavoriteIcon={true}
    />
  );
}
