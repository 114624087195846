import { Box, Paper } from "@mui/material";
import * as React from "react";
import { browserUtils } from "../utils/browserUtils";

export interface FullPageMobileFooterProps
{
  children: React.ReactNode;
}

export function FullPageMobileFooter( props: FullPageMobileFooterProps )
{
  const bottomPadding = browserUtils.bottomSafeAreaPadding();

  return <Box>
    <Paper elevation={3} sx={{ pb: bottomPadding }}>
      {props.children}
    </Paper>
  </Box>
}
