import React from "react";
import { PREVIEW_NAV_ID_NEXT_VARIANT, PREVIEW_NAV_ID_PREVIOUS_VARIANT } from "../constants";
import { Box } from "@mui/material";
import { SkewedGradientRectangle } from "./skewedGradientRectangle";
import { PostVariationArrow } from "./postVariationArrow";
import { browserUtils } from "../utils/browserUtils";

interface PostVariationPanelProps
{
  variantDirection: typeof PREVIEW_NAV_ID_PREVIOUS_VARIANT | typeof PREVIEW_NAV_ID_NEXT_VARIANT,
  showLoader: boolean,
  handleArrowClick: ( event: React.MouseEvent ) => void,
}

export function PostVariationPanel( props: PostVariationPanelProps )
{
  const sxProps: any = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  };
  if ( !browserUtils.isMobileBrowser() )
  {
    sxProps.width = "32px";
  }

  function handleArrowClick( event: React.MouseEvent )
  {
    if ( !props.showLoader )
    {
      props.handleArrowClick( event );
    }
  }

  return <Box onClick={handleArrowClick}
              sx={
                sxProps
              }>
    <SkewedGradientRectangle variantDirection={props.variantDirection}/>
    <PostVariationArrow variantDirection={props.variantDirection} showLoader={props.showLoader}/>
  </Box>;
}
