import * as React from "react";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import "./confirmFacebookAccount.scss";
import { businessServices, SocialNetworkAccountAPI } from "../../services/business.services";
import { map } from "lodash";
import { ConnectSocialNetworkAccountDrawerProps } from "../userSettings/SwitchSocialNetworkAccountButton";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import { setAlertMessage, warningAlert } from "../alert/alertSlice";
import { useDispatch } from "react-redux";
import { browserUtils } from "../utils/browserUtils";

export function ConfirmFacebookPageAccount( props: ConnectSocialNetworkAccountDrawerProps )
{
  const dispatch = useDispatch();
  const socialNetworkAccountsContext = React.useContext( SocialNetworkAccountsContext );
  const allFacebookPageAccounts = socialNetworkAccountHelper.getAllFacebookPageAccounts( socialNetworkAccountsContext.socialNetworkAccounts );
  const previouslyConnectedFacebookAccount = socialNetworkAccountHelper.getConnectedFacebookPage(
    socialNetworkAccountsContext.socialNetworkAccounts );
  const bottomPadding = browserUtils.bottomSafeAreaPadding();

  function handleCancel()
  {
    props.handleCancel()
  }

  async function handleAccountSelected( facebookPage: SocialNetworkAccountAPI )
  {
    if ( !!previouslyConnectedFacebookAccount )
    {
      const result = await businessServices.getHasScheduledPostsForAccount( previouslyConnectedFacebookAccount.id );
      if ( result && result.has_scheduled_posts )
      {
        dispatch( setAlertMessage( warningAlert(
          "You have scheduled posts for your current Facebook Page. They will still be published to that account unless you cancel or reschedule them with the new account." ) ) );
      }
    }

    await businessServices.switchFacebookPage( facebookPage.id )
    props.handleSelectionComplete();
  }

  return (
    <Box sx={{ pb: bottomPadding }}>
      <Typography sx={{ fontWeight: "bold", my: 5 }}>{props.isInitialConnect ? "Choose Facebook Page" : "Switch Facebook Pages"}</Typography>
      <Typography sx={{ my: 5 }}>Which account would you like to connect?</Typography>
      <Stack alignItems={"center"}>
        {allFacebookPageAccounts && map( allFacebookPageAccounts, ( facebookPage: SocialNetworkAccountAPI ) =>
          {
            return <Button variant={"outlined"}
                           key={facebookPage.id}
                           sx={{ my: 5, overflow: "hidden", button: { textTransform: "none" } }}
                           startIcon={<Avatar sx={{ height: 22, width: 22, border: "1px solid #3f50b5" }}
                                              src={facebookPage.profile_image_url}/>}
                           onClick={() => handleAccountSelected( facebookPage )}>
              {facebookPage.name}
            </Button>
          }
        )}
        <Button variant={"outlined"} color="error" sx={{ my: 5, overflow: "hidden" }} onClick={handleCancel}>Cancel</Button>
      </Stack>
    </Box>
  )
}
