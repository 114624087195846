import { Box, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { AlkaiWordmarkLogoGradient } from "./alkaiWordmarkLogoGradient";
import CalendarIcon from "../../assets/real-sparkle-calendar.png";
import { CREATE_ACCOUNT_SHOWN_VIA_POST_PREVIEW } from "../constants";
import CarouselImage1 from "../../assets/signInCarousels/sign-in-carousel-1.png";
import CarouselImage2 from "../../assets/signInCarousels/sign-in-carousel-2.png";
import CarouselImage3 from "../../assets/signInCarousels/sign-in-carousel-3.png";
import { ShowCarouselSignInContext } from "../context/showCarouselSignInContext";
import EmblaCarousel from "./emblaCarousel/emblaCarousel";

export interface AlkaiSignInHeaderProps
{
  shownVia: string;
}

export function AlkaiSignInHeader( props: AlkaiSignInHeaderProps )
{
  const showCarouselSignIn = useContext( ShowCarouselSignInContext );

  const headerContent = () =>
  {
    if ( props.shownVia === CREATE_ACCOUNT_SHOWN_VIA_POST_PREVIEW )
    {
      return postPreviewLogin();
    }
    else if ( showCarouselSignIn )
    {
      return carouselLogin();
    }
    else
    {
      return standardLogin();
    }
  }

  const postPreviewLogin = () =>
  {
    return <>
      <Stack direction="column" textAlign="center" sx={{ maxWidth: "90%" }}>
        <Typography sx={{ fontFamily: "Montserrat", fontWeight: 900, fontSize: { xs: "24px", sm: "28px" }, lineHeight: "32px", mb: 4 }}>Get More
                                                                                                                                        Posts</Typography>
        <Box component="img" src={CalendarIcon} alt="let's connect" sx={{ width: "200px", margin: "auto" }}/>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            color: "#3D3690",
            fontSize: { xs: "20px", sm: "24px" },
            lineHeight: "32px",
            mb: 4,
            wordBreak: "break-word"
          }}>
          Unlock even more<br/>with a free account
        </Typography>
        <Typography sx={{ fontSize: { xs: "11px", sm: "14px" } }}>
          Alkai will create new posts for you, on your schedule, letting you know when they're ready for review.
        </Typography>
      </Stack>
    </>
  }

  const standardLogin = () =>
  {
    return <>
      <AlkaiWordmarkLogoGradient/>
      <Stack direction="column" textAlign="center" spacing={4}>
        <Typography variant="h4">Your Own AI Social Media Assistant</Typography>
        <Typography variant="subtitle1">
          Alkai creates custom posts for you <br/>
          helping you regularly share engaging content and grow your business.
        </Typography>
      </Stack>
    </>
  }

  const carouselLogin = () =>
  {
    return <EmblaCarousel options={{ loop: true }} autoPlayOptions={{ stopOnLastSnap: false }}>
      <div className="embla__slide" style={{ flex: "0 0 100%", minWidth: 0 }}>
        <Box component="img" src={CarouselImage1} sx={{ width: "100%" }}/>
      </div>
      <div className="embla__slide" style={{ flex: "0 0 100%", minWidth: 0 }}>
        <Box component="img" src={CarouselImage2} sx={{ width: "100%" }}/>
      </div>
      <div className="embla__slide" style={{ flex: "0 0 100%", minWidth: 0 }}>
        <Box component="img" src={CarouselImage3} sx={{ width: "100%" }}/>
      </div>
    </EmblaCarousel>
  }

  function getHeaderStyle()
  {
    if ( !showCarouselSignIn )
    {
      return { mb: 20 };
    }
    return {};
  }

  return (
    <Stack direction="column" alignItems="center" spacing={12} sx={getHeaderStyle()}>
      {headerContent()}
    </Stack>
  );
}
