import * as React from "react";
import { ReactNode } from "react";
import { Typography } from "@mui/material";

export interface ConfirmDialogBodyTypographyProps
{
  children: ReactNode;
}

export function ConfirmDialogBodyTypography( props: ConfirmDialogBodyTypographyProps )
{
  return (
    <Typography sx={{ fontSize: "16px", lineHeight: "20px", mb: 8 }}
                variant={"subtitle1"}>
      {props.children}
    </Typography>
  );
}