import React from "react";
import { GlobalErrorDialogCallbackProps } from "./globalErrorDialogFactory";
import { ConfirmDialogBodyTypography } from "../ui/confirmDialogBodyTypography";
import { GlobalErrorConfirmDialog } from "../ui/globalErrorConfirmDialog";
import { ConfirmDialogContent } from "../ui/confirmDialogContent";
import { ALKAI_FAQ_POP_UP_BLOCKERS_URL } from "../constants";

export default function PopupBlockerDetectedDialog( props: GlobalErrorDialogCallbackProps )
{

  function getDialogContent()
  {
    return <ConfirmDialogContent>
      <ConfirmDialogBodyTypography>Looks like your web browser has pop-up blockers enabled.</ConfirmDialogBodyTypography>
      <ConfirmDialogBodyTypography>Disable your browser’s pop-up blocker and then, try connecting again.</ConfirmDialogBodyTypography>
    </ConfirmDialogContent>
  }

  return <GlobalErrorConfirmDialog {...props}
                                   title={"Pop-up Blocker Detected"}
                                   dialogContent={getDialogContent()}
                                   customNeedHelpUrl={ALKAI_FAQ_POP_UP_BLOCKERS_URL}/>
}