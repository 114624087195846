import { iOSMessageSender } from "../features/payment/iOSMessageSender";
import { browserUtils } from "../features/utils/browserUtils";
import { isiOSTrackingAuthorized, setIosTrackingPermissionsStatus } from "../features/tracking/trackingSlice";
import { store } from "../app/store";
import { isUserLoggedIn } from "../features/user/userSlice";
import { authenticationServices } from "../services/authentication.services";
import ReactPixel from "react-facebook-pixel";
import { gtagWrapper } from "./gtagWrapper";

export const iosPermissionsRequester = {
  updateForTrackingPermissions,
}

function updateForTrackingPermissions()
{
  if ( browserUtils.isAlkaiIOSApp() )
  {
    iOSMessageSender.requestTrackingPermissions().then( ( trackingPermissionsInfo ) =>
    {
      store.dispatch( setIosTrackingPermissionsStatus( trackingPermissionsInfo.trackingAuthorizationStatus ) );
      if ( isUserLoggedIn( store.getState() ) )
      {
        authenticationServices.updateIosTrackingPermissions();
      }
      const isAuthorized = isiOSTrackingAuthorized( store.getState() );
      if ( isAuthorized )
      {
        ReactPixel.grantConsent();
        gtagWrapper.gtagGrantConsent();
      }
      else
      {
        ReactPixel.revokeConsent();
        gtagWrapper.gtagRevokeConsent();
      }
    } );
  }
}
