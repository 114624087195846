import React, { useContext } from "react";
import { Link } from "@mui/material";
import { ShowCarouselSignInContext } from "../context/showCarouselSignInContext";
import { merge } from "lodash";

export interface CreateAccountSwitchFormLinkProps
{
  onClick: () => void;
  children: React.ReactNode;
}

export function CreateAccountSwitchFormLink( props: CreateAccountSwitchFormLinkProps )
{
  const showCarouselSignIn = useContext( ShowCarouselSignInContext );

  function getStyle()
  {
    if ( showCarouselSignIn )
    {
      return {
        fontSize: "11px",
      }
    }
    return {};
  }

  return <Link href="#" variant="body2" sx={merge( { mt: 3, mb: 5 }, getStyle() )} onClick={props.onClick}>
    {props.children}
  </Link>;
}
