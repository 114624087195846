import { getGoogleTagId, IS_DEVELOPMENT } from "../constants";
import { errorReporter } from "../features/error/errorReporter";

export const gtagWrapper = {
  initialize,
  configUserId,
  setUserData,
  getClientId,
  gtagRevokeConsent,
  gtagGrantConsent
}

function initialize( debugModeOverride?: boolean )
{
  const debugMode = debugModeOverride || IS_DEVELOPMENT;

  gtagConfig( getGoogleTagId(), { 'debug_mode': debugMode } )
}

function configUserId( userId: string )
{
  gtagConfig( getGoogleTagId(), { 'user_id': userId } )
}

function setUserData( userData: any )
{
  gtagSet( 'user_data', userData );
}

async function getClientId()
{
  try
  {
    const gclidPromise = new Promise( resolve =>
    {
      gtagGet( 'client_id', resolve );
    } );
    const googleClientId = await gclidPromise;
    return googleClientId;
  }
  catch (error)
  {
    errorReporter.reportErrorToSentry( error );
    return null;
  }
}

function gtagConfig( gtagId: string, params?: any )
{
  if ( !!params )
  {
    //@ts-ignore
    window.gtag( 'config', gtagId, params );
  }
  else
  {
    //@ts-ignore
    window.gtag( 'config', gtagId );
  }
}

function gtagSet( key, value )
{
  //@ts-ignore
  window.gtag( 'set', key, value );
}

function gtagGet( key: string, callback: any )
{
  //@ts-ignore
  window.gtag( 'get', getGoogleTagId(), key, callback );
}

function gtagRevokeConsent()
{
  //@ts-ignore
  window.gtag( 'consent', 'update', {
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'analytics_storage': 'denied'
  } );
}

function gtagGrantConsent()
{
  //@ts-ignore
  window.gtag( 'consent', 'update', {
    'ad_storage': 'granted',
    'ad_user_data': 'granted',
    'ad_personalization': 'granted',
    'analytics_storage': 'granted'
  } );
}