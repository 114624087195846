import { Box, Stack, SxProps, Typography } from "@mui/material";
import PAYWALL_BADGE_STAR from "../../assets/paywallBadgeStar.svg"
import { merge } from "lodash";

export interface PaywallBadgeProps
{
  badgeText: string;
  positioningProps?: SxProps;
}

export function PaywallBadge( props: PaywallBadgeProps )
{
  const positioningProps = props.positioningProps ?? { position: "absolute", bottom: "-36px", right: "-12px" };
  let defaultSxProps = {
    background: `url(${PAYWALL_BADGE_STAR})`,
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    width: "88px",
    height: "88px",
  };

  let sx = merge( defaultSxProps, positioningProps );
  return <Box sx={sx}>
    <Stack sx={{ p: 8, justifyContent: "center", alignItems: "center", height: "100%", textAlign: "center" }}>
      <Typography sx={{ fontWeight: 600, lineHeight: "12px", fontSize: "12px" }}>{props.badgeText}</Typography>
    </Stack>
  </Box>;
}