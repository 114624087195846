import { postJsonToApi } from "./requestManager";

const CREATE_IOS_SUBSCRIBER_PATH = 'ios_subscribers/create';

export const iosSubscriberServices = {
  createSubscriber,
}

export interface CreateSubscriberAPI
{
  success: boolean;
}

async function createSubscriber( entitlementLookupKey: string )
{

  const jsonBody = {
    entitlement_lookup_key: entitlementLookupKey,
  }
  return postJsonToApi<CreateSubscriberAPI>( CREATE_IOS_SUBSCRIBER_PATH, {}, jsonBody );
}