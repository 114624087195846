import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../app/store";
import { POST_IDEA_NOTIFICATION_NOT_FOUND_DIALOG, ROUTES, SEARCH_PARAM_KEY_POST_IDEA } from "../constants";
import "./assistantChat.scss"
import { isUserLoggedIn } from "../user/userSlice";
import { useSearchParams } from "react-router-dom";
import useRemoveSearchParamByKey from "../hooks/useRemoveSearchParamByKey";
import { FullScreenEditor } from "../editing/editAndSharePostFlow";
import { PostIdeaContext } from "../context/postIdeaContext";
import { postIdeaServices } from "../../services/postIdeaServices";
import { errorReporter } from "../error/errorReporter";
import { addPost } from "../postIdea/postsSlice";
import { clearAlertMessage, errorAlert, setAlertMessage } from "../alert/alertSlice";
import { eventTracker } from "../../helpers/eventTracker";
import { addPostIdea, getPostIdea } from "../postIdea/postIdeaSlice";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { eligibleToDefaultToPlan } from "../business/businessSlice";
import { amplitudeFeatureFlagsVariables } from "../amplitude/amplitudeFeatureFlagsVariables";
import { showGlobalErrorDialog } from "../ui/uiSlice";

export function PostIdeaNotification()
{
  const dispatch = useDispatch();

  const [searchParams,] = useSearchParams();
  const removeSearchParamByKey = useRemoveSearchParamByKey();

  const [notificationPostIdeaId, setNotificationPostIdeaId] = useState<string | null>( null );
  const postIdea = useSelector( ( state: RootState ) => getPostIdea( state, notificationPostIdeaId ) );
  const userIsLoggedIn = useSelector( ( state: RootState ) => isUserLoggedIn( state ) );
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const [defaultToPlanFromAmplitudeFeatureFlag, setDefaultToPlanFromAmplitudeFeatureFlag] = useState( false );
  const eligibleToReturnToPlan = useSelector( ( state: RootState ) => eligibleToDefaultToPlan( state ) );
  const shouldReturnToPlanFromPreview = eligibleToReturnToPlan && defaultToPlanFromAmplitudeFeatureFlag;
  const shouldShowFullScreenEditor = !!notificationPostIdeaId && !!postIdea;

  useEffect( () =>
  {
    amplitudeFeatureFlagsVariables.shouldDefaultToPlanTab().then( ( value ) =>
    {
      setDefaultToPlanFromAmplitudeFeatureFlag( value );
    } );

    const notificationPostIdeaSlug = searchParams.get( SEARCH_PARAM_KEY_POST_IDEA );
    if ( userIsLoggedIn && !!notificationPostIdeaSlug )
    {
      removeSearchParamByKey( SEARCH_PARAM_KEY_POST_IDEA );
      postIdeaServices.getPostIdeaData( notificationPostIdeaSlug ).then( ( data ) =>
      {
        dispatch( addPostIdea( data ) )
        eventTracker.logPostIdeaNotificationShown( data );
        setNotificationPostIdeaId( data.id );
        fetchPostJson( notificationPostIdeaSlug );
      } ).catch( ( error ) =>
      {
        eventTracker.logPostIdeaNotificationError( error?.message );

        if ( error?.message === 'Not found' )
        {
          dispatch( clearAlertMessage() );
          dispatch( showGlobalErrorDialog( POST_IDEA_NOTIFICATION_NOT_FOUND_DIALOG ) );
        }
        else
        {
          dispatch( setAlertMessage( errorAlert( "An error occurred while loading the post. Please try again." ) ) );
        }
        errorReporter.reportErrorToSentry( error );
      } ).finally( () =>
      {
        removeSearchParamByKey( SEARCH_PARAM_KEY_POST_IDEA );
      } );
    }
  }, [] );

  function fetchPostJson( notificationPostIdeaSlug: string )
  {
    postIdeaServices.getPostJson( notificationPostIdeaSlug ).then( ( postJson ) =>
    {
      store.dispatch( addPost( postJson ) )
    } ).catch( ( error ) =>
    {
      errorReporter.reportErrorToSentry( error );
    } );
  }

  function handleCloseEditorFromNotificationPost()
  {
    setNotificationPostIdeaId( null );
    if ( shouldReturnToPlanFromPreview )
    {
      navigateWithSearchParams( ROUTES.PLAN )
    }
  }

  return <>
    {shouldShowFullScreenEditor && <PostIdeaContext.Provider value={{ postIdea: postIdea }}>
      <FullScreenEditor editable={true}
                        externalStateOpen={shouldShowFullScreenEditor}
                        handleClose={handleCloseEditorFromNotificationPost}/>
    </PostIdeaContext.Provider>}
  </>
}

