import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { useEffect, useRef } from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
import { UpsellBenefitRow } from "./upsellBenefitRow";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentDialogOpen, setPaymentDialogSelectedSubscriptionDuration, setUpsellShown } from "../ui/uiSlice";
import { PurchaseFlowCTA } from "../payment/purchaseFlowCTA";
import { PurchaseFlowHeader } from "../payment/purchaseFlowHeader";
import {
  ALKAI_SUBSCRIPTION_TERMS_FAQ_URL,
  CREATE_ACCOUNT_SHOWN_VIA_CHECKOUT,
  SUBSCRIPTION_DURATION_MONTHLY,
  SUBSCRIPTION_DURATION_YEARLY,
  SubscriptionDuration,
  UPSELL_GRADIENT
} from "../constants";
import { isGuestUser, isMonthlyStripeSubscriber } from "../user/userSlice";
import { RootState } from "../../app/store";
import { CreateAccountOrSignIn } from "../registration/createAccountOrSignIn";
import { eventTracker } from "../../helpers/eventTracker";
import UpsellSeal from "../../assets/upsell-seal.svg";
import { AlkaiWordmarkLogoWhite } from "../registration/alkaiWordmarkLogoWhite";
import { amplitudeFeatureFlagsVariables } from "../amplitude/amplitudeFeatureFlagsVariables";
import CircularProgress from "@mui/material/CircularProgress";
import { browserUtils } from "../utils/browserUtils";
import { iOSMessageSender } from "../payment/iOSMessageSender";
import { SubscriptionProductButton } from "../payment/subscriptionProductButton";
import { YearlyPurchaseFlowCTA } from "../payment/yearlyPurchaseFlowCTA";
import { YearlyPurchaseFlowHeader } from "../payment/yearlyPurchaseFlowHeader";
import { SubscriptionProductOutlineItem } from "../payment/subscriptionProductOutlineItem";
import { PORTAL_TYPE_UPGRADE_TO_YEARLY, stripeServices } from "../../services/stripe.services";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";

function UpsellContainer( props: WithFullScreenDialogProps )
{
  const [loading, setLoading] = React.useState<boolean>( true );
  const [shouldShowPaywallWithYearlyOption, setShouldShowPaywallWithYearlyOption] = React.useState<boolean>( false );
  const shouldShowUpgradeToYearlyPaywall = useSelector( ( state: RootState ) => isMonthlyStripeSubscriber( state ) );

  useEffect( () =>
  {
    if ( props.externalStateOpen )
    {

      if ( !browserUtils.isAlkaiIOSApp() )
      {
        amplitudeFeatureFlagsVariables.shouldShowPaywallWithYearlyOption().then( ( value ) =>
        {
          if ( value )
          {
            setShouldShowPaywallWithYearlyOption( true );
          }
          else
          {
            setShouldShowPaywallWithYearlyOption( false );
          }
        } ).catch( ( error ) =>
        {
          setShouldShowPaywallWithYearlyOption( false );
        } ).finally( () =>
        {
          setLoading( false );
        } );
      }
      else
      {
        setLoading( false );
      }
    }
  }, [props.externalStateOpen] );

  if ( loading )
  {
    return <Stack sx={{ width: "100vw", height: "100vh", alignItems: "center", justifyContent: "center" }}><CircularProgress/></Stack>
  }
  else if ( shouldShowUpgradeToYearlyPaywall )
  {
    return <UpgradeToYearlyUpsell {...props}/>
  }
  else if ( shouldShowPaywallWithYearlyOption )
  {
    return <UpsellWithYearlyOption {...props}/>
  }
  else
  {
    return <Upsell {...props}/>
  }
}

function Upsell( props: WithFullScreenDialogProps )
{
  const dispatch = useDispatch();
  const userIsGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const userIsGuestRef = useRef( userIsGuest );
  const [showCreateLogin, setShowCreateLogin] = React.useState<boolean>( false );
  const isInAlkaiIOSApp = browserUtils.isAlkaiIOSApp();

  useEffect( () =>
  {
    userIsGuestRef.current = userIsGuest;
  }, [userIsGuest] );

  const handleCloseUpsell = () =>
  {
    eventTracker.logUpsellCancelClicked( userIsGuest );
    if ( props.handleClose )
    {
      props.handleClose();
    }
  }

  function openSubscriptionFlow()
  {
    if ( isInAlkaiIOSApp )
    {
      iOSMessageSender.startSubscription();
    }
    else
    {
      dispatch( setPaymentDialogOpen( true ) );
    }
  }

  function handleStripeCheckout()
  {
    eventTracker.logUpsellContinueToPaymentClicked( userIsGuest );
    if ( userIsGuest )
    {
      setShowCreateLogin( true )
    }
    else
    {
      dispatch( setUpsellShown( false ) );
      openSubscriptionFlow();
    }
  }

  function handleAccountCreated()
  {
    setShowCreateLogin( false );
    if ( !userIsGuestRef.current )
    {
      dispatch( setUpsellShown( false ) );
      openSubscriptionFlow();
    }
  }

  function getPurchaseFlowCTA()
  {
    return "Save 50% for 3 months!"
  }

  function getUpsellSeal()
  {
    let monthlyPriceAfterDiscount = "$20/mo";
    let ongoingMonthlyPrice = "$40";

    return (<Box sx={{ position: "absolute", width: "90px", height: "90px", top: "-65px", right: "-55px" }}>
      <Box component="img" src={UpsellSeal} alt="save now" sx={{ width: "100%", position: "absolute", top: 0, left: 0, right: 0 }}/>
      <Stack sx={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0, right: 0 }} alignItems="center"
             justifyContent="center">
        <Typography variant="subtitle2"
                    sx={{ textDecoration: "line-through", color: "white", fontSize: "16px", lineHeight: "18px" }}>{ongoingMonthlyPrice}</Typography>
        <Typography variant="subtitle2"
                    sx={{ color: "white", fontSize: "16px", lineHeight: "18px" }}>{monthlyPriceAfterDiscount}</Typography>
      </Stack>
    </Box>);
  }

  return (
    <FullPageMobileContainer sx={{ background: UPSELL_GRADIENT }}>
      <IconButton
        aria-label="close"
        color="secondary"
        size="small"
        sx={{
          position: "absolute",
          left: 8,
          top: 8,
          zIndex: 1,
        }}
        onClick={handleCloseUpsell}>
        <CloseIcon fontSize="inherit"/>
      </IconButton>
      <Stack sx={{ display: "flex", alignItems: "center", my: 12, mx: "auto" }}>
        <AlkaiWordmarkLogoWhite sx={{ width: "110px" }}/>
        <PurchaseFlowHeader sx={{ width: "280px" }}>Your AI Social Media Assistant</PurchaseFlowHeader>
        <UpsellBenefitRow>
          <Box component="span" fontWeight='bold'>Compelling post ideas</Box> tailored for your business
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          Keeps you on track with a <Box component="span" fontWeight='bold'>custom weekly plan</Box>
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          Automatically creates <Box component="span" fontWeight='bold'>eye-catching content</Box> when it's time to post
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          <Box component="span" fontWeight='bold'>Unlimited</Box> ideas, content, and cloud storage
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          <Box component="span" fontWeight='bold'>Free stock</Box> photos, videos, and music
        </UpsellBenefitRow>
        <PurchaseFlowCTA onClick={handleStripeCheckout}>
          {getPurchaseFlowCTA()}
          {getUpsellSeal()}
        </PurchaseFlowCTA>
        <Typography variant="caption" sx={{ color: "primary.contrastText" }}>Discount available to first-time customers only.</Typography>
      </Stack>
      {showCreateLogin && <CreateAccountOrSignIn shownVia={CREATE_ACCOUNT_SHOWN_VIA_CHECKOUT}
                                                 shouldShowSignIn={showCreateLogin}
                                                 onClose={handleAccountCreated}/>}
    </FullPageMobileContainer>
  )
}

function UpsellWithYearlyOption( props: WithFullScreenDialogProps )
{
  const dispatch = useDispatch();
  const userIsGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const userIsGuestRef = useRef( userIsGuest );
  const [showCreateLogin, setShowCreateLogin] = React.useState<boolean>( false );
  const isInAlkaiIOSApp = browserUtils.isAlkaiIOSApp();
  const [selectedSubscriptionDuration, setSelectedSubscriptionDuration] = React.useState<SubscriptionDuration>( SUBSCRIPTION_DURATION_YEARLY );
  const isYearly = selectedSubscriptionDuration === SUBSCRIPTION_DURATION_YEARLY;

  useEffect( () =>
  {
    userIsGuestRef.current = userIsGuest;
  }, [userIsGuest] );

  const handleCloseUpsell = () =>
  {
    eventTracker.logUpsellCancelClicked( userIsGuest );
    if ( props.handleClose )
    {
      props.handleClose();
    }
  }

  function openSubscriptionFlow()
  {
    if ( isInAlkaiIOSApp )
    {
      iOSMessageSender.startSubscription();
    }
    else
    {
      dispatch( setPaymentDialogOpen( true ) );
    }
  }

  function handleStripeCheckout()
  {
    dispatch( setPaymentDialogSelectedSubscriptionDuration( selectedSubscriptionDuration ) );
    eventTracker.logUpsellContinueToPaymentClicked( userIsGuest );
    if ( userIsGuest )
    {
      setShowCreateLogin( true )
    }
    else
    {
      dispatch( setUpsellShown( false ) );
      openSubscriptionFlow();
    }
  }


  function handleYearlyProductSelected()
  {
    if (selectedSubscriptionDuration !== SUBSCRIPTION_DURATION_YEARLY)
    {
      eventTracker.logUpsellSubscriptionDurationSwitched(selectedSubscriptionDuration, SUBSCRIPTION_DURATION_YEARLY);
    }
    setSelectedSubscriptionDuration( SUBSCRIPTION_DURATION_YEARLY );
  }

  function handleMonthlyProductSelected()
  {
    if (selectedSubscriptionDuration !== SUBSCRIPTION_DURATION_MONTHLY)
    {
      eventTracker.logUpsellSubscriptionDurationSwitched(selectedSubscriptionDuration, SUBSCRIPTION_DURATION_MONTHLY);
    }
    setSelectedSubscriptionDuration( SUBSCRIPTION_DURATION_MONTHLY );
  }

  function handleAccountCreated()
  {
    setShowCreateLogin( false );
    if ( !userIsGuestRef.current )
    {
      dispatch( setUpsellShown( false ) );
      openSubscriptionFlow();
    }
  }

  function getPurchaseFlowCTA()
  {
    return isYearly ? "Save 50% for 12 months!" : "Save 50% for 3 months!";
  }

  function getFinePrint()
  {
    return isYearly ? "No commitment. Cancel anytime. Bills yearly." : "No commitment. Cancel anytime. Bills monthly.";
  }

  function getDiscountAppliesVisibility()
  {
    return isYearly ? "hidden" : "visible";
  }

  return (
    <FullPageMobileContainer sx={{ background: UPSELL_GRADIENT }}>
      <IconButton
        aria-label="close"
        color="secondary"
        size="small"
        sx={{
          position: "absolute",
          left: 8,
          top: 8,
          zIndex: 1,
        }}
        onClick={handleCloseUpsell}>
        <CloseIcon fontSize="inherit"/>
      </IconButton>
      <Stack sx={{ display: "flex", alignItems: "center", py: 10, my: "auto", mx: "auto" }}>
        <AlkaiWordmarkLogoWhite sx={{ width: "118px" }}/>
        <YearlyPurchaseFlowHeader sx={{ width: "280px" }}>Your AI Social Media Assistant</YearlyPurchaseFlowHeader>
        <UpsellBenefitRow small={true}>
          <Box component="span" fontWeight='bold'>Compelling post ideas</Box> tailored for your business
        </UpsellBenefitRow>
        <UpsellBenefitRow small={true}>
          Keeps you on track with a <Box component="span" fontWeight='bold'>custom weekly plan</Box>
        </UpsellBenefitRow>
        <UpsellBenefitRow small={true}>
          Automatically creates <Box component="span" fontWeight='bold'>eye-catching content</Box> when it's time to post
        </UpsellBenefitRow>
        <UpsellBenefitRow small={true}>
          <Box component="span" fontWeight='bold'>Unlimited</Box> ideas, content, and cloud storage
        </UpsellBenefitRow>
        <UpsellBenefitRow small={true}>
          <Box component="span" fontWeight='bold'>Free stock</Box> photos, videos, and music
        </UpsellBenefitRow>
        <Stack sx={{ mt: 11 }}>
          <SubscriptionProductButton onClick={handleYearlyProductSelected} subscriptionDuration={SUBSCRIPTION_DURATION_YEARLY}
                                     selectedDuration={selectedSubscriptionDuration}/>
          <SubscriptionProductButton onClick={handleMonthlyProductSelected} subscriptionDuration={SUBSCRIPTION_DURATION_MONTHLY}
                                     selectedDuration={selectedSubscriptionDuration}/>
          <YearlyPurchaseFlowCTA onClick={handleStripeCheckout}>
            {getPurchaseFlowCTA()}
          </YearlyPurchaseFlowCTA>
        </Stack>
        <Stack direction={"row"} sx={{ pt: "6px", pb: "1px", flexWrap: "wrap", justifyContent: "center" }}>
          <Typography variant={"caption"}
                      sx={{ color: "primary.contrastText", fontSize: "10px", fontWeight: "500" }}>{getFinePrint()}&nbsp;</Typography>
          <Link variant={"caption"} target="_blank" rel="noopener"
                sx={{ color: "primary.contrastText", textDecoration: "underline", fontSize: "10px" }}
                href={ALKAI_SUBSCRIPTION_TERMS_FAQ_URL}>Subscription Terms</Link>
        </Stack>
        <Typography sx={{ fontSize: "10px", color: "primary.contrastText", visibility: `${getDiscountAppliesVisibility()}` }}>Discount available to
                                                                                                                              first-time customers
                                                                                                                              only.</Typography>
      </Stack>
      {showCreateLogin && <CreateAccountOrSignIn shownVia={CREATE_ACCOUNT_SHOWN_VIA_CHECKOUT}
                                                 shouldShowSignIn={showCreateLogin}
                                                 onClose={handleAccountCreated}/>}
    </FullPageMobileContainer>
  )
}

function UpgradeToYearlyUpsell( props: WithFullScreenDialogProps )
{
  const dispatch = useDispatch();
  const userIsGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const userIsGuestRef = useRef( userIsGuest );
  const [showCreateLogin, setShowCreateLogin] = React.useState<boolean>( false );
  const isInAlkaiIOSApp = browserUtils.isAlkaiIOSApp();

  useEffect( () =>
  {
    userIsGuestRef.current = userIsGuest;
  }, [userIsGuest] );

  const handleCloseUpsell = () =>
  {
    eventTracker.logUpgradeToYearlyUpsellCancelClicked( userIsGuest );
    if ( props.handleClose )
    {
      props.handleClose();
    }
  }

  function openSubscriptionFlow()
  {
    if ( isInAlkaiIOSApp )
    {
      iOSMessageSender.startSubscription();
    }
    else
    {
      dispatch( setPaymentDialogOpen( true ) );
    }
  }

  function handleStripeCheckout()
  {
    eventTracker.logUpgradeToYearlyContinueToPaymentClicked();
    dispatch( setUpsellShown( false ) );
    stripeServices.customerPortalUpgradeToYearly().catch( ( error ) =>
    {
      eventTracker.logStripeCustomerPortalFailed( error, PORTAL_TYPE_UPGRADE_TO_YEARLY );
      dispatch( setAlertMessage( errorAlert( "Unable to upgrade to yearly. Please try again or contact support if the problem persists." ) ) );
    } )
  }

  function handleAccountCreated()
  {
    setShowCreateLogin( false );
    if ( !userIsGuestRef.current )
    {
      dispatch( setUpsellShown( false ) );
      openSubscriptionFlow();
    }
  }

  function getPurchaseFlowCTA()
  {
    return "Save 50% for 12 months!";
  }

  function getFinePrint()
  {
    return "No commitment. Cancel anytime. Bills yearly.";
  }

  return (
    <FullPageMobileContainer sx={{ background: UPSELL_GRADIENT }}>
      <IconButton
        aria-label="close"
        color="secondary"
        size="small"
        sx={{
          position: "absolute",
          left: 8,
          top: 8,
          zIndex: 1,
        }}
        onClick={handleCloseUpsell}>
        <CloseIcon fontSize="inherit"/>
      </IconButton>
      <Stack sx={{ display: "flex", alignItems: "center", py: 10, my: "auto", mx: "auto" }}>
        <AlkaiWordmarkLogoWhite sx={{ width: "118px" }}/>
        <YearlyPurchaseFlowHeader sx={{ width: "280px" }}>Your AI Social Media Assistant</YearlyPurchaseFlowHeader>
        <Typography sx={{
          fontSize: "25px",
          color: "primary.contrastText",
          width: "230px",
          fontFamily: "montserrat",
          lineHeight: "30px",
          textAlign: "center",
          fontWeight: "500",
          mt: 6,
          mb: 15
        }}>Switch to a
           Yearly
           subscription
           and <b>save
                  $240!</b></Typography>
        <Stack sx={{ mt: 11 }}>
          <SubscriptionProductButton subscriptionDuration={SUBSCRIPTION_DURATION_YEARLY}
                                     selectedDuration={SUBSCRIPTION_DURATION_YEARLY}/>
          <Typography sx={{
            fontSize: "25px",
            fontWeight: "800",
            color: "primary.contrastText",
            textAlign: "center",
            fontFamily: "montserrat",
          }}>vs.</Typography>
          <SubscriptionProductOutlineItem/>
          <YearlyPurchaseFlowCTA onClick={handleStripeCheckout} marginTop={20}>
            {getPurchaseFlowCTA()}
          </YearlyPurchaseFlowCTA>
        </Stack>
        <Stack direction={"row"} sx={{ pt: "6px", pb: "1px", flexWrap: "wrap", justifyContent: "center" }}>
          <Typography variant={"caption"}
                      sx={{ color: "primary.contrastText", fontSize: "10px", fontWeight: "500" }}>{getFinePrint()}&nbsp;</Typography>
          <Link variant={"caption"} target="_blank" rel="noopener"
                sx={{ color: "primary.contrastText", textDecoration: "underline", fontSize: "10px" }}
                href={ALKAI_SUBSCRIPTION_TERMS_FAQ_URL}>Subscription Terms</Link>
        </Stack>
        <Typography sx={{ fontSize: "10px", color: "primary.contrastText" }}>
          Monthly subscription price shown without active offers.</Typography>
      </Stack>
      {showCreateLogin && <CreateAccountOrSignIn shownVia={CREATE_ACCOUNT_SHOWN_VIA_CHECKOUT}
                                                 shouldShowSignIn={showCreateLogin}
                                                 onClose={handleAccountCreated}/>}
    </FullPageMobileContainer>
  )
}

export const FullScreenUpsellDialog = withFullScreenDialog( UpsellContainer )
