import React, { useContext, useState } from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
import { matchIsValidTel } from "mui-tel-input";
import { LoadingButton } from "@mui/lab";
import { authenticationServices } from "../../services/authentication.services";
import { PRIVACY_POLICY_URL, SIGN_IN_PROVIDER_EMAIL, SIGN_IN_PROVIDER_PHONE_NUMBER, TERMS_OF_USE_URL } from "../constants";
import { eventTracker } from "../../helpers/eventTracker";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { useDispatch } from "react-redux";
import { PhoneNumberInput } from "./phoneNumberInput";
import { EmailInput } from "./emailInput";
import validator from "validator";
import { CreateAccountSwitchFormLink } from "./createAccountSwitchFormLink";
import { isEmailCrmSource } from "../utils/crmSourceUtils";
import { logToConsoleError } from "../utils/devLoggingHelper";
import { FinePrint } from "./finePrint";
import { ShowCarouselSignInContext } from "../context/showCarouselSignInContext";
import { merge } from "lodash";

export interface SignInProps
{
  onUserInputChanged: ( newInputValue: string ) => void;
  onSignInProviderChangeRequested: () => void;
  onSignInClose: () => void;
  signInProvider: string;
  source: string;
  shownVia: string;
  signInAsGuestInProgress: boolean;
}

export function SignIn( props: SignInProps )
{
  const shouldShowEmailInput = props.signInProvider === SIGN_IN_PROVIDER_EMAIL;
  const shouldShowPhoneNumberInput = props.signInProvider === SIGN_IN_PROVIDER_PHONE_NUMBER;
  const [showLoader, setShowLoader] = useState( false );
  const showCarouselSignIn = useContext( ShowCarouselSignInContext );

  const dispatch = useDispatch();

  const [userInputValue, setUserInputValue] = useState<string>( "" );
  const [isUserInputValid, setIsUserInputValid] = useState<boolean>( false );

  const handleSubmit = async ( event: React.FormEvent<HTMLFormElement> ) =>
  {
    event.preventDefault();

    if ( props.signInProvider === SIGN_IN_PROVIDER_PHONE_NUMBER )
    {
      const phoneIsValid = matchIsValidTel( userInputValue );
      if ( !phoneIsValid )
      {
        dispatch( setAlertMessage( errorAlert( "Enter a valid phone number to continue" ) ) )
        return
      }
    }
    else if ( props.signInProvider === SIGN_IN_PROVIDER_EMAIL )
    {
      const emailIsValid = validator.isEmail( userInputValue );
      if ( !emailIsValid )
      {
        dispatch( setAlertMessage( errorAlert( "Enter a valid email to continue" ) ) )
        return
      }
    }

    eventTracker.logSignInClicked( props.source, props.shownVia )
    setShowLoader( true )

    authenticationServices.createAccountOrSignIn( userInputValue, props.signInProvider ).then( ( response ) =>
    {
      if ( response.verification_required )
      {
        props.onUserInputChanged( userInputValue );
      }
      setShowLoader( false );
    } ).catch( ( error ) =>
    {
      logToConsoleError( error );
      dispatch( setAlertMessage( errorAlert( "Unable to create account" ) ) );
      setShowLoader( false );
    } );
  }

  const handleInputChanged = ( value: string, valid: boolean ) =>
  {
    setUserInputValue( value );
    setIsUserInputValid( valid );
  }

  const onSignInProviderChangeRequested = () =>
  {
    setUserInputValue( "" );
    setIsUserInputValid( false );
    props.onSignInProviderChangeRequested();
  }

  function getSwitchToEmailText()
  {
    return "Outside the US or Canada?";
  }

  function getSwitchToPhoneNumberText()
  {
    if ( isEmailCrmSource( props.source ) )
    {
      return "Sign in with phone number?";
    }
    return "Go back";
  }

  const getSwitchSignInProviderLinkText = () =>
  {
    return props.signInProvider === SIGN_IN_PROVIDER_PHONE_NUMBER ? getSwitchToEmailText() : getSwitchToPhoneNumberText();
  }

  function getSignInButtonStyling()
  {
    if ( showCarouselSignIn && isUserInputValid )
    {
      return {
        backgroundImage: "-webkit-linear-gradient(82deg, #423593 31.67%, #97457A 49.15%, #FA585E 69.38%)",
      };
    }
    return {};
  }

  function getFinePrintStyle()
  {
    if ( showCarouselSignIn )
    {
      return {
        textAlign: "center",
      };
    }
    return {};
  }

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }}>
      {shouldShowPhoneNumberInput && <PhoneNumberInput handleInputChanged={handleInputChanged}/>}
      {shouldShowEmailInput && <EmailInput handleInputChanged={handleInputChanged}/>}

      <LoadingButton type="submit"
                     fullWidth
                     variant="contained"
                     sx={merge( { mt: 3, mb: 2 }, getSignInButtonStyling() )}
                     disabled={!isUserInputValid}
                     loading={showLoader}>
        Sign in
      </LoadingButton>
      <CreateAccountSwitchFormLink onClick={onSignInProviderChangeRequested}>{getSwitchSignInProviderLinkText()}</CreateAccountSwitchFormLink>
      <Stack>
        <FinePrint sx={getFinePrintStyle()}>
          By continuing, I agree to the <Link href={TERMS_OF_USE_URL} rel="noopener" target="_blank">Terms of Use</Link> and that Alkai may process my
          information for the purposes of disclosed and as otherwise set forth in the <Link href={PRIVACY_POLICY_URL} rel="noopener" target="_blank">Privacy
                                                                                                                                                     Policy.</Link> Carrier
          message/data rates may apply
        </FinePrint>

        {showCarouselSignIn && <Typography variant={"subtitle2"} textAlign={"center"} paddingY={5}>
          First 3 posts FREE, no credit card required
        </Typography>

        }
      </Stack>
    </Box>
  )
}

