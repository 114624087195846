import { detect, detectOS } from "detect-browser";
import { last } from "lodash";

const isAndroidBrowser = () =>
{
  return detectOS( window.navigator.userAgent ) === "Android OS";
};

const isiOSBrowser = () =>
{
  return detectOS( window.navigator.userAgent ) === "iOS";
};

const isBlackberryOSBrowser = () =>
{
  return detectOS( window.navigator.userAgent ) === "BlackBerry OS";
};

const isWindowsMobileBrowser = () =>
{
  return detectOS( window.navigator.userAgent ) === "Windows Mobile";
};

const isAmazonOSBrowser = () =>
{
  return detectOS( window.navigator.userAgent ) === "Amazon OS";
};

const isSafariBrowser = () =>
{
  return detect( window.navigator.userAgent )?.name === "safari";
};

const isEdgeBrowser = () =>
{
  return detect( window.navigator.userAgent )?.name === "edge-chromium";
}

const isUnsupportedBrowser = () =>
{
  return isEdgeBrowser();
}

const isMobileBrowser = () =>
{
  return isAndroidBrowser() || isiOSBrowser() || isBlackberryOSBrowser() || isWindowsMobileBrowser() || isAmazonOSBrowser();
};

const isFacebookBrowser = () =>
{
  return detect( window.navigator.userAgent )?.name === "facebook";
}

const isInstagramBrowser = () =>
{
  return detect( window.navigator.userAgent )?.name === "instagram";
}

const isNativeShareSupported = () =>
{
  return (isMobileBrowser() && !isAndroidBrowser()) && navigator && navigator.canShare;
}

function getActionTextForMobileOrWeb()
{
  return isMobileBrowser() ? "tap" : "click";
}

function copyToClipboard( clipboardText: string )
{
  if ( !!navigator.clipboard )
  {
    navigator.clipboard.writeText( clipboardText );
    return true;
  }
  return false;
}

function sleep( ms: number )
{
  return new Promise( ( r ) => setTimeout( r, ms ) );
}

function isInStandaloneMode()
{
  return window.matchMedia( '(display-mode: standalone)' ).matches ||
         ('standalone' in window.navigator && !!window.navigator.standalone)
}

function isAlkaiIOSApp()
{
  return window.navigator.userAgent.includes( "Alkai-iOS" );
}

function isIosProgressiveWebApp()
{
  return browserUtils.isiOSBrowser() && browserUtils.isInStandaloneMode();
}

function isIosProgressiveWebAppOriOSApp()
{
  return browserUtils.isIosProgressiveWebApp() || browserUtils.isAlkaiIOSApp();
}

function bottomSafeAreaPadding()
{
  if ( browserUtils.isIosProgressiveWebAppOriOSApp() )
  {
    const metaViewport = last( document.getElementsByName( 'viewport' ) );
    if ( !!metaViewport && metaViewport.getAttribute( 'content' )?.includes( 'viewport-fit=cover' ) )
    {
      return 'calc( max(0px, env(safe-area-inset-bottom, 10px) - 14px) )';
    }
    else
    {
      return 10;
    }
  }
  return 0;
}

export const browserUtils = {
  isAndroidBrowser,
  isiOSBrowser,
  isBlackberryOSBrowser,
  isWindowsMobileBrowser,
  isAmazonOSBrowser,
  isMobileBrowser,
  isSafariBrowser,
  isFacebookBrowser,
  isInstagramBrowser,
  isNativeShareSupported,
  isUnsupportedBrowser,
  getActionTextForMobileOrWeb,
  copyToClipboard,
  sleep,
  isInStandaloneMode,
  isAlkaiIOSApp,
  isIosProgressiveWebApp,
  isIosProgressiveWebAppOriOSApp,
  bottomSafeAreaPadding,
};
