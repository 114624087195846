import { Accordion, AccordionDetails, Box, Button, Stack } from "@mui/material";
import { format } from "date-fns";
import { SHARING_STATUS_FAILED } from "../../services/business.services";
import React, { useContext, useEffect, useState } from "react";
import AutoModeIcon from '@mui/icons-material/AutoMode';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { PlanItemIconLabel } from "./PlanItemIconLabel";
import { PlanAccordionSummary } from "./planAccordionSummary";
import { useDispatch, useSelector } from "react-redux";
import { PlanItemCtaFooterContainer } from "./planItemCTAFooterContainer";
import { getPlanLastPostIdeaEdited, setUpsellShown } from "../ui/uiSlice";
import { postIdeaServices, ShareBatchAPI } from "../../services/postIdeaServices";
import { filter, map } from "lodash";
import { DateUtils } from "../utils/dateUtils";
import {
  getEffectiveCompletedDisplayDate,
  getInitialScheduledTimeForCompletedPost,
  getValidScheduledDate,
  isShareBatchSending
} from "../postIdea/shareBatchHelper";
import { ShareDrawer } from "./shareDrawer";
import { needsRecording, postIdeaHelper } from "../postIdea/postIdeaHelper";
import { RootState } from "../../app/store";
import { shouldBlockForUpsell } from "../user/userSlice";
import SendIcon from "@mui/icons-material/Send";
import { ShareNowTabContentSources } from "../editing/directShare";
import {
  PLAN_TAB_COMPLETED_POSTS_EDIT_BUTTON_TEXT,
  PLAN_TAB_COMPLETED_POSTS_PUBLISHING_TEXT,
  PLAN_TAB_COMPLETED_POSTS_SHARE_AGAIN_TEXT,
  POST_VIEW_DATE_FORMAT_DAY_DIFFERENT_THAN_PLAN,
  POST_VIEW_DATE_FORMAT_DAY_SAME_AS_PLAN,
  ROUTE_PARAM,
  ROUTES
} from "../constants";
import { SharedVideoAndCaption } from "./sharedVideoAndCaption";
import { ShareBatchAccordionHeader } from "./shareBatchAccordionHeader";
import { PlanDateContext } from "../context/planDateContext";
import { PlanIdeaTitleTypography } from "./planIdeaTitleTypography";
import { OutputFormatIconAndLabel } from "./outputFormatIconAndLabelSmall";
import { ContentGoalIconAndLabel } from "./contentGoalIconAndLabelSmall";
import { eventTracker } from "../../helpers/eventTracker";
import { UNAUTHORIZED_ERROR } from "../../services/requestManager";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import EditIcon from "@mui/icons-material/Edit";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { PostIdeaEditor } from "../editing/postIdeaEditor";
import LoadingButton from "@mui/lab/LoadingButton";
import { UrlUtils } from "../utils/urlUtils";
import { useSearchParams } from "react-router-dom";

interface CompletedPostViewProps
{
  shareBatch: ShareBatchAPI;
  handleUpdatePlannerData: () => void;
}

export function CompletedPostView( props: CompletedPostViewProps )
{
  const dispatch = useDispatch();
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const [searchParams] = useSearchParams();
  const navigatedFromPostComplete = UrlUtils.isParamValueTrue( searchParams.get( ROUTE_PARAM.PLAN_TAB_POST_COMPLETE ) )

  const [loading, setLoading] = useState<boolean>( false );

  const lastPostIdeaEdited = useSelector( ( state ) => getPlanLastPostIdeaEdited( state ) );
  const [expanded, setExpanded] = React.useState<boolean>( lastPostIdeaEdited === props.shareBatch.post_idea?.id );
  const failedSocialNetworkPosts = filter( props.shareBatch.social_network_posts || [],
    ( socialNetworkPost ) => socialNetworkPost.send_status === SHARING_STATUS_FAILED );

  const [showShareDrawer, setShowShareDrawer] = React.useState( false );
  const blockForUpsell = useSelector( ( state: RootState ) => shouldBlockForUpsell( state ) );
  const [shareButtonEnabled, setShareButtonEnabled] = React.useState<boolean>( true );

  const planDateContext = useContext( PlanDateContext );
  const planDate = planDateContext.planDate;

  const accentColor = getAccentColor();
  const backgroundColor = getBackgroundColor();

  const [shareBatchForEditor, setShareBatchForEditor] = useState<ShareBatchAPI>();
  const [postIdeaIdForEditor, setPostIdeaIdForEditor] = useState<string>();

  useEffect( () =>
  {
    if ( navigatedFromPostComplete )
    {
      // If the user navigated from to plan with post complete parameter, we want to close the editor
      setPostIdeaIdForEditor( undefined );
    }
  }, [navigatedFromPostComplete] );

  const handleAccordionClicked = () =>
  {
    if ( !expanded )
    {
      setExpanded( true );
    }
    else
    {
      setExpanded( false );
    }
  }

  function getIcon()
  {
    if ( isShareBatchSending( props.shareBatch ) )
    {
      return <PlanItemIconLabel label="Publishing" color={accentColor}>
        <AutoModeIcon sx={{ color: "primary.contrastText", height: "15px", width: "15px" }}/>
      </PlanItemIconLabel>;
    }
    else if ( failedSocialNetworkPosts.length > 0 && failedSocialNetworkPosts.length === props.shareBatch.social_network_posts?.length )
    {
      return <PlanItemIconLabel label="Attention required" color={accentColor}>
        <ErrorOutlineIcon sx={{ color: "primary.contrastText", height: "15px", width: "15px" }}/>
      </PlanItemIconLabel>;
    }
    else
    {
      return <PlanItemIconLabel label="Completed" color={accentColor}>
        <CheckBoxOutlinedIcon sx={{ color: "primary.contrastText", height: "15px", width: "15px" }}/>
      </PlanItemIconLabel>;
    }
  }

  function getAccentColor()
  {
    return isShareBatchSending( props.shareBatch ) ? "planPublishing.main" : "planCompleted.main";

  }

  function getBackgroundColor()
  {
    return isShareBatchSending( props.shareBatch ) ? "planPublishing.light" : "planCompleted.light";
  }

  function getCompletedPostDateText()
  {
    const dateToUse = getEffectiveCompletedDisplayDate( props.shareBatch );
    let dateFormat = POST_VIEW_DATE_FORMAT_DAY_SAME_AS_PLAN;

    if ( isShareBatchSending( props.shareBatch ) )
    {
      return "";
    }
    else if ( !DateUtils.isSameCalendarDay( planDate, dateToUse ) )
    {
      dateFormat = POST_VIEW_DATE_FORMAT_DAY_DIFFERENT_THAN_PLAN;
    }
    return "Post completed " + format( dateToUse, dateFormat );
  }

  function planCTAFooter()
  {
    return <PlanItemCtaFooterContainer>
       <LoadingButton
         variant="outlined"
         onClick={handleEditClicked}
         loading={loading}
         loadingPosition="start"
         startIcon={<EditIcon/>}>
         {PLAN_TAB_COMPLETED_POSTS_EDIT_BUTTON_TEXT}
       </LoadingButton>
      {isShareBatchSending( props.shareBatch ) && <Button
        startIcon={<SendIcon/>}
        variant="contained"
        disabled={true}>
        {PLAN_TAB_COMPLETED_POSTS_PUBLISHING_TEXT}
      </Button>}
      {!isShareBatchSending( props.shareBatch ) && <Button
        startIcon={<SendIcon/>}
        variant="contained"
        disabled={!shareButtonEnabled}
        onClick={handleShareClicked}>
        {PLAN_TAB_COMPLETED_POSTS_SHARE_AGAIN_TEXT}
      </Button>}
    </PlanItemCtaFooterContainer>;
  }

  async function handleEditClicked( event: React.MouseEvent<HTMLButtonElement> )
  {
    event.stopPropagation();
    if ( loading )
    {
      return;
    }

    setLoading( true );
    if ( !!props.shareBatch.post_idea )
    {
      if ( blockForUpsell && needsRecording( props.shareBatch.post_idea ) )
      {
        dispatch( setUpsellShown( true ) );
        setLoading( false );
      }
      else
      {
        try
        {
          const dateToUse = new Date();
          const shareBatchDraftCreated = await postIdeaServices.createDraftShareBatch( props.shareBatch.post_idea.id, dateToUse );

          if ( !!shareBatchDraftCreated.share_batch.drafted_at && !!shareBatchDraftCreated.share_batch.post_idea.id )
          {
            setShareBatchForEditor( shareBatchDraftCreated.share_batch );
            setPostIdeaIdForEditor( shareBatchDraftCreated.share_batch.post_idea.id )
          }
          else
          {
            throw new Error( "Drafted at date not found" );
          }
        }
        catch (e)
        {
          dispatch( setAlertMessage( errorAlert( "Error creating draft. Please try again later." ) ) );
          setLoading( false );
        }
      }
    }
  }

  function handleShareClicked()
  {
    eventTracker.logPlanPostShareClicked( props.shareBatch.post_idea );

    if ( !!props.shareBatch.post_idea )
    {
      if ( blockForUpsell && needsRecording( props.shareBatch.post_idea ) )
      {
        dispatch( setUpsellShown( true ) );
      }
      else
      {
        setShareButtonEnabled( false );
        setShowShareDrawer( true );
      }
    }
  }

  function onDrawerClosed()
  {
    setShowShareDrawer( false );
    setShareButtonEnabled( true );
  }

  function handleRetrySocialShare()
  {
    const postIdeaId = props.shareBatch.post_idea.id;
    const socialNetworkPostIdsToRetry = map( failedSocialNetworkPosts, ( socialNetworkPost ) => socialNetworkPost.id );
    const shareBatchId = props.shareBatch.id;
    return postIdeaServices.retryShareToSocialNetworks( postIdeaId, socialNetworkPostIdsToRetry, shareBatchId )
      .then( () =>
      {
        props.handleUpdatePlannerData();
      } )
      .catch( ( error ) =>
      {
        if ( error === UNAUTHORIZED_ERROR )
        {
          dispatch( setAlertMessage( errorAlert( "Unable to retry share.  Please login and try again.", "top" ) ) );
        }
        else
        {
          props.handleUpdatePlannerData();
        }
      } );
  }

  function getAdditionalHeaderContent()
  {
    if ( !!props.shareBatch )
    {
      return (<ShareBatchAccordionHeader shareBatch={props.shareBatch}
                                         backgroundColor={getAccentColor()}
                                         handleRetrySocialShare={handleRetrySocialShare}></ShareBatchAccordionHeader>);
    }
    return null;
  }

  function handleErrorLoadingPostIdeaData()
  {
    setPostIdeaIdForEditor( undefined );
    setLoading( false );
  }

  function handlePostIdeaReadyToEdit()
  {
    setLoading( false );
  }

  async function handleCloseFullScreenEditor()
  {
    setPostIdeaIdForEditor( undefined );

    if ( shareBatchForEditor && !!shareBatchForEditor.drafted_at )
    {
      await postIdeaHelper.updateStoreWithLatestPostIdeaAndPost( shareBatchForEditor.post_idea.id );

      const planDateToLoad = new Date( shareBatchForEditor.drafted_at );
      const planTabDateStringToLoad = DateUtils.toISODateString( planDateToLoad );
      navigateWithSearchParams( ROUTES.PLAN, {
        [ROUTE_PARAM.PLAN_TAB_REFRESH_REQUEST]: true,
        [ROUTE_PARAM.PLAN_TAB_DATE]: planTabDateStringToLoad
      } );
    }
  }

  return <Box sx={{ mb: 5 }}> <Accordion
    expanded={expanded}
    sx={{
      backgroundColor: backgroundColor,
      borderRadius: "5px",
      p: 0,
    }}>
    <PlanAccordionSummary
      onClick={handleAccordionClicked}
      icon={getIcon()}
      savedPostIdeaTitle={<Stack>
        <PlanIdeaTitleTypography>{props.shareBatch.post_idea?.topic_name}</PlanIdeaTitleTypography>
        <OutputFormatIconAndLabel outputFormatSlug={props.shareBatch.output_format_slug}/>
        <ContentGoalIconAndLabel contentGoal={props.shareBatch.content_goal}/>
      </Stack>}
      accentColor={getAccentColor()}
      helperText={getCompletedPostDateText()}
      expanded={expanded}
      allowedToCollapse={true}
      planCTAFooter={planCTAFooter()}
      additionalHeaderContent={getAdditionalHeaderContent()}
    />
    <AccordionDetails sx={{ p: 0 }}>
      {expanded && <SharedVideoAndCaption videoUrl={props.shareBatch.video_url} caption={props.shareBatch.caption}
                                          imageUrl={props.shareBatch.image_url}/>}
      {planCTAFooter()}
    </AccordionDetails>
  </Accordion>
    {!!postIdeaIdForEditor && <PostIdeaEditor postIdeaId={postIdeaIdForEditor}
                                              shareBatch={shareBatchForEditor}
                                              initialScheduleForDate={getValidScheduledDate( new Date() )}
                                              handlePostIdeaReadyToEdit={handlePostIdeaReadyToEdit}
                                              handleClose={handleCloseFullScreenEditor}
                                              handleErrorLoadingPostIdeaData={handleErrorLoadingPostIdeaData}/>}
    {showShareDrawer && <ShareDrawer sharedFrom={ShareNowTabContentSources.PLAN}
                                     postIdea={props.shareBatch.post_idea}
                                     previousShareBatch={props.shareBatch}
                                     showShareDrawer={showShareDrawer}
                                     closeDrawer={onDrawerClosed}
                                     onCloseFromBackdropClick={onDrawerClosed}
                                     initialScheduleForDate={getInitialScheduledTimeForCompletedPost( props.shareBatch )}/>}
  </Box>
}
