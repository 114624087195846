import { Divider, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { ROUTE_SEO, ROUTES } from "../constants";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import React, { useEffect, useState } from "react";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import AlertBanner from "../alert/alertBanner";
import { eventTracker } from "../../helpers/eventTracker";
import { UserSettingsRow } from "../userSettings/userSettingsRow";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { hasEmail, hasPhoneNumber } from "../user/userSlice";
import { NotificationSubscriptionToggle } from "../userSettings/NotificationSubscriptionToggle";
import { businessServices } from "../../services/business.services";
import { isSubscribedAllNotifications } from "../business/businessSlice";

export function AccountSettingsPage()
{
  const navigateWithSearchParams = useNavigateWithSearchParams();

  const isSubscribedFromAllNotifications = useSelector( ( state: RootState ) => isSubscribedAllNotifications( state ) );
  const [isSubscribedFromAllNotificationsToggleOn, setIsSubscribedFromAllNotificationsToggleOn] = useState<boolean>(
    isSubscribedFromAllNotifications );

  const hasPhoneChannel = useSelector( ( state: RootState ) => hasPhoneNumber( state ) );
  const hasEmailChannel = useSelector( ( state: RootState ) => hasEmail( state ) );
  const hasCommunicationChannel = hasPhoneChannel || hasEmailChannel;

  useEffect( () =>
  {
    setIsSubscribedFromAllNotificationsToggleOn( isSubscribedFromAllNotifications );
  }, [isSubscribedFromAllNotifications] );

  function onSubscribeAllNotificationsChange( event: React.ChangeEvent<HTMLInputElement> )
  {
    const newValue = event.target.checked;
    setIsSubscribedFromAllNotificationsToggleOn( newValue );

    eventTracker.logUnsubscribedAllNotificationsChanged( !newValue );
    businessServices.updateUnsubscribedFromNotifications( !newValue );
  }

  async function handleBackClicked()
  {
    navigateWithSearchParams( ROUTES.USER_SETTINGS );
  }

  function handleDeleteMyAccount()
  {
    eventTracker.logDeleteMyAccountClicked();
    navigateWithSearchParams( ROUTES.DELETE_MY_ACCOUNT );
  }

  return (
    <Stack spacing={5} sx={{ textAlign: "left", px: 5, py: 10 }} alignItems="start">
      <Helmet>
        <title>{ROUTE_SEO.ACCOUNT_SETTINGS.title}</title>
        <meta name="description" content={ROUTE_SEO.ACCOUNT_SETTINGS.description}/>
      </Helmet>
      <AlertBanner/>
      <Stack direction="row" sx={{ width: "100%", px: 5 }}>
        <IconButton onClick={handleBackClicked} sx={{ padding: "unset", position: "absolute" }}>
          <ArrowBackIcon/>
        </IconButton>
        <Typography sx={{ textAlign: "center", flex: "1 1 0", width: "100%" }}>Account settings</Typography>
      </Stack>
      {
        hasCommunicationChannel && <>
                                  <NotificationSubscriptionToggle isToggleOn={isSubscribedFromAllNotificationsToggleOn} onChange={onSubscribeAllNotificationsChange}/>
                                  <Divider sx={{ width: "100%" }}/>
                                </>
      }
      <UserSettingsRow sx={{ pb: 2 }}
                       icon={<PersonRemoveOutlinedIcon/>}
                       label="Delete my account"
                       onClick={handleDeleteMyAccount}/>
    </Stack>);
}
