import { Button } from "@mui/material";

export interface YearlyPurchaseFlowCTAProps
{
  children?: React.ReactNode;
  onClick?(): void;
  marginTop?: number;
}

export function YearlyPurchaseFlowCTA( props: YearlyPurchaseFlowCTAProps )
{
  const marginTop = props.marginTop || 5;
  return (
    <Button
      variant="contained"
      sx={{
        display: "flex",
        mx: "auto",
        mt: marginTop,
        mb: 5,
        px: "12px",
        py: "16px",
        backgroundColor: "#BDE792",
        borderRadius: "6px",
        color: "#23163B",
        fontSize: "16px",
        lineHeight: "22px",
        fontFamily: "Montserrat",
        fontWeight: "600",
        textTransform: "none",
        width: "90%",
        '&:hover': { backgroundColor: "#BDE792", color: "#23163B" }
      }}
      onClick={props.onClick}>
      {props.children}
    </Button>
  );
}


