import * as React from "react";
import { ReactNode } from "react";
import { Stack } from "@mui/material";

export interface ConfirmDialogContentProps
{
  children: ReactNode;
}

export function ConfirmDialogContent( props: ConfirmDialogContentProps )
{
  return (
    <Stack sx={{ px: 20, textAlign: "left" }}>
      {props.children}
    </Stack>
  );
}