import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";

export function SubscriptionProductOutlineItem()
{
  function getTitle()
  {
    return "Monthly";
  }

  function getPriceDescription()
  {
    return "$480, billed monthly"
  }

  function getMonthlyPriceText()
  {
    return "$40/mo";
  }

  return (
    <Box sx={{ border: "2px solid white", borderRadius: "10px", mt: "5px", py: "1px", width: "350px", position: "relative" }}>
        <Stack sx={{ p: "5px" }}>
          <Stack direction={"row"} sx={{ alignItems: "center", justifyContent: "space-between", ml: "10px" }}>
            <Stack>
              <Typography variant={"h5"} sx={{ color: "white", fontWeight: "500", fontSize: "20px" }}>{getTitle()}</Typography>
              <Typography sx={{ color: "white", fontSize: "13px", fontWeight: "400" }}>{getPriceDescription()}</Typography>
            </Stack>
            <Typography variant={"h5"}
                        sx={{ color: "white", fontWeight: "500", fontSize: "20px", mr: "20px" }}>{getMonthlyPriceText()}</Typography>
          </Stack>
        </Stack>
    </Box>
  );
}


