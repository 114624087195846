import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import AlertBanner from "../alert/alertBanner";
import { chatServices } from "../../services/chat.services";
import SendIcon from "@mui/icons-material/Send";
import { chain, flatten, head, isEmpty, map, nth, size } from "lodash";
import { Message } from "../message/message";
import {
  getAssistantType,
  getMessages,
  getPostIdeaMessages,
  MediaSelection,
  MessageAPI,
  setCheckMessagesFailedAllAttempts,
  setEnsureChatThread,
  setSelectedMedia,
  setShowChooseMediaButton,
  setWaitingForThreadRunToComplete,
  showChooseMediaButton,
  showOnboardingQuickActions,
  showQuickActions
} from "./assistantChatSlice";
import { AssistantChooseMedia } from "../assistantChooseMedia/assistantChooseMedia";
import { eventTracker } from "../../helpers/eventTracker";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import {
  ALKAI_ONBOARDING_FIRST_CLIENT_SIDE_MESSAGE,
  ALKAI_ONBOARDING_FIRST_CLIENT_SIDE_MESSAGE_DEFAULT_OLDEST_DATE_IMAGINABLE,
  KEYBOARD_ENTER_KEY_ID,
  MAX_MESSAGES_FROM_OPEN_AI,
  MESSAGE_ROLE_ASSISTANT,
  MESSAGE_TYPE_REEL_APPROVAL,
  MESSAGE_TYPE_THREAD_IMAGE,
  MESSAGE_TYPE_THREAD_MESSAGE,
  QUICK_ACTIONS_WAIT_TIME_MS,
  ROUTE_SEO,
  SEARCH_PARAM_KEY_WEBSITE
} from "../constants";
import "./assistantChat.scss"
import { BouncingDotsLoader } from "../loadingIndicator/BouncingDotsLoader";
import { QuickActionPanel } from "../ui/QuickActionPanel";
import { useForceUpdate } from "../hooks/useForceUpdate";
import { hasSeenPostStrategyQuiz, setHasSeenPostStrategyQuiz, setUpsellShown } from "../ui/uiSlice";
import { hasMultipleBusinesses, shouldBlockForUpsell } from "../user/userSlice";
import { UPSELL_SOURCE_SEND_CHAT_MESSAGE } from "../../helpers/trackingConstants";
import { currentUserBusinessCreatedAt, currentUserBusinessId, hasCompletedFirstRun } from "../business/businessSlice";
import { useVisibilityChange } from "@uidotdev/usehooks";
import { DateUtils } from "../utils/dateUtils";
import { getRotatingWaitingMessage, WAITING_TYPE_CREATING_FIRST_POST_IDEA } from "./waitingMessageHelper";
import { Helmet } from "react-helmet";
import { PostIdeaNotification } from "./postIdeaNotification";
import { OnboardingQuickActionPanel } from "../ui/onboardingQuickActionPanel";
import useRemoveSearchParamByKey from "../hooks/useRemoveSearchParamByKey";
import { PostingStrategyQuizFullScreenDialog } from "../postingStrategyQuiz/postingStrategyQuizFullScreenDialog";
import { isApplicationLoading } from "../loadingIndicator/loadingSlice";
import { businessServices } from "../../services/business.services";

export function AssistantChat()
{
  const [userInput, setUserInput] = useState( "" );
  const [loading, setLoading] = useState( false );
  const applicationLoading = useSelector( ( state: RootState ) => isApplicationLoading( state ) );
  const waitingForThreadRun = useSelector( ( state: RootState ) => state.assistantChat.waitingForThreadRunToComplete );
  const currentWaitingType = useSelector( ( state: RootState ) => state.assistantChat.waitingType );
  const shouldShowWaiting = loading || waitingForThreadRun
  const messages = useSelector( ( state: RootState ) => getMessages( state ) );
  const postIdeaMessages = useSelector( ( state: RootState ) => getPostIdeaMessages( state ) );
  const dispatch = useDispatch();
  const chatBottomRef = useRef<HTMLDivElement>( null );
  const shouldShowChooseMediaButton = useSelector( ( state: RootState ) => showChooseMediaButton( state ) );
  const shouldShowRetryCheckMessagesButton = useSelector( ( state: RootState ) => state.assistantChat.checkMessagesFailedAllAttempts );
  const shouldShowEnsureChatThreadButton = useSelector( ( state: RootState ) => state.assistantChat.ensureChatThread );
  const isCurrentBusinessSet = useSelector( ( state: RootState ) => !!currentUserBusinessId( state ) );
  const hasSingleBusiness = useSelector( ( state: RootState ) => !hasMultipleBusinesses( state ) );
  const currentBusinessCreatedAt = useSelector( ( state: RootState ) => currentUserBusinessCreatedAt( state ) );
  const hasFinishedFirstRun = useSelector( ( state: RootState ) => hasCompletedFirstRun( state ) );

  const assistantType = useSelector( ( state: RootState ) => getAssistantType( state ) );
  const firstCheckForMessageCompleted = !!assistantType;

  const businessCreationDateOrDefault = currentBusinessCreatedAt ? new Date( currentBusinessCreatedAt ) : new Date(
    ALKAI_ONBOARDING_FIRST_CLIENT_SIDE_MESSAGE_DEFAULT_OLDEST_DATE_IMAGINABLE );
  const clientSideIntroMessage = buildClientSideIntroMessage( businessCreationDateOrDefault );
  const messagesToShow = getMessagesToShow();
  const numberOfMessagesToShow = size( messagesToShow );
  const isOnlyHardcodedMessageScenario = numberOfMessagesToShow === 1 && head( messagesToShow ) !== clientSideIntroMessage;
  const hasPreviousChatHistoryWithOpenAI = numberOfMessagesToShow > 1;
  const eligibleToShowOnboardingQuickActions = useSelector( ( state: RootState ) => showOnboardingQuickActions( state )
  )
  const shouldShowOnboardingQuickActions = eligibleToShowOnboardingQuickActions
                                           && shouldInjectHardCodedIntroMessageAtTopOfChat()
                                           && firstCheckForMessageCompleted;
  const showingErrorUI = shouldShowRetryCheckMessagesButton || shouldShowEnsureChatThreadButton;

  const shouldShowUserMessageInput = !shouldShowOnboardingQuickActions && !shouldShowChooseMediaButton && !showingErrorUI
                                     && isCurrentBusinessSet;
  const blockedForUpsell = useSelector( ( state: RootState ) => shouldBlockForUpsell( state ) );
  const showQuickActionsState = useSelector( ( state: RootState ) => showQuickActions( state ) );
  const shouldShowQuickActions = showQuickActionsState &&
                                 !shouldShowChooseMediaButton &&
                                 !showingErrorUI &&
                                 !blockedForUpsell;
  const selectedMedia = useSelector( ( state: RootState ) => state.assistantChat.selectedMedia );
  const inputRef = useRef<HTMLInputElement>();
  const isUserIdle = !(waitingForThreadRun || loading || !isEmpty( userInput ));
  const showChatHintPulse = isUserIdle && !blockedForUpsell;
  const forceUpdate = useForceUpdate();
  const renderQuickActionsTimerRef = useRef<NodeJS.Timeout>();
  const documentVisible = useVisibilityChange();
  const [wasDocumentHiddenWhileLoaded, setWasDocumentHiddenWhileLoaded] = useState( false );

  const removeSearchParamByKey = useRemoveSearchParamByKey();

  const hasSeenStrategyQuiz = useSelector( ( state: RootState ) => hasSeenPostStrategyQuiz( state ) );
  const isWaitingTypeCreatingFirstPostIdea = currentWaitingType === WAITING_TYPE_CREATING_FIRST_POST_IDEA
  const [showPostingStrategyQuiz, setShowPostingStrategyQuiz] = useState<boolean>( isWaitingTypeCreatingFirstPostIdea && !hasSeenStrategyQuiz );

  useEffect( () =>
  {
    restartQuickActionsTimer();
    return () =>
    {
      clearQuickActionsTimer();
    }
  }, [] );

  useEffect( () =>
  {
    if ( isCurrentBusinessSet && isAllowedToCheckForMessages() )
    {
      clearWebsiteParameter();
      checkThreadMessages();
    }
  }, [isCurrentBusinessSet] );

  function clearWebsiteParameter()
  {
    removeSearchParamByKey( SEARCH_PARAM_KEY_WEBSITE );
  }

  useEffect( () =>
  {
    if ( documentVisible )
    {
      if ( wasDocumentHiddenWhileLoaded && !chatServices.hasCheckMessageTimeout() && isAllowedToCheckForMessages() )
      {
        setWasDocumentHiddenWhileLoaded( false );
        checkThreadMessages();
      }
    }
    else
    {
      setWasDocumentHiddenWhileLoaded( true );
    }
  }, [documentVisible, wasDocumentHiddenWhileLoaded] );

  function restartQuickActionsTimer()
  {
    clearQuickActionsTimer()

    renderQuickActionsTimerRef.current = setTimeout( () =>
    {
      forceUpdate()
    }, QUICK_ACTIONS_WAIT_TIME_MS );
  }

  function clearQuickActionsTimer()
  {
    if ( renderQuickActionsTimerRef.current )
    {
      clearTimeout( renderQuickActionsTimerRef.current );
    }
  }

  useEffect( () =>
  {
    if ( inputRef.current )
    {
      inputRef.current.focus();
    }
  }, [inputRef.current, loading, messages] );

  useEffect( () =>
  {
    chatBottomRef.current?.scrollIntoView( { block: 'start' } );
  }, [messages, loading, selectedMedia, shouldShowWaiting] );

  useEffect( () =>
  {
    if ( isWaitingTypeCreatingFirstPostIdea && !showPostingStrategyQuiz && !hasSeenStrategyQuiz )
    {
      setShowPostingStrategyQuiz( true );
    }
  }, [isWaitingTypeCreatingFirstPostIdea, showPostingStrategyQuiz, hasSeenStrategyQuiz] );

  const handleKeyDown = ( event: { which: number; preventDefault: () => void; } ) =>
  {
    if ( event.which === KEYBOARD_ENTER_KEY_ID )
    {
      event.preventDefault();
      sendValue();
    }
  };

  const sendCompleteChooseMedia = async ( message, messageSelectedMedia ) =>
  {
    setLoading( true );

    try
    {
      await chatServices.completeChooseMedia( message, messageSelectedMedia );
      setLoading( false );
    }
    catch (e)
    {
      dispatch( setAlertMessage( errorAlert( "An error occurred. Please try again." ) ) );
      setLoading( false );
    }
  }

  const sendChatMessage = async ( message ) =>
  {
    if ( blockedForUpsell )
    {
      dispatch( setUpsellShown( true ) )
      eventTracker.logUpsellShown( UPSELL_SOURCE_SEND_CHAT_MESSAGE );
      return;
    }
    setLoading( true );

    try
    {
      if ( message.length === 0 )
      {
        return;
      }

      let result = await chatServices.sendMessage( message );

      if ( !isEmpty( userInput ) && result )
      {
        setUserInput( "" );
        eventTracker.logChatMessageSent( message );
      }
      setLoading( false );
    }
    catch (e)
    {
      dispatch( setAlertMessage( errorAlert( "An error occurred. Please try again." ) ) );
      setLoading( false );
    }
  }
  const sendValue = async () =>
  {
    if ( userInput.length === 0 || waitingForThreadRun )
    {
      return;
    }

    await sendChatMessage( userInput );
  }

  const checkThreadMessages = async () =>
  {
    dispatch( setCheckMessagesFailedAllAttempts( false ) );
    dispatch( setWaitingForThreadRunToComplete( true ) );

    return chatServices.checkForNewMessagesIfLoggedIn().catch( () =>
    {
      dispatch( setCheckMessagesFailedAllAttempts( true ) );
      dispatch( setWaitingForThreadRunToComplete( false ) );

      return false;
    } );
  }

  const requestEnsureChatThread = async () =>
  {
    setLoading( true );
    return businessServices.ensureChatThread().then( () =>
      {
        dispatch( setEnsureChatThread( false ) );
        checkThreadMessages()
      }
    ).catch( () =>
    {
      dispatch( setEnsureChatThread( true ) );
      return false;
    } ).finally( () =>
    {
      setLoading( false );
    } );
  }

  function convertChatMessageToMessageAPI( chatMessage, content )
  {
    const message: MessageAPI = {
      id: chatMessage.id,
      text: content.text.value,
      type: MESSAGE_TYPE_THREAD_MESSAGE,
      role: chatMessage.role,
      timestamp: chatMessage.created_at.toString(),
    }
    return message;
  }

  function convertChatImageToMessageAPI( chatMessage, content )
  {
    const message: MessageAPI = {
      id: chatMessage.id,
      text: undefined,
      image_url: content.image_url?.url,
      type: MESSAGE_TYPE_THREAD_IMAGE,
      role: chatMessage.role,
      timestamp: chatMessage.created_at.toString(),
    }
    return message;
  }

  function extractChatMessagesFromOpenAI()
  {
    const emptyMessages: MessageAPI[] = []
    return flatten(
      map( messages, ( chatMessage ) =>
        {
          return map( chatMessage.content, ( content ) =>
          {
            if ( content.type === "text" )
            {
              return convertChatMessageToMessageAPI( chatMessage, content );
            }
            else if ( content.type === "image_url" )
            {
              return convertChatImageToMessageAPI( chatMessage, content );
            }
          } );
        }
      )
    ) || emptyMessages;
  }

  function handleOnChange( event: React.ChangeEvent<HTMLInputElement> )
  {
    setUserInput( event.target.value );
  }

  function handleMediaPicked( newSelectedMedia: MediaSelection[] )
  {
    dispatch( setSelectedMedia( newSelectedMedia ) );
    dispatch( setShowChooseMediaButton( false ) );
    eventTracker.logOwnerChooseMediaCompleted( newSelectedMedia );
    sendCompleteChooseMedia( "Ok, I uploaded some media", newSelectedMedia );
  }

  function handleMediaSelectionChanged( newSelectedMedia: MediaSelection[] )
  {
    dispatch( setSelectedMedia( newSelectedMedia ) );
  }

  function handleCancelMediaPicker()
  {
    dispatch( setShowChooseMediaButton( false ) );
    const newSelectedMedia = [];
    dispatch( setSelectedMedia( newSelectedMedia ) );
    eventTracker.logOwnerChooseMediaSkipped();
    sendCompleteChooseMedia( "no media at this time", newSelectedMedia );
  }

  function renderChooseMedia()
  {
    return (
      <AssistantChooseMedia onComplete={handleMediaPicked} handleGoBack={handleCancelMediaPicker} onChangeSelection={handleMediaSelectionChanged}/>);
  }

  function renderQuickActions()
  {
    clearQuickActionsTimer();
    return <QuickActionPanel loading={loading} sendChatMessage={sendChatMessage}/>;
  }

  function renderOnboardingQuickActions()
  {
    return <OnboardingQuickActionPanel loading={loading} sendChatMessage={sendChatMessage}/>;
  }

  function renderUserMessageInput()
  {
    return (<Box className="assistantChat" sx={{
      display: 'flex', justifySelf: 'flex-end', alignItems: 'center', px: 5
    }}>
      <TextField
        className={showChatHintPulse ? "chatTextField" : ""}
        sx={{ flex: '1 1 auto', mr: 4, p: 0, '& .MuiInputBase-root': { p: 4 } }}
        inputProps={{ padding: 0 }}
        inputRef={inputRef}
        value={userInput}
        onChange={handleOnChange}
        multiline
        onKeyDown={handleKeyDown}
        disabled={shouldShowWaiting}
      />
      <Button
        variant='contained'
        color='primary'
        sx={{ alignSelf: 'center' }}
        onClick={sendValue}
        disabled={shouldShowWaiting}
      >
        <SendIcon/>
      </Button>
    </Box>);
  }

  function renderErrorUI()
  {
    if ( shouldShowEnsureChatThreadButton )
    {
      return renderEnsureChatThreadButton();
    }
    else if ( shouldShowRetryCheckMessagesButton )
    {
      return renderRetryCheckMessagesButton();
    }
  }

  function renderEnsureChatThreadButton()
  {
    return renderErrorStack( requestEnsureChatThread, 'Refresh Chat' );
  }

  function renderRetryCheckMessagesButton()
  {
    return renderErrorStack( checkThreadMessages, 'Retry' );
  }

  function renderErrorStack( onClick: () => Promise<void | boolean>, buttonText: string )
  {
    return (<Stack sx={{ alignItems: 'center' }}>
      <Typography sx={{ mb: 5, mt: 5, ml: 5, mr: 5 }} align="center">
        An error occurred,<br/>
        please try again.
      </Typography>
      <Button
        variant='contained'
        color='primary'
        sx={{ alignSelf: 'center' }}
        onClick={onClick}
        disabled={loading}>
        {buttonText}
      </Button>
    </Stack>)
  }


  function isAllowedToCheckForMessages()
  {
    return isCurrentBusinessSet && (!firstCheckForMessageCompleted || hasPreviousChatHistoryWithOpenAI || hasFinishedFirstRun
                                    || isOnlyHardcodedMessageScenario);
  }

  function buildClientSideIntroMessage( businessCreationDateOrDefault: Date )
  {
    const timestamp = (DateUtils.getSecondsSinceEpoch( businessCreationDateOrDefault ) - 1).toString();
    const clientSideIntroMessage: MessageAPI = {
      id: "alkaiOnboardingMessage",
      text: ALKAI_ONBOARDING_FIRST_CLIENT_SIDE_MESSAGE,
      type: MESSAGE_TYPE_THREAD_MESSAGE,
      role: MESSAGE_ROLE_ASSISTANT,
      timestamp,
    }
    return clientSideIntroMessage;
  }

  function lessThanMaxMessagesFromOpenAi()
  {
    const messagesFromOpenAI = extractChatMessagesFromOpenAI();
    return size( messagesFromOpenAI ) < MAX_MESSAGES_FROM_OPEN_AI;
  }

  function shouldInjectHardCodedIntroMessageAtTopOfChat()
  {
    const singleBusinessWaitingForCheckMessages = !firstCheckForMessageCompleted && hasSingleBusiness;
    return isCurrentBusinessSet
           && lessThanMaxMessagesFromOpenAi()
           && !applicationLoading
           && (firstCheckForMessageCompleted || singleBusinessWaitingForCheckMessages);
  }

  function getMessagesToShow()
  {
    const messagesFromOpenAI = extractChatMessagesFromOpenAI();
    const initialOnboardingMessage = shouldInjectHardCodedIntroMessageAtTopOfChat() ? clientSideIntroMessage : [];
    return chain( messagesFromOpenAI )
      .concat( postIdeaMessages )
      .concat( initialOnboardingMessage )
      .compact()
      .sortBy( ( message ) =>
      {
        const timestampAsString = message.timestamp;
        return Number( timestampAsString );
      } )
      .map( ( message, index, collection ) =>
      {
        const previousIndex = index - 1;
        const previousMessage = previousIndex > -1 && nth( collection, previousIndex );
        const hasPreviousMessage = !!previousMessage;
        if ( message.role === MESSAGE_ROLE_ASSISTANT || message.type === MESSAGE_TYPE_REEL_APPROVAL )
        {
          const messageShouldDisplayAvatar = hasPreviousMessage &&
                                             (previousMessage.role !== MESSAGE_ROLE_ASSISTANT && previousMessage.type !== MESSAGE_TYPE_REEL_APPROVAL);
          if ( !hasPreviousMessage || messageShouldDisplayAvatar )
          {
            return {
              ...message,
              showAvatar: true
            }
          }
        }
        return message;
      } )
      .value();
  }

  function closePostingStrategyQuiz()
  {
    dispatch( setHasSeenPostStrategyQuiz( true ) )
    setShowPostingStrategyQuiz( false );
  }

  return <>
    <Helmet>
      <title>{ROUTE_SEO.CHAT.title}</title>
      <meta name="description" content={ROUTE_SEO.CHAT.description}/>
    </Helmet>

    {restartQuickActionsTimer()}
    <Stack sx={{
      textAlign: "left", height: "100%", position: "relative", paddingBottom: "10px"
    }}>
      <Box className="conversation"
           sx={{
             display: "flex",
             flexDirection: "column",
             pt: 5,
             justifyContent: "flex-start",
             alignItems: "center",
             flex: "1 1 auto",
             overflow: "auto",
             px: 5,
           }}>
        {
          map( messagesToShow, ( message, idx ) =>
            {
              if ( message )
              {
                const key_part_for_message_type = message.type === "thread_image" ? message.image_url : message.text
                const key = message.id + key_part_for_message_type + message.timestamp;
                return <Message message={message} key={key}/>;
              }
              else
              {
                return null;
              }
            }
          )
        }
        {shouldShowWaiting && <BouncingDotsLoader longWaitingMessages={getRotatingWaitingMessage( currentWaitingType )}/>}
        <Box sx={{ justifySelf: "flex-end" }} ref={chatBottomRef}/>
      </Box>
      {shouldShowChooseMediaButton && renderChooseMedia()}
      {shouldShowQuickActions && renderQuickActions()}
      {shouldShowOnboardingQuickActions && renderOnboardingQuickActions()}
      {shouldShowUserMessageInput && renderUserMessageInput()}
      {showingErrorUI && renderErrorUI()}
      <AlertBanner/>
    </Stack>
    <PostIdeaNotification/>

    {showPostingStrategyQuiz && <PostingStrategyQuizFullScreenDialog externalStateOpen={showPostingStrategyQuiz}
                                                                     handleClose={closePostingStrategyQuiz}
                                                                     modalDialogSxProps={{ maxWidth: "400px", mx: "auto" }}
    />}
  </>;
}
