import * as React from 'react';
import { useState } from 'react';
import { Box, Button, CircularProgress, Divider, Stack, TextField, Typography } from "@mui/material";
import { businessServices, MediaAssetAPI } from "../../services/business.services";
import { size } from "lodash";
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import Search from "@mui/icons-material/Search";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { useDispatch } from "react-redux";
import AddFromMediaLibraryGrid from "./addFromMediaLibraryGrid";
import { KEYBOARD_ENTER_KEY_ID, MAX_NUMBER_OF_MEDIA, MAX_NUMBER_OF_MEDIA_ERROR, MediaPickingMode } from "../constants";
import { WebImagesAutomaticallyAddedDialog } from "./webImagesAutomaticallyAddedDialog";

export interface WebsiteMediaTabProps
{
  postIdea?: PostIdeaDataAPI;
  handleChooseMediaAssets: ( mediaAssets: MediaAssetAPI[] ) => void;
  currentMediaUrls?: string[];
  openedFromMediaLibraryTab?: boolean;
  handleBackClicked: () => void;
  mediaPickingMode?: MediaPickingMode;
}

function WebsiteMediaTab( props: WebsiteMediaTabProps )
{
  const [loadingMedia, setLoadingMedia] = useState( false );
  const dispatch = useDispatch();

  const [scrapedMediaAssets, setScrapedMediaAssets] = useState<MediaAssetAPI[]>( [] );
  const [websiteUrl, setWebsiteUrl] = useState<string>( "" );

  const [openWebImagesAutomaticallyAddedDialog, setOpenWebImagesAutomaticallyAddedDialog] = useState<boolean>( false );

  const handleOnChange = ( event: React.ChangeEvent<HTMLInputElement> ) =>
  {
    const websiteUrl = event.target.value
    setWebsiteUrl( websiteUrl as string )
  }

  async function handleKeyDown( event: { which: number; preventDefault: () => void; } )
  {
    if ( event.which === KEYBOARD_ENTER_KEY_ID )
    {
      event.preventDefault();
      await handleScrapeWebsite();
    }
  }

  async function handleScrapeWebsite()
  {
    if ( props.openedFromMediaLibraryTab )
    {
      setOpenWebImagesAutomaticallyAddedDialog( true );
      businessServices.addMediaFromWebsite( websiteUrl ).catch( () =>
      {
        dispatch( setAlertMessage( errorAlert( "Failed to load media from website. Please verify the address is correct and try again." ) ) );
      } );
    }
    else
    {
      await scrapeWebsiteAndWaitToShowResults();
    }
  }

  async function scrapeWebsiteAndWaitToShowResults()
  {
    setScrapedMediaAssets( [] );
    setLoadingMedia( true );
    try
    {
      const scrapedMediaAssetsResponse = await businessServices.addMediaFromWebsite( websiteUrl );
      if ( scrapedMediaAssetsResponse.media_assets.length === 0 )
      {
        dispatch( setAlertMessage( errorAlert( "No usable media found. Please try a different page." ) ) );
      }
      else
      {
        setScrapedMediaAssets( scrapedMediaAssetsResponse.media_assets );
      }
    }
    catch (e)
    {
      setScrapedMediaAssets( [] )
      dispatch( setAlertMessage( errorAlert( "Failed to load media from website. Please verify the address is correct and try again." ) ) );
    }
    finally
    {
      setLoadingMedia( false );
    }
  }

  function getHintText()
  {
    if ( props.openedFromMediaLibraryTab )
    {
      return <Typography variant={"subtitle1"} sx={{ my: 10 }}>Alkai will grab the images from the URL you enter and automatically add them to your
                                                               media library.</Typography>
    }
    return <Typography variant={"subtitle1"} sx={{ my: 10 }}>Alkai will show the photos it finds here so you can
                                                             choose which ones to include in your
                                                             post. <br/> All photos will be added
                                                             to your library for future
                                                             use.</Typography>
  }

  function handleWebImagesAutomaticallyAddedDialogClose()
  {
    setOpenWebImagesAutomaticallyAddedDialog( false );
    props.handleBackClicked();
  }

  return (
    <Box sx={{
      height: "100%",
      overflow: "auto",
      textAlign: "center",
    }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          id="url-input"
          inputProps={{ sx: { px: 4, py: 4 } }}
          sx={{ width: "100%", maxWidth: "650px" }}
          placeholder="Enter a website or media URL"
          value={websiteUrl}
          inputMode={"url"}
          disabled={loadingMedia}
          onKeyDown={handleKeyDown}
          onChange={handleOnChange}/>
        <Button variant={"contained"} sx={{ ml: 4 }} onClick={handleScrapeWebsite} disabled={!websiteUrl || loadingMedia}><Search/></Button>
      </Box>

      <Divider sx={{ my: 5, maxWidth: "350px", mx: "auto" }}/>
      {size( scrapedMediaAssets ) === 0 && !loadingMedia && getHintText()}
      {loadingMedia && <Stack sx={{ display: "flex", width: "100%", flexDirection: "vertical", my: 10 }}>
        Loading media from your site, this may take a few moments...
        <CircularProgress className="searchSpinner small" id="spinner" sx={{ my: 10, alignSelf: "center" }}/>
      </Stack>}

      <AddFromMediaLibraryGrid
        mediaAssets={scrapedMediaAssets}
        currentMediaUrls={props.currentMediaUrls}
        buttonText={"Add selected"}
        handleChooseMediaAssets={props.handleChooseMediaAssets}
        maxMediaCount={MAX_NUMBER_OF_MEDIA}
        maxMediaCountError={MAX_NUMBER_OF_MEDIA_ERROR}
        mediaPickingMode={props.mediaPickingMode}
      />

      {props.openedFromMediaLibraryTab && openWebImagesAutomaticallyAddedDialog && <WebImagesAutomaticallyAddedDialog
        open={openWebImagesAutomaticallyAddedDialog} onClose={handleWebImagesAutomaticallyAddedDialogClose}/>}
    </Box>
  );
}

export default WebsiteMediaTab;
