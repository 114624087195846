import { createSlice } from '@reduxjs/toolkit'
import { RootState } from "../../app/store";

const IOS_TRACKING_PERMISSIONS_STATUS_AUTHORIZED = 3;

export interface TrackingState
{
  last_reported_client_activity_at: number;
  has_clicked_on_post_preview: boolean;
  channel_tracking_parameters?: ChannelTrackingParameters;
  ga_client_id?: string;
  ios_tracking_permissions_status?: number;
}

const initialState: TrackingState = {
  last_reported_client_activity_at: 0,
  has_clicked_on_post_preview: false,
}

export interface ChannelTrackingParameters
{
  alkai_source: string;
  fbclid: string;
}

export const trackingSlice = createSlice( {
  name: 'tracking',
  initialState,
  reducers: {
    setHasClickedOnPostPreview: ( state, action ) =>
    {
      return {
        ...state,
        has_clicked_on_post_preview: action.payload,
      }
    },
    setLastReportedClientActivityAt: ( state, action ) =>
    {
      return {
        ...state,
        last_reported_client_activity_at: action.payload,
      }
    },
    setChannelTrackingParameters( state, action )
    {
      return {
        ...state,
        channel_tracking_parameters: action.payload
      }
    },
    setGAClientId( state, action )
    {
      return {
        ...state,
        ga_client_id: action.payload
      }
    },
    setIosTrackingPermissionsStatus( state, action )
    {
      return {
        ...state,
        ios_tracking_permissions_status: action.payload
      }
    },
    clearTracking: () =>
    {
      return {
        ...initialState
      };
    },
  },
} )

export const getLastReportedClientActivityAt = ( state: RootState ) => state.tracking.last_reported_client_activity_at;
export const getHasClickedOnPostPreview = ( state: RootState ) => state.tracking.has_clicked_on_post_preview;
export const getChannelTrackingParameters = ( state: RootState ) => state.tracking.channel_tracking_parameters;
export const getGAClientId = ( state: RootState ) => state.tracking.ga_client_id;
export const getIosTrackingPermissionsStatus = ( state: RootState ) => state.tracking.ios_tracking_permissions_status;
export const isiOSTrackingAuthorized = ( state: RootState ) => state.tracking.ios_tracking_permissions_status
                                                               === IOS_TRACKING_PERMISSIONS_STATUS_AUTHORIZED;

// Action creators are generated for each case reducer function
export const {
  setLastReportedClientActivityAt,
  clearTracking,
  setHasClickedOnPostPreview,
  setChannelTrackingParameters,
  setGAClientId,
  setIosTrackingPermissionsStatus,
} = trackingSlice.actions

export default trackingSlice.reducer
