import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";
import { SUBSCRIPTION_DURATION_YEARLY, SubscriptionDuration, YEARLY_PAYWALL_BUTTON_GRADIENT } from "../constants";
import { PaywallBadge } from "../upsell/paywallBadge";
import { BestValueBadge } from "../upsell/bestValueBadge";

export interface SubscriptionProductButtonProps
{
  onClick?(): void;
  subscriptionDuration: SubscriptionDuration;
  selectedDuration: SubscriptionDuration;
}

export function SubscriptionProductButton( props: SubscriptionProductButtonProps )
{

  const upsellPurple = "#373182";
  const backgroundWhite = "#F5F5F5";
  const backgroundGrey = "#8C8C8C";

  const selected = props.selectedDuration === props.subscriptionDuration;
  const isYearly = props.subscriptionDuration === SUBSCRIPTION_DURATION_YEARLY;

  function getTitle()
  {
    return isYearly ? "Yearly" : "Monthly";
  }

  function getPriceDescription()
  {
    return isYearly ? "$240, billed yearly" : "$20/mo for 3 months, $40/mo after";
  }

  function getMonthlyPriceText()
  {
    return isYearly ? "$20/mo" : "$40/mo";
  }

  function getBadgeText()
  {
    return isYearly ? "Lock in 50% off!" : "50% off for 3 months";
  }

  function getBackgroundColor()
  {
    return selected ? backgroundWhite : backgroundGrey;
  }

  return (
    <Box onClick={props.onClick}
         sx={{ background: YEARLY_PAYWALL_BUTTON_GRADIENT, borderRadius: "10px", mt: "5px", py: "1px", width: "350px", position: "relative" }}>
      <Box sx={{ background: `${getBackgroundColor()}`, borderRadius: "10px", mx: "auto", width: "calc(100% - 2px)" }}>
        <Stack sx={{ p: "5px" }}>
          <Stack direction={"row"} sx={{ alignItems: "center", justifyContent: "space-between", ml: "10px" }}>
            <Stack>
              <Typography variant={"h5"} sx={{ color: `${upsellPurple}`, fontWeight: "500", fontSize: "20px" }}>{getTitle()}</Typography>
              <Typography sx={{ color: `${upsellPurple}`, fontSize: "13px", fontWeight: "400" }}>{getPriceDescription()}</Typography>
            </Stack>
            <Typography variant={"h5"}
                        sx={{ color: `${upsellPurple}`, fontWeight: "500", fontSize: "20px", mr: "20px" }}>{getMonthlyPriceText()}</Typography>
          </Stack>
        </Stack>
        {selected && <PaywallBadge badgeText={getBadgeText()} positioningProps={{ position: "absolute", top: "-67px", right: "-2px" }}/>}
        {isYearly && <BestValueBadge selected={selected}/>}
      </Box>
    </Box>
  );
}


