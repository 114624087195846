import { Stack } from "@mui/material";
import { Helmet } from "react-helmet";
import { ROUTE_SEO } from "../constants";
import React from "react";
import { NewSupportTicketDialog } from "./newSupportTicketDialog";

export function NewSupportTicket()
{
  return <Stack spacing={10} sx={{ textAlign: "left", px: 5, pt: 5 }} alignItems="start">
    <Helmet>
      <title>{ROUTE_SEO.NEW_SUPPORT_TICKET.title}</title>
      <meta name="description" content={ROUTE_SEO.NEW_SUPPORT_TICKET.description}/>
    </Helmet>
    <NewSupportTicketDialog externalStateOpen={true}/>
  </Stack>
}
