import React, { useState } from "react";
import { matchIsValidTel, MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import { SIGN_IN_PROVIDER_PHONE_NUMBER } from "../constants";

export interface InputProps
{
  handleInputChanged( value: string, valid: boolean ): void;
}

export function PhoneNumberInput( props: InputProps )
{
  const [phoneNumber, setPhoneNumber] = useState<string>( "" );
  const [phoneNumberError, setPhoneNumberError] = useState( false );

  const handlePhoneNumberChange = ( value: string, info: MuiTelInputInfo ) =>
  {
    const numberWithCallingCode = info.numberValue || "";
    setPhoneNumber( numberWithCallingCode );
    let phoneIsValid = matchIsValidTel( numberWithCallingCode );
    props.handleInputChanged( numberWithCallingCode, phoneIsValid );
    setPhoneNumberError( !phoneIsValid )
  }

  return <>
    <MuiTelInput
      forceCallingCode
      disableDropdown
      fullWidth
      defaultCountry="US"
      onlyCountries={["US", "CA"]}
      name={SIGN_IN_PROVIDER_PHONE_NUMBER}
      value={phoneNumber}
      error={phoneNumberError}
      onChange={handlePhoneNumberChange}
      helperText={phoneNumberError ? "Enter a valid US or Canadian phone number" : null}
      sx={{
        '& .MuiInputBase-root': {
          background: 'white',
        }
      }}/>
  </>;
}
