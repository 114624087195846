import { postIdeaServices, RecordingStatusAPI } from "../../services/postIdeaServices";
import { PostIdeaDataAPI, SparsePostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import {
  HOLIDAY_PRESET_NAME,
  MESSAGE_TYPE_MULTIPLE_POST_IDEAS,
  NO_MUSIC_TRACK_ID,
  OUTPUT_FORMAT_PROPERTIES_BY_SLUG,
  OUTPUT_FORMAT_SLUG_PORTRAIT_STATIC,
  OUTPUT_FORMAT_SLUG_SQUARE_STATIC,
  OutputFormatSlug,
  TRACK_TYPE_NO_MUSIC
} from "../constants";
import { addPost, getPostForPostIdea } from "./postsSlice";
import { store } from "../../app/store";
import { findKey, isArray, isEmpty, isNumber, map, startsWith } from "lodash";
import { addPostIdea } from "./postIdeaSlice";

export const postIdeaHelper = {
  checkForShareWarnings,
  updateStoreWithLatestPostIdeaAndPost,
}

export function addOrUpdatePostIdeaFromMessage( postIdeaMessage )
{
  if ( postIdeaMessage.type === MESSAGE_TYPE_MULTIPLE_POST_IDEAS )
  {
    map( postIdeaMessage.data, ( postIdea: PostIdeaDataAPI ) => store.dispatch( addPostIdea( postIdea ) ) );
  }
  else
  {
    store.dispatch( addPostIdea( postIdeaMessage.data as PostIdeaDataAPI ) )
  }
}

export function hasCompletedPostIdea( postIdea: PostIdeaDataAPI )
{
  return (!!postIdea.downloaded_at || !!postIdea.first_completed_at);
}

export function needsRecording( postIdea: PostIdeaDataAPI )
{
  const hasNoRecording = !postIdea.recording
  const recordingIsDirty = !!postIdea.recording && postIdea.recording.recording_status === RecordingStatusAPI.DIRTY;

  return hasNoRecording || recordingIsDirty;
}

export const getColorPaletteSlug = ( postIdea: PostIdeaDataAPI ) =>
{
  return postIdea.configuration.config_bundle.color_palette_slug;
}

export const getFontSetSlug = ( postIdea: PostIdeaDataAPI ) =>
{
  return postIdea.configuration.config_bundle.font_set_slug;
}

export const getLayoutPresetName = ( postIdea: PostIdeaDataAPI ) =>
{
  return postIdea.configuration.config_bundle.preset_name;
}

export const getHolidayLayoutSlug = ( postIdea: PostIdeaDataAPI ) =>
{
  return postIdea.configuration.config_bundle.holiday?.holiday_layout_slug;
}

export const isHolidayPostIdea = ( postIdea: PostIdeaDataAPI ) =>
{
  return startsWith( getLayoutPresetName( postIdea ), HOLIDAY_PRESET_NAME );
}

export function getLabelForPreparingOutput( postIdea: PostIdeaDataAPI )
{
  const isStatic = isStaticPostIdea( postIdea );
  return isStatic ? "Preparing..." : "Recording...";
}

export function isStaticPostIdea( postIdea: PostIdeaDataAPI )
{
  return postIdea.output_format_slug === OUTPUT_FORMAT_SLUG_SQUARE_STATIC || postIdea.output_format_slug === OUTPUT_FORMAT_SLUG_PORTRAIT_STATIC;
}

export function getOutputFormatDisplayNameFromSlug( slug: OutputFormatSlug | undefined )
{
  if ( !!slug && !!OUTPUT_FORMAT_PROPERTIES_BY_SLUG[slug] )
  {
    return OUTPUT_FORMAT_PROPERTIES_BY_SLUG[slug].displayName;
  }
  return "";
}

export function getAspectRatioFromOutputFormat( slug: string )
{
  return OUTPUT_FORMAT_PROPERTIES_BY_SLUG[slug].aspectRatio;
}

export function hasBrandCardInPostJson( postIdea: PostIdeaDataAPI )
{
  const post = getPostForPostIdea( store.getState(), postIdea.id );
  if ( !!post )
  {
    const globalSettingsJson = JSON.parse( post.global_settings_data );
    const slideStore = globalSettingsJson.slideStore;
    const hasBrandSlide = !!findKey( slideStore, function ( slide )
    {
      return slide.slide_config_type === "brand_slide";
    } );
    return hasBrandSlide;
  }
  return false;
}

export function getMusicDataFromPostJson( postIdea: PostIdeaDataAPI )
{
  const post = getPostForPostIdea( store.getState(), postIdea.id );
  if ( !!post )
  {
    const globalSettingsJson = JSON.parse( post.global_settings_data );
    return globalSettingsJson.musicData;
  }
  return null;
}

export function doesPostHaveNoMusicSelected( postIdea: PostIdeaDataAPI )
{
  const currentMusicData = getMusicDataFromPostJson( postIdea );
  return isEmpty( currentMusicData )
         || currentMusicData.id === NO_MUSIC_TRACK_ID
         || currentMusicData.id === null
         || currentMusicData.type === TRACK_TYPE_NO_MUSIC;
}

export function getSelectedTrackIdFromPostJson( postIdea: PostIdeaDataAPI )
{
  const musicData = getMusicDataFromPostJson( postIdea );
  return musicData?.id ? musicData.id : "";
}

export function getSelectedTrackTypeFromPostJson( postIdea: PostIdeaDataAPI )
{
  const musicData = getMusicDataFromPostJson( postIdea );
  return musicData?.type ? musicData.type : "";
}

export function getTrackDurationMsFromPostJson( postIdea: PostIdeaDataAPI )
{
  const musicData = getMusicDataFromPostJson( postIdea );
  const duration = musicData?.metadata?.duration_ms;
  if ( isNumber( duration ) )
  {
    return duration;
  }
  return undefined;
}

export function getOutputDurationMsFromPostJson( postIdea: PostIdeaDataAPI )
{
  return postIdea.output_duration_ms;
}

export function getTrackStartTimeMsFromPostJson( postIdea: PostIdeaDataAPI )
{
  const musicData = getMusicDataFromPostJson( postIdea );
  if ( isNumber( musicData?.startTimeInSeconds ) )
  {
    return musicData.startTimeInSeconds * 1000;
  }
  return 0;
}

export function hasUseBrandInBrandSlideInPostJson( postIdea: PostIdeaDataAPI )
{
  const configuration = postIdea.configuration;
  const configBundle = configuration.config_bundle;
  const brandStyle = configBundle.brand_style;
  return !!brandStyle && brandStyle.use_brand_in_brand_slide;
}

export function getFontSetTrackingData( postIdea: PostIdeaDataAPI )
{
  return postIdea.tracking_data?.font_set;
}

export function convertFullPostIdeaDataToSparsePostIdeaData( data: PostIdeaDataAPI | PostIdeaDataAPI[] )
{
  if ( isArray( data ) )
  {
    return map( data, ( postIdeaData ) =>
    {
      return { id: postIdeaData.id } as SparsePostIdeaDataAPI;
    } );
  }
  else
  {
    return { id: data.id } as SparsePostIdeaDataAPI;
  }
}

export interface PostIdeaValidationResults
{
  isValid: boolean;
  warnings?: string[];
  errors?: string[];
}

function checkForShareWarnings( postIdea: PostIdeaDataAPI ): PostIdeaValidationResults
{
  let warnings: string[] = [];
  if ( !!postIdea.has_active_placeholder_media )
  {
    warnings.push( "Your post contains placeholder images. We recommend replacing them before sharing." );
  }

  if ( !isEmpty( warnings ) )
  {
    return {
      isValid: false,
      warnings,
    }
  }
  return {
    isValid: true,
    warnings: undefined,
  }
}

async function updateStoreWithLatestPostIdeaAndPost( postIdeaId: string )
{
  try
  {
    const postIdeaData = await postIdeaServices.getPostIdeaData( postIdeaId )
    store.dispatch( addPostIdea( postIdeaData ) )
    const postJson = await postIdeaServices.getPostJson( postIdeaId )
    store.dispatch( addPost( postJson ) );
    return Promise.resolve();
  }
  catch (error)
  {
    return Promise.reject( error );
  }
}